import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../../front-end-global-components/components/Header/Header";
import emptyDocument from "../../assets/images/fallback.svg";
import "./Abdm.css";
import Button from "../../front-end-global-components/components/Button/Button";
import { isValidObject } from "../../utils/validators";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import {
  clearAbdmAuthCredentials,
  clearLinkRefNumber,
  confirmOtpToLinkAbdmCareContext,
  discoverCareContextWithClinicId,
  sendOtpToLinkAbdmCareContext
} from "../../redux/abdm/actions";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { getValidGender } from "../../utils/constants";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { ClinicCard, DocumentCard } from "../../components/Cards/Cards";

export const ViewSearchedClinicCareContext = (props) => {
  const { clinicId } = useParams();
  const [OTPValue, setOTPValue] = useState(null);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [careContext, setCareContext] = useState({});
  const [clinicDetails, setClinicDetails] = useState({});
  const [selectedCareContexts, setSelectedCareContexts] = useState({});
  const [menu, setMenu] = useState("menu");
  useEffect(() => {
    if (props.abdm.clinics?.search?.linkRefNumber) {
      setShowOTPModal(true);
    } else {
      setShowOTPModal(false);
    }
  }, [props.abdm.clinics.search.linkRefNumber]);
  //clear OTP residues
  useEffect(() => {
    props.clearLinkRefNumber();
    // eslint-disable-next-line
  }, []);

  function fetchCareContextDetails() {
    if (
      !!clinicId &&
      isValidObject(props.abdm.clinics.search.careContexts?.[clinicId])
    ) {
      setCareContext(props.abdm.clinics.search.careContexts[clinicId]);
    }
  }

  useEffect(() => {
    if (clinicId) {
      if (
        isValidObject(props.abdm.clinics.search.results) &&
        isValidObject(props.abdm.clinics.search.results[clinicId])
      ) {
        setClinicDetails(props.abdm.clinics.search.results[clinicId]);
      } else if (
        isValidObject(props.abdm.clinics.search.searchHistory) &&
        isValidObject(props.abdm.clinics.search.searchHistory[clinicId])
      ) {
        setClinicDetails(props.abdm.clinics.search.searchHistory[clinicId]);
      } else if (
        isValidObject(props.abdm.clinics.search.publicHealthPrograms) &&
        isValidObject(props.abdm.clinics.search.publicHealthPrograms[clinicId])
      ) {
        setClinicDetails(
          props.abdm.clinics.search.publicHealthPrograms[clinicId]
        );
      } else {
        props.navigate(-1);
      }
    } else {
      props.navigate(-1);
    }
    // eslint-disable-next-line
  }, [clinicId]);

  useEffect(() => {
    fetchCareContextDetails();
    // eslint-disable-next-line
  }, [clinicId, props.abdm.clinics.search.careContexts]);

  useEffect(() => {
    if (
      props.abdm.clinics.search.publicHealthPrograms &&
      props.abdm.clinics.search.publicHealthPrograms[clinicId] &&
      isValidObject(props.abdm.clinics.search.publicHealthPrograms[clinicId])
    ) {
      setMenu("patientDetails");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    {
      name: "Fetch Record",
      description:
        "You can able to fetch records which is associated with your clinic",
      "data-cy": "fetchRecords-menu",
      onClick: () => {
        setMenu("patientDetails");
      }
    }
    // {
    //   name: "Book Appointment",
    //   description: "You can able to book appointment with your clinic",
    //   "data-cy": "bookAppointment-menu",
    //   onClick: () => {
    //     setMenu("bookAppointment");
    //   }
    // },
    // {
    //   name: "Blood Request",
    //   description:
    //     "You can able to donate your blood in your nearest or requested clinic",
    //   "data-cy": "bloodRequest-menu",
    //   onClick: () => {
    //     setMenu("bloodRequest");
    //   }
    // }
  ];
  const [healthData, setHealthData] = useState(null);
  const [patientId, setPatientId] = useState(null);

  useEffect(() => {
    if (
      isValidObject(props.patients.healthIdData) &&
      props.patients.currentProfile
    ) {
      setHealthData(props.patients.healthIdData[props.patients.currentProfile]);
    }
  }, [props.patients.healthIdData, props.patients.currentProfile]);

  const patientData = [
    {
      name: "VERIFIED MOBILE NUMBER",
      value: healthData?.mobile ? `+91${healthData?.mobile}` : "Nill"
    },
    {
      name: "ABHA ADDRESS",
      value: healthData?.id ? healthData?.id : "Nill"
    },
    {
      name: "ABHA NUMBER",
      value: healthData?.healthId ? healthData?.healthId : "Nill"
    },
    {
      name: "NAME",
      value: healthData?.fullName ? healthData?.fullName : "Nill"
    },
    {
      name: "GENDER",
      value: healthData?.gender ? getValidGender(healthData?.gender) : "Nill"
    },
    {
      name: "YEAR OF BIRTH",
      value: healthData?.dateOfBirth?.year
        ? healthData?.dateOfBirth?.year
        : "Nill"
    }
  ];

  return (
    <React.Fragment>
      <Header
        title={
          menu === "menu"
            ? "Hospital / lab"
            : menu === "fetchRecords" || menu === "patientDetails"
            ? "Fetch Records"
            : menu === "bloodRequest"
            ? "Blood Request"
            : menu === "bookAppointment" && "Book Appointment"
        }
        backButtonOnClick={() => {
          if (
            props.abdm.clinics.search.publicHealthPrograms &&
            props.abdm.clinics.search.publicHealthPrograms[clinicId] &&
            isValidObject(
              props.abdm.clinics.search.publicHealthPrograms[clinicId]
            )
          ) {
            return props.navigate(-1);
          }
          if (menu === "menu") {
            props.navigate(-1);
          } else if (menu === "fetchRecords") {
            setMenu("patientDetails");
          } else {
            setMenu("menu");
          }
        }}
      />

      <section className="remaining-body-height max-width-588px margin-horizontal-auto ">
        <ClinicCard
          loading={props.abdm.loading}
          clinicDetails={clinicDetails}
          logoURL={null}
        />
        <ConditionalRender condition={menu === "menu"}>
          {options.map((data, index) => (
            <div key={index} className="padding-large">
              <div
                className="display-flex flex-align-items-center flex-justify-content-space-between "
                data-cy={data["data-cy"]}
                onClick={data.onClick}
              >
                <div>
                  <div>{data.name}</div>
                  <div className="font-size-small padding-top-default padding-right-large">
                    {data.description}
                  </div>
                </div>
                <ArrowIcon />
              </div>
            </div>
          ))}
        </ConditionalRender>

        <ConditionalRender condition={menu === "patientDetails"}>
          <section className="remaining-height-of-abdm-clinic-care-context-section ">
            <div className="padding-top-default">
              {patientData.map((data, index) => (
                <div
                  key={index}
                  className="padding-left-large padding-right-large padding-bottom-default padding-top-default font-size-medium"
                >
                  <div className="padding-bottom-small">{data.name}</div>
                  <div
                    className={`${
                      data.name === "ABHA ADDRESS" ? "" : "text-capitalize"
                    }  font-size-small`}
                  >
                    {data.value}
                  </div>
                </div>
              ))}
            </div>
            <form
              className="flex-justify-content-space-between flex-direction-column"
              onChange={(event) => {
                setPatientId(event.target.value);
              }}
            >
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default padding-left-large padding-right-large"
                name="patientId"
                label={`${
                  clinicDetails?.attributes?.hipAttributes
                    ?.discoveryAdditionalFieldLabel
                    ? clinicDetails?.attributes?.hipAttributes
                        ?.discoveryAdditionalFieldLabel
                    : "PATIENT ID (OPTIONAL)"
                }`}
                type="text"
                required
              />
            </form>
            <div className="inherit-parent-width padding-left-large padding-right-large padding-left-large padding-top-larger padding-bottom-large">
              <Button
                text="Continue"
                data-cy="continue-to-fetch-records"
                boxShadow={false}
                onClick={() => {
                  setMenu("fetchRecords");
                  props.fetchCareContexts(clinicId, patientId);
                }}
              />
            </div>
          </section>
        </ConditionalRender>

        <ConditionalRender condition={menu === "fetchRecords"}>
          <section className="remaining-height-of-abdm-clinic-care-context-section ">
            <PatientRecords
              clinicName={careContext?.clinicName}
              location={careContext?.location}
              otpLoading={props.abdm.OTPLoading}
              loading={props.abdm.loading}
              records={careContext}
              selectedCareContexts={selectedCareContexts}
              setSelectedCareContexts={setSelectedCareContexts}
              fetchOnClick={(data) => {
                props.sendOtpToLinkCareContext(data, clinicId);
              }}
              retryOnClick={() => {
                props.fetchCareContexts(clinicId, patientId);
              }}
            />
          </section>
        </ConditionalRender>

        <OTPModal
          onClose={() => {
            setShowOTPModal(!showOTPModal);
            props.clearLinkRefNumber();
          }}
          OnConfirmClick={() => {
            props.confirmOtpToLinkCareContext(OTPValue, props.navigate);
          }}
          disableButton={OTPValue?.length === 6 ? false : true}
          loading={props.abdm.OTPLoading}
          showUploadModal={showOTPModal}
          resendOTPOnClick={() => {
            props.clearLinkRefNumber();
            props.sendOtpToLinkCareContext(selectedCareContexts, clinicId);
          }}
          setOTPValue={(otp) => setOTPValue(otp)}
        />
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  abdm: state.abdm,
  patients: state.patients
});

const mapDispatchToProps = function () {
  return {
    fetchCareContexts: (clinicId, patientId) =>
      discoverCareContextWithClinicId(clinicId, patientId),
    clearAbdmAuthCredentials: () => clearAbdmAuthCredentials(),
    sendOtpToLinkCareContext: (data, clinicId) =>
      sendOtpToLinkAbdmCareContext(data, clinicId),
    clearLinkRefNumber: () => clearLinkRefNumber(),
    confirmOtpToLinkCareContext: (otp, navigate) =>
      confirmOtpToLinkAbdmCareContext(otp, navigate)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSearchedClinicCareContext);

const PatientRecords = ({
  clinicName,
  location,
  records,
  retryOnClick,
  fetchOnClick,
  loading,
  otpLoading,
  selectedCareContexts,
  setSelectedCareContexts
}) => {
  return (
    <section className="inherit-parent-height ">
      {loading && (
        <DocumentCardLoading
          loading={loading}
          clinicName={clinicName}
          clinicLocation={location}
        />
      )}

      {!loading &&
        Array.isArray(records?.careContexts) &&
        records?.careContexts.length > 0 && (
          <section
            className={`inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between padding-top-large padding-bottom-large padding-right-larger padding-left-larger`}
          >
            <div className="overflow-y-auto ">
              <div className="padding-bottom-large text-align-center letter-spacing-4-percentage font-family-gilroy-regular font-color-secondary font-size-small position-sticky top-0 background-white z-index-1 ">
                NEW RECORDS FOUND
              </div>
              <ul className="abdm-care-context-document-card-border-bottom list-style-none overflow-y-auto  ">
                {records?.careContexts.map((careContext) => (
                  <li key={careContext?.referenceNumber}>
                    <DocumentCard
                      data={careContext}
                      // dateOfIssued={dateAndTimeConverter(+new Date())}
                      clinicLocation={location}
                      // documentType="Report"
                      checkBoxOnChange={(event) => {
                        if (event.target.checked === true) {
                          setSelectedCareContexts({
                            [careContext?.referenceNumber]: careContext
                          });
                        } else if (event.target.checked === false) {
                          const removeKey = (key, { [key]: _, ...rest }) =>
                            rest;
                          const data = removeKey(
                            careContext?.referenceNumber,
                            selectedCareContexts
                          );
                          setSelectedCareContexts(data);
                        }
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <Button
              text="Fetch records"
              data-cy="send-otp-to-fetch-records"
              boxShadow={false}
              // className="font-family-gilroy-medium  padding-bottom-large"
              disabled={
                isValidObject(selectedCareContexts) &&
                Object.keys(selectedCareContexts).length >= 1
                  ? false
                  : true
              }
              loading={otpLoading}
              onClick={(e) => {
                if (typeof fetchOnClick !== "function") {
                  return;
                } else {
                  fetchOnClick(selectedCareContexts);
                }
              }}
            />
          </section>
        )}

      {!loading &&
        !(
          Array.isArray(records?.careContexts) &&
          records?.careContexts.length > 0
        ) && (
          <NoDocumentsFound
            onClick={(event) => {
              if (typeof retryOnClick === "function") {
                retryOnClick(event);
              }
            }}
          />
        )}
    </section>
  );
};

const DocumentCardLoading = () => {
  return (
    <section
      className="padding-left-larger padding-right-larger padding-top-large padding-bottom-large"
      data-cy="document-card-loading"
    >
      <div className="inherit-parent-width display-flex flex-justify-content-center padding-bottom-large">
        <div className="shimmer width-150px padding-small  border-radius-default ">
          <div className="padding-small  border-radius-default" />
        </div>
      </div>

      {[...Array(5)].map((data) => (
        <section
          key={data}
          className="flex-center-children-vertically padding-bottom-larger"
        >
          <div className="shimmer padding-small border-radius-25-percentage height-width-16px" />
          <div>
            <div className="shimmer margin-left-larger padding-default border-radius-default width-200px " />
            <div className="shimmer margin-left-larger margin-top-default padding-small border-radius-default width-150px ">
              <div className="padding-smaller" />
            </div>
          </div>
        </section>
      ))}
    </section>
  );
};

const NoDocumentsFound = ({ onClick }) => {
  return (
    <section className="inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between overflow-y-auto">
      <section></section>
      <section
        className="text-align-center flex-place-children-page-center "
        data-cy="empty-documents-fallback"
      >
        <img
          src={emptyDocument}
          alt="empty-doc"
          width="100%"
          height="150px"
          className="padding-default margin-bottom-large"
        />
        <div className="font-family-gilroy-bold font-size-medium font-color-secondary padding-default">
          No records found.
        </div>
        <div className="font-family-gilroy-regular font-size-medium font-color-secondary padding-top-default padding-bottom-default padding-right-large padding-left-large line-height-140-percentage">
          Try fetching records from this clinic / lab to get any record
          associated with you in your health locker
        </div>
      </section>
      <Button
        text="Retry"
        boxShadow={false}
        className="font-family-gilroy-medium max-width-150px margin-horizontal-auto margin-bottom-larger margin-top-large"
        onClick={(e) => {
          if (typeof onClick !== "function") return;
          onClick(e);
        }}
      />
    </section>
  );
};

// const SuggestClinic = () => {
//   return (
//     <div className="flex-center-children-horizontally flex-direction-column">
//       <div className="text-align-center  padding-top-large">
//         <img
//           className="health-id-benefits-icons-size"
//           src={searchIcon}
//           alt="searchIcon"
//         />
//       </div>
//       <div className="text-align-center padding-top-large">
//         Can’t find your clinic / lab?
//       </div>
//       <div className="text-align-center padding-top-large">
//         Suggest the clinic or lab you have records linked. We will try our best
//         to onboard them to Ninto as soon as possible.
//       </div>
//       <Button
//         text="Suggest clinic"
//         boxShadow={false}
//         className="font-family-gilroy-medium max-width-150px padding-top-large margin-horizontal-auto margin-bottom-larger margin-top-large"
//         // onClick={(e) => {
//         //   if (typeof onClick !== "function") return;
//         //   onClick(e);
//         // }}
//       />
//     </div>
//   );
// };

// function dateAndTimeConverter(timestamp) {
//   if (!timestamp) return;
//   let dateObject = new Date(timestamp);
//   const date =
//     timestamp >= new Date().setHours(0, 0, 0, 0)
//       ? "Today"
//       : timestamp >= new Date().setHours(0, 0, 0, 0) - 86400000 &&
//         timestamp < new Date().setHours(0, 0, 0, 0)
//         ? "Yesterday"
//         : dateObject.toLocaleString("en-IN", { day: "2-digit"}) +
//         ""+
//         dateObject.toLocaleString("en-IN", { month: "short"}) +
//         ""+
//         dateObject.toLocaleString("en-IN", { year: "numeric"});
//   return date;
//   // date + ","+ dateObject.toLocaleString("en-IN", { timeStyle: "short"})
// }

const OTPModal = ({
  showUploadModal,
  OnConfirmClick,
  onClose,
  setOTPValue,
  loading,
  resendOTPOnClick,
  disableButton
}) => {
  return (
    <Modal
      canIgnore={true}
      onClose={onClose}
      show={showUploadModal}
      position="position-fixed bottom-0"
      boxShadow="none"
      borderRadius="none"
      width="inherit-parent-width"
      background="background-transparent"
      height="height-fit-to-content"
    >
      <div
        data-cy="otp-verify-modal"
        className="background-white border-top-radius-default padding-default font-family-gilroy-medium font-color-secondary flex-justify-content-space-between flex-align-items-center flex-direction-column font-size-medium padding-larger "
      >
        <OTPInputBox
          name="otp"
          setValue={setOTPValue}
          className="margin-bottom-larger"
          required
        />
        <h2 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
          Didn’t receive an OTP?
          <span
            className={`margin-left-default cursor-pointer font-color-primary`}
            onClick={() => {
              if (typeof resendOTPOnClick !== "function") {
                return;
              } else {
                resendOTPOnClick();
              }
            }}
          >
            Resend OTP
          </span>
        </h2>
        <Button
          text="Fetch"
          boxShadow={false}
          loading={loading}
          disabled={disableButton}
          data-cy="confirm-otp-to-fetch-records"
          className="font-family-gilroy-medium max-width-150px margin-horizontal-auto margin-top-larger"
          onClick={(e) => {
            if (typeof OnConfirmClick !== "function") {
              return;
            } else {
              OnConfirmClick();
            }
          }}
        />
      </div>
    </Modal>
  );
};
