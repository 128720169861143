import { put, takeEvery, all } from "redux-saga/effects";
import {
  getPaginatedNotifications,
  setAllNotificationAsRead,
  setNotificationRead
} from "../../services/database";
import { isValidObject } from "../../utils/validators";
import { setErrorStatus } from "../status/actions";
import store from "../store/store";
import { updateNotifications } from "./actions";

export const notificationActionTypes = {
  SET_NOTIFICATION: "SET_NOTIFICATION",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  SET_NOTIFICATION_READ: "SET_NOTIFICATION_READ"
};

function* setNotificationWorker(action) {
  try {
    setNotificationLoading(true);

    yield put({
      type: "SET_NOTIFICATION_DATA",
      payload: {
        data: isValidObject(store.getState().notifications.data)
          ? { ...store.getState().notifications.data, ...action.payload.data }
          : action.payload.data
      }
    });

    yield put({
      type: "SET_NOTIFICATION_PAGINATION",
      payload: {
        data:
          store.getState().notifications.pagination !== null &&
          isValidObject(store.getState().notifications.pagination)
            ? {
                ...store.getState().notifications.pagination,
                ...{
                  [action.payload.profileId]: {
                    0: action.payload.lastVisible
                  }
                }
              }
            : {
                [action.payload.profileId]: {
                  0: action.payload.lastVisible
                }
              }
      }
    });

    setNotificationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    setNotificationLoading(false);
  }
}

function* updateNotificationWorker(action) {
  try {
    yield setNotificationPaginationLoading(true);
    const profileId = store.getState().patients.currentProfile;
    const existingData = store.getState().notifications.data[profileId];
    const existingPagination =
      store.getState().notifications.pagination[profileId];

    const lastDocumentPageNumber = action.payload?.lastDocPageNumber
      ? action.payload?.lastDocPageNumber
      : isValidObject(store.getState().notifications.data) &&
        Object.values(store.getState().notifications?.data[profileId])[
          [
            Object.values(store.getState().notifications.data[profileId])
              .length - 1
          ]
        ]?.pageNumber;

    const paginationData = action.payload?.lastDocPageNumber
      ? Object.values(existingPagination)[action.payload.lastDocPageNumber - 1]
      : Object.values(existingPagination)[lastDocumentPageNumber];

    if (
      profileId &&
      paginationData &&
      typeof lastDocumentPageNumber === "number"
    ) {
      const paginationNotification = yield getPaginatedNotifications(
        profileId,
        paginationData,
        lastDocumentPageNumber,
        action.payload?.lastDocPageNumber ? true : false
      );

      if (paginationNotification.notifications) {
        yield put({
          type: "SET_NOTIFICATION_DATA",
          payload: {
            data: {
              ...store.getState().notifications.data,
              [store.getState().patients.currentProfile]: {
                ...existingData,
                ...paginationNotification.notifications
              }
            }
          }
        });
      }

      if (!action.payload?.lastDocPageNumber) {
        if (
          isValidObject(paginationNotification.lastVisible) ||
          paginationNotification.lastVisible === "end"
        ) {
          yield put({
            type: "SET_NOTIFICATION_PAGINATION",
            payload: {
              data: {
                ...store.getState().notifications.pagination,
                [store.getState().patients?.currentProfile]: {
                  ...existingPagination,
                  [lastDocumentPageNumber + 1]:
                    paginationNotification.lastVisible
                }
              }
            }
          });
        }
      }
    }

    yield setNotificationPaginationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setNotificationPaginationLoading(false);
  }
}

function* setNotificationReadWorker(action) {
  try {
    const profileId = store.getState().patients.currentProfile;
    if (profileId) {
      if (action.payload.type === "all") {
        let notificationsList = [];
        Object.values(store.getState().notifications.data[profileId]).filter(
          (data) => {
            return data.read === false && notificationsList.push(data);
          }
        );
        const response = yield setAllNotificationAsRead(notificationsList);
        if (response?.success === true) {
          yield updateNotifications(1);
        }
      } else {
        const response = yield setNotificationRead(
          action.payload.notifications.documentId,
          profileId
        );
        if (response.success === true) {
          yield updateNotifications(action.payload.notifications.pageNumber);
        }
      }
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

export function* notificationsWatcher() {
  yield all([takeEvery("SET_NOTIFICATION", setNotificationWorker)]);
  yield all([takeEvery("UPDATE_NOTIFICATION", updateNotificationWorker)]);
  yield all([takeEvery("SET_NOTIFICATION_READ", setNotificationReadWorker)]);
}

function* setNotificationLoading(value) {
  yield put({
    type: "SET_NOTIFICATION_LOADING",
    payload: {
      loading: value
    }
  });
}

function* setNotificationPaginationLoading(value) {
  yield put({
    type: "SET_NOTIFICATION_PAGINATION_LOADING",
    payload: {
      loading: value
    }
  });
}
