import { all, put, takeEvery } from "@redux-saga/core/effects";
import { errorHandler } from "../../services/error";
import LogRocket from "logrocket";
import { removeStatus } from "./actions";
import { logoutHealthId } from "../patients/actions";
import store from "../store/store";
import { logout } from "../authentication/actions";
import { logError } from "../../services/analytics";

export const statusActionTypes = {
  SET_ERROR_STATUS: "SET_ERROR_STATUS",
  SET_SUCCESS_STATUS: "SET_SUCCESS_STATUS",
  CLEAR_STATUS: "CLEAR_STATUS"
};

function* setErrorStatusWorker(action) {
  try {
    logError({
      code: action.payload.code,
      userType: "patients",
      message: action.payload.message,
      fatal: false // set to true if the error is fatal
    });

    LogRocket.captureMessage(action.payload.message, {
      tags: {
        userType: "patient"
      },
      extra: {
        fatal: false,
        code: action.payload.code
      }
    });
    const error = errorHandler(action.payload);
    if (error.message === "Token verification failed") {
      logoutHealthId(store.getState().patients.currentProfile);
    }

    yield put({
      type: "SET_STATUS",
      payload: {
        code: error.code,
        message: error.message
      }
    });
    setTimeout(() => {
      removeStatus();
    }, 4000);

    if (action.payload.code === "permission-denied") {
      logout();
    }
  } catch (error) {
    console.error(error);
  }
}

function* setSuccessStatusWorker(action) {
  try {
    yield put({
      type: "SET_STATUS",
      payload: {
        code: null,
        message: action.payload.message
      }
    });
    setTimeout(() => {
      removeStatus();
    }, 4000);
  } catch (error) {}
}

function* setStatusResponseWorker() {
  yield put({
    type: "REMOVE_STATUS"
  });
}

export default function* statusWatcher() {
  yield all([
    takeEvery("SET_ERROR_STATUS", setErrorStatusWorker),
    takeEvery("SET_SUCCESS_STATUS", setSuccessStatusWorker),
    takeEvery("CLEAR_STATUS", setStatusResponseWorker)
  ]);
}
