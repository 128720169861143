const abdmReducer = (
  state = {
    loading: false,
    OTPLoading: false,
    transactionId: null,
    newHealthNumberData: null,
    clinics: {
      search: {
        searchHistory: null,
        results: null,
        publicHealthPrograms: null,
        careContexts: null,
        linkRefNumber: null
      }
    },
    connect: {
      qrCodeDetails: null,
      clinicDetails: null
    },
    healthIdExists: null
  },
  action
) => {
  switch (action.type) {
    case "SET_ABDM_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

      case "SET_ABDM_TRANSACTION_ID":
      return {
        ...state,
        transactionId: action.payload
      };

    case "PUT_SEARCH_HISTORY":
      return {
        ...state,
        clinics: {
          ...state.clinics,
          search: {
            ...state.clinics.search,
            searchHistory: {
              ...state.clinics.search.searchHistory,
              ...action.payload.data
            }
          }
        }
      };

    case "SET_ABDM_OTP_LOADING":
      return {
        ...state,
        OTPLoading: action.payload.loading
      };

    case "SET_NEW_HEALTH_NUMBER_DATA":
      return {
        ...state,
        newHealthNumberData: action.payload.data
      };

    case "SET_ABDM_CLINIC_SEARCH_RESULTS":
      return {
        ...state,
        clinics: {
          ...state.clinics,
          search: {
            ...state.clinics.search,
            results: action.payload.searchResults
          }
        }
      };

    case "SET_PUBLIC_HEALTH_PROGRAMS":
      return {
        ...state,
        clinics: {
          ...state.clinics,
          search: {
            ...state.clinics.search,
            publicHealthPrograms: action.payload.data
          }
        }
      };

    case "SET_ABDM_LINK_REF_NUMBER":
      return {
        ...state,
        clinics: {
          ...state.clinics,
          search: {
            ...state.clinics.search,
            linkRefNumber: action.payload.data
          }
        }
      };

    case "CLEAR_ABDM_LINK_REF_NUMBER":
      return {
        ...state,
        clinics: {
          ...state.clinics,
          search: {
            ...state.clinics.search,
            linkRefNumber: null
          }
        }
      };

    case "SET_ABDM_SEARCHED_CLINIC_CARE_CONTEXTS":
      return {
        ...state,
        clinics: {
          ...state.clinics,
          search: {
            ...state.clinics.search,
            careContexts: action.payload
          }
        }
      };

    case "SET_ABDM_CLINIC_DETAILS":
      return {
        ...state,
        connect: {
          ...state.connect,
          clinicDetails: action.payload.clinicDetails
        }
      };

    case "SET_ABDM_QR_CODE_DETAILS":
      return {
        ...state,
        connect: {
          ...state.connect,
          qrCodeDetails: action.payload.qrCodeDetails
        }
      };

    case "CLEAR_CLINIC_SEARCH_DATA":
      return {
        ...state,
        clinics: {
          search: {
            results: null,
            careContexts: null,
            linkRefNumber: null
          }
        }
      };

    case "LOGOUT":
    case "LOGOUT_ABDM":
    case "RESET":
      return {
        loading: false,
        OTPLoading: false,
        transactionId: null,
        newHealthNumberData: null,
        clinics: {
          search: {
            results: null,
            careContexts: null,
            linkRefNumber: null
          }
        },
        connect: {
          qrCodeDetails: null,
          clinicDetails: null
        },
        healthIdExists: null
      };

    default:
      return state;
  }
};
export default abdmReducer;
