import { all, put, takeEvery } from "@redux-saga/core/effects";
// import { isValidString } from "../../front-end-global-components/services/validators";
import { validValue } from "../../front-end-global-components/utils/Functions";
import {
  acceptAndShareConnectionToClinic,
  checkHealthIdExists,
  discoverCareContextUsingClinicId,
  shareProfile,
  searchAbdmClinics,
  linkAbdmCareContextSendOtp,
  linkAbdmCareContextConfirmOtp,
  getPublicHealthPrograms,
  linkPhoneNumberAndAbhaNumber,
  createAbhaNumber,
  registerAbhaNumberUsingAadhar,
  searchAbdmClinicsWithHipId
} from "../../services/api";
import { throwError } from "../../services/error";
// import { consentStatus } from "../../utils/constants";
import { isValidArray } from "../../utils/validators";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import store from "../store/store";

export const abdmActionTypes = {
  SEARCH_ABDM_CLINIC: "SEARCH_ABDM_CLINIC",
  SHARE_PROFILE: "SHARE_PROFILE",
  CLEAR_CLINIC_SEARCH_RESULTS: "CLEAR_CLINIC_SEARCH_RESULTS",
  SET_SEARCH_HISTORY: "SET_SEARCH_HISTORY",
  GET_PUBLIC_HEALTH_PROGRAMS: "GET_PUBLIC_HEALTH_PROGRAMS",
  CHECK_HEALTH_ID_EXISTS: "CHECK_HEALTH_ID_EXISTS",
  CLEAR_HEALTH_ID_EXISTS_STATUS: "CLEAR_HEALTH_ID_EXISTS_STATUS",
  ADD_ABDM_AUTH_CREDENTIALS: "ADD_ABDM_AUTH_CREDENTIALS",
  CLEAR_ABDM_AUTH_CREDENTIALS: "CLEAR_ABDM_AUTH_CREDENTIALS",
  DISCOVER_ABDM_CARE_CONTEXT_WITH_CLINIC_ID:
    "DISCOVER_ABDM_CARE_CONTEXT_WITH_CLINIC_ID",
  SEND_OTP_TO_LINK_ABDM_CARE_CONTEXT: "SEND_OTP_TO_LINK_ABDM_CARE_CONTEXT",
  CONFIRM_OTP_TO_LINK_ABDM_CARE_CONTEXT:
    "CONFIRM_OTP_TO_LINK_ABDM_CARE_CONTEXT",
  GET_SCANNED_CLINIC_DETAILS: "GET_SCANNED_CLINIC_DETAILS",
  CLEAR_ABDM_CONNECT_DETAILS: "CLEAR_ABDM_CONNECT_DETAILS",
  CLEAR_LINK_REF_NUMBER: "CLEAR_LINK_REF_NUMBER",
  SHARE_CONNECTION_TO_CLINIC: "SHARE_CONNECTION_TO_CLINIC",
  REGISTER_NEW_ABHA_NUMBER: "REGISTER_NEW_ABHA_NUMBER"
};

function* checkHealthIdExistsWorker(action) {
  try {
    yield setAbdmLoading(true);
    const response = yield checkHealthIdExists(
      action.payload.healthId,
      store.getState().auth.data.accessToken
    );
    if (response?.error) {
      throwError(response.error);
    }
    if (
      response?.success === true &&
      typeof response?.data?.isHealthIdExists?.status === "boolean"
    )
      yield setHealthIdExist(response.data.isHealthIdExists.status);

    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    setErrorStatus(error);
  }
}

function* setSearchHistoryWorker(action) {
  try {
    yield setAbdmLoading(true);

    yield put({
      type: "PUT_SEARCH_HISTORY",
      payload: {
        data: action.payload.data
      }
    });

    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    setErrorStatus(error);
  }
}

function* clearClinicSearchResultsWorker() {
  yield put({
    type: "SET_ABDM_CLINIC_SEARCH_RESULTS",
    payload: {
      searchResults: []
    }
  });
}

function* setAbdmAuthCredentialsWorker(action) {
  try {
    yield put({
      type: "SET_ABDM_OTP_CREDENTIALS",
      payload: {
        requestMethod: action.payload.requestMethod,
        transactionId: action.payload.transactionId
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* clearHealthIdExistsStatusWorker(action) {
  try {
    yield setHealthIdExist(null);
  } catch (error) {
    setErrorStatus(error);
  }
}

function* clearAbdmAuthCredentialsWorker() {
  try {
    yield put({
      type: "SET_ABDM_OTP_CREDENTIALS",
      payload: {
        requestMethod: null,
        transactionId: null
      }
    });
    if (
      Array.isArray(store.getState().abdm?.data?.healthId) &&
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ] === null
    ) {
      yield setAbdmHealthIds(null);
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* searchAbdmClinicWorker(action) {
  try {
    const previousSearchResults = isValidArray(
      store.getState().abdm.clinics.search.results
    )
      ? store.getState().abdm.clinics.search.results
      : [];

    if (
      previousSearchResults.filter(
        (clinic) => clinic?.identifier?.name === action.payload.searchKey
      ).length === 0
    ) {
      yield setAbdmLoading(true);
      const response = yield searchAbdmClinics(
        store.getState().auth.data.accessToken,
        store.getState().patients.accessToken[
          store.getState().patients.currentProfile
        ],
        action.payload.searchKey
      );

      if (response?.success === true && response?.data) {
        let clinics = {};

        response.data.forEach((data) => {
          clinics = { ...clinics, [data.identifier.id]: data };
        });
        yield put({
          type: "SET_ABDM_CLINIC_SEARCH_RESULTS",
          payload: {
            searchResults: clinics
          }
        });
        yield setAbdmLoading(false);
      } else {
        yield put({
          type: "SET_ABDM_CLINIC_SEARCH_RESULTS",
          payload: {
            searchResults: []
          }
        });
        throwError(response);
      }
    }
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* getPublicHealthProgramsWorker(action) {
  try {
    yield setAbdmLoading(true);
    const response = yield getPublicHealthPrograms(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ]
    );
    if (response?.success === true) {
      let obj = {};
      response.data.forEach((data) => {
        obj[data?.identifier?.id] = data;
      });
      yield put({
        type: "SET_PUBLIC_HEALTH_PROGRAMS",
        payload: {
          data: obj
        }
      });
    } else {
      throwError(response);
    }
    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* discoverCareContextWorker(action) {
  try {
    yield setAbdmLoading(true);
    const response = yield discoverCareContextUsingClinicId(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      action.payload.clinicId,
      store.getState().auth.data.phoneNumber,
      action.payload.patientId
    );

    if (!!response?.data) {
      yield put({
        type: "SET_ABDM_SEARCHED_CLINIC_CARE_CONTEXTS",
        payload: {
          [action.payload.clinicId]: response.data
        }
      });
    }

    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* sendOtpToLinkAbdmCareContextWorker(action) {
  try {
    yield setAbdmOTPLoading(true);

    let careContextReferenceNumber = [];
    Object.values(action.payload.data).forEach((data) => {
      careContextReferenceNumber.push({
        referenceNumber: data.referenceNumber
      });
    });
    const careContextData =
      store.getState().abdm?.clinics?.search?.careContexts[
        action.payload.clinicId
      ];
    const response = yield linkAbdmCareContextSendOtp(
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken,
      careContextReferenceNumber,
      careContextData?.transactionId,
      careContextData?.referenceNumber
    );
    if (response?.success === true) {
      yield put({
        type: "SET_ABDM_LINK_REF_NUMBER",
        payload: {
          data: response.data.link.referenceNumber
        }
      });
      yield setAbdmOTPLoading(false);
    } else {
      throwError(response);
    }
  } catch (error) {
    yield setAbdmOTPLoading(false);
    yield setErrorStatus(error);
  }
}

function* confirmOtpToLinkAbdmCareContextWorker(action) {
  try {
    yield setAbdmOTPLoading(true);

    const response = yield linkAbdmCareContextConfirmOtp(
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken,
      store.getState().abdm?.clinics?.search?.linkRefNumber,
      action.payload.otp
    );
    if (response?.success === true) {
      yield setAbdmOTPLoading(false);
      if (typeof action.payload.navigate === "function") {
        action.payload.navigate("/");
        setSuccessStatus("document linked successfully");
        yield put({
          type: "CLEAR_CLINIC_SEARCH_DATA",
          payload: {
            data: null
          }
        });
      }
    }
    yield setAbdmOTPLoading(false);
  } catch (error) {
    yield setAbdmOTPLoading(false);
    yield setErrorStatus(error);
  }
}

function* getScannedClinicDetailsWorker(action) {
  try {
    if (typeof action.payload.hipId !== "string") {
      throwError("custom", "Hip-Id not available");
    }
    yield setAbdmLoading(true);

    const getClinicDetailsWithHipIdResponse = yield searchAbdmClinicsWithHipId(
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken,
      action.payload.hipId,
      action.payload.hipCode
    );
    if (getClinicDetailsWithHipIdResponse?.success === true) {
      yield put({
        type: "SET_ABDM_QR_CODE_DETAILS",
        payload: {
          qrCodeDetails: {
            hipId: action.payload.hipId,
            hipCode: action.payload.hipCode ? action.payload.hipCode : null
          }
        }
      });
      yield put({
        type: "SET_ABDM_CLINIC_DETAILS",
        payload: {
          clinicDetails: {
            ...getClinicDetailsWithHipIdResponse.data
          }
        }
      });
      yield setAbdmLoading(false);
      if (typeof action.payload.push === "function") {
        action.payload.push("/scannedClinicDetails");
      }
    } else {
      throwError(getClinicDetailsWithHipIdResponse);
    }
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* shareProfileWorker(action) {
  try {
    yield setAbdmLoading(true);

    const response = yield shareProfile(
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken,
      store.getState().abdm.connect.qrCodeDetails.hipId,
      store.getState().abdm.connect.qrCodeDetails.hipCode
    );
    console.log(response?.success);
    if (response?.success === true) {
      yield setAbdmLoading(false);
      setSuccessStatus("Clinic Authorized Successfully");
      action.payload.navigate("/");
    } else {
      throwError(response?.message);
    }
    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* clearABdmConnectDetailsWorker() {
  try {
    yield setAbdmLoading(true);
    yield put({
      type: "SET_ABDM_QR_CODE_DETAILS",
      payload: {
        qrCodeDetails: null
      }
    });
    yield put({
      type: "SET_ABDM_CLINIC_DETAILS",
      payload: {
        clinicDetails: null
      }
    });

    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* clearLinkRefNumberWorker() {
  try {
    yield put({
      type: "SET_ABDM_LINK_REF_NUMBER",
      payload: {
        data: null
      }
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* shareConnectionToClinicWorker(action) {
  try {
    const hipId = validValue(
      () => store.getState().abdm.connect.qrCodeDetails.hipId
    );
    const hipCode = validValue(
      () => store.getState().abdm.connect.qrCodeDetails.hipCode
    );
    if (typeof hipId !== "string" || typeof hipCode !== "string") {
      throwError("custom", "Hip-code & Hip-id not available");
    }

    yield setAbdmLoading(true);

    const acceptAndShareConnectionToClinicResponse =
      yield acceptAndShareConnectionToClinic(
        store.getState().patients.accessToken[
          store.getState().patients.currentProfile
        ],
        hipId,
        hipCode
      );

    if (acceptAndShareConnectionToClinicResponse.status === false) {
      throwError(
        "custom",
        acceptAndShareConnectionToClinicResponse.error.message
      );
    }

    setSuccessStatus("Successfully Connected to Clinic");

    yield setAbdmLoading(false);

    if (typeof action.payload.go === "function") {
      action.payload.go(-2);
    }

    yield put({
      type: "SET_ABDM_QR_CODE_DETAILS",
      payload: {
        qrCodeDetails: null
      }
    });
    yield put({
      type: "SET_ABDM_CLINIC_DETAILS",
      payload: {
        clinicDetails: null
      }
    });
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* registerNewAbhaNumberWorker(action) {
  try {
    yield setAbdmLoading(true);

    if (action.payload.type === "sendAadharOtp") {
      const response = yield registerAbhaNumberUsingAadhar(
        store.getState().auth.data.accessToken,
        { aadhaarNumber: action.payload.aadhaarNumber },
        "sendOtp"
      );

      if (response.success) {
        yield put({
          type: "SET_ABDM_TRANSACTION_ID",
          payload: response.data.transactionId
        });
      }
    }

    if (action.payload.type === "verifyAadharOtp") {
      const response = yield registerAbhaNumberUsingAadhar(
        store.getState().auth.data.accessToken,
        {
          otp: action.payload.otp,
          transactionId: store.getState().abdm.transactionId
        },
        "verifyOtp"
      );

      if (response.success) {
        yield put({
          type: "SET_ABDM_TRANSACTION_ID",
          payload: `aadhaarVerified${response.data.transactionId}`
        });
      }
    }

    if (action.payload.type === "sendMobileOtp") {
      const response = yield linkPhoneNumberAndAbhaNumber(
        store.getState().auth.data.accessToken,
        {
          transactionId: store
            .getState()
            .abdm.transactionId.split("aadhaarVerified")[1],
          mobileNumber: action.payload.mobileNumber
        },
        "sendOtp"
      );
      if (response.success) {
        yield put({
          type: "SET_ABDM_TRANSACTION_ID",
          payload: `getMobileOtp${response.data.transactionId}`
        });
      }
    }

    if (action.payload.type === "verifyMobileOtp") {
      const response = yield linkPhoneNumberAndAbhaNumber(
        store.getState().auth.data.accessToken,
        {
          transactionId: store
            .getState()
            .abdm.transactionId.split("getMobileOtp")[1],
          otp: action.payload.otp
        },
        "verifyOtp"
      );
      if (response.success) {
        const createHealthNumberResponse = yield createAbhaNumber(
          store.getState().auth.data.accessToken,
          { transactionId: response.data }
        );
        if (createHealthNumberResponse.success) {
          yield put({
            type: "SET_NEW_HEALTH_NUMBER_DATA",
            payload: { data: createHealthNumberResponse.data }
          });
        }
      }
    }

    if (action.payload.clearNewHealthNumberData) {
      yield put({
        type: "SET_NEW_HEALTH_NUMBER_DATA",
        payload: { data: null }
      });
    }

    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
  }
}

export default function* abdmWatcher() {
  yield all([
    takeEvery("ADD_ABDM_AUTH_CREDENTIALS", setAbdmAuthCredentialsWorker),
    takeEvery("SET_SEARCH_HISTORY", setSearchHistoryWorker),
    takeEvery("CLEAR_CLINIC_SEARCH_RESULTS", clearClinicSearchResultsWorker),
    takeEvery("GET_PUBLIC_HEALTH_PROGRAMS", getPublicHealthProgramsWorker),
    takeEvery("CLEAR_ABDM_AUTH_CREDENTIALS", clearAbdmAuthCredentialsWorker),
    takeEvery("CHECK_HEALTH_ID_EXISTS", checkHealthIdExistsWorker),
    takeEvery("CLEAR_HEALTH_ID_EXISTS_STATUS", clearHealthIdExistsStatusWorker),
    takeEvery("SEARCH_ABDM_CLINIC", searchAbdmClinicWorker),
    takeEvery(
      "DISCOVER_ABDM_CARE_CONTEXT_WITH_CLINIC_ID",
      discoverCareContextWorker
    ),
    takeEvery(
      "SEND_OTP_TO_LINK_ABDM_CARE_CONTEXT",
      sendOtpToLinkAbdmCareContextWorker
    ),
    takeEvery(
      "CONFIRM_OTP_TO_LINK_ABDM_CARE_CONTEXT",
      confirmOtpToLinkAbdmCareContextWorker
    ),
    takeEvery("GET_SCANNED_CLINIC_DETAILS", getScannedClinicDetailsWorker),
    takeEvery("SHARE_PROFILE", shareProfileWorker),
    takeEvery("CLEAR_ABDM_CONNECT_DETAILS", clearABdmConnectDetailsWorker),
    takeEvery("CLEAR_LINK_REF_NUMBER", clearLinkRefNumberWorker),
    takeEvery("SHARE_CONNECTION_TO_CLINIC", shareConnectionToClinicWorker),
    takeEvery("REGISTER_NEW_ABHA_NUMBER", registerNewAbhaNumberWorker)
  ]);
}

function* setAbdmHealthIds(healthId) {
  const healthIds = !!healthId ? [healthId].flat() : null;
  yield put({
    type: "SET_ABDM_HEALTH_ID",
    payload: {
      healthId: healthIds
    }
  });
}

function* setAbdmLoading(loadingState) {
  if (store.getState().abdm.loading !== loadingState) {
    yield put({
      type: "SET_ABDM_LOADING",
      payload: {
        loading: loadingState
      }
    });
  }
}

function* setAbdmOTPLoading(loadingState) {
  yield put({
    type: "SET_ABDM_OTP_LOADING",
    payload: {
      loading: loadingState
    }
  });
}

function* setHealthIdExist(healthIdExists) {
  //healthIdExists :boolean
  yield put({
    type: "SET_HEALTH_ID_EXISTS_STATUS",
    payload: {
      healthIdExists: healthIdExists
    }
  });
}
