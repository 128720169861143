import React, { useState, useRef, useEffect } from "react";
import dropDownStatusIcon from "../../assets/Next.svg";
import { isValidArray, isValidString } from "../../services/validators";
import closeIcon from "../../assets/close.svg";
import addGreyIcon from "../../assets/add_grey.svg";
import inputValidIcon from "../../assets/inputValid.svg";
import "./Select.css";
import PropTypes from "prop-types";

function Select(props) {
  const [chips, setChips] = useState([]);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const selectedChipIndexRef = useRef(null);
  const selectCompRef = useRef(null);
  const label = useRef(null);
  const [showBottomModal, setShowBottomModal] = useState(false);
  // set chips
  useEffect(() => {
    if (props.chips) {
      setChips([...props.chips]);
    }
  }, [props.chips]);

  const onChipClick = (event, index) => {
    event.stopPropagation();
    if (selectedChipIndex === index) {
      let newArray = [...chips];
      newArray.splice(index, 1);
      typeof props.onChipAdded === "function" &&
        props.onChipAdded([...newArray]);
      setSelectedChipIndex(null);
      selectedChipIndexRef.current = null;
    } else {
      selectedChipIndexRef.current = index;
      setSelectedChipIndex(index);
    }
  };

  return (
    <>
      <div
        className={` position-relative min-height-48px inherit-parent-width select-border-bottom-default padding-top-medium  ${
          props.disabled === true ? " background-color-grey " : ""
        }`}
        data-cy={
          props["data-cy"] ? props["data-cy"] : `${props.name}-select-box-comp`
        }
        ref={selectCompRef}
        onClick={() => {
          label.current && label.current.classList.add("active");
          setShowBottomModal(true);
        }}
      >
        <label
          data-cy={`label-${props.name}`}
          ref={label}
          className={` font-color-secondary select-label position-absolute font-family-gilroy-medium font-size-medium ${
            props.labelClassName ? props.labelClassName : ""
          } ${
            isValidArray(chips) || props.alwaysActive === true ? "active" : ""
          } ${props.disabled ? " disabled " : ""}
          `}
        >
          {props.label}
        </label>
        <section className="inherit-parent-width inherit-parent-height ">
          {isValidArray(chips) && (
            <section
              className="max-height-128px flex-direction-row gap-0-point-5em margin-top-small flex-wrap overflow-auto padding-top-default padding-bottom-default padding-right-medium"
              data-cy="chips-container"
            >
              {isValidArray(chips) &&
                chips.map((chipData, index) => (
                  <div
                    data-cy={`${chipData}-chip${
                      selectedChipIndex === index ? "-selected" : ""
                    }`}
                    className={`text-transform-capitalize text-overflow-ellipsis padding-small border-2px-lite-grey text-transform-uppercase border-radius-default font-size-small font-family-gilroy-regular font-color-secondary ${
                      selectedChipIndex === index
                        ? "background-color-lite-grey"
                        : ""
                    } flex-center-children-vertically `}
                    key={`chip-data-${index}`}
                    onClick={(event) => onChipClick(event, index)}
                  >
                    <span>{chipData}</span>
                    {selectedChipIndex === index && (
                      <img
                        className="margin-left-default height-width-0-point-5em"
                        src={closeIcon}
                        alt="close"
                      />
                    )}
                  </div>
                ))}
            </section>
          )}
        </section>
        <img
          src={dropDownStatusIcon}
          alt="dropdown"
          className="select-suffix-icon"
          data-cy="select-toggle-icon"
        />
      </div>
      <AnimatedBottomModal
        showAddOption={props.showAddOption}
        show={showBottomModal}
        onClickClose={() => {
          setShowBottomModal(false);
        }}
        selectCompRef={selectCompRef}
        chips={chips}
        modalTitle={props.modalTitle}
        selectedChipIndex={selectedChipIndex}
        setSelectedChipIndex={setSelectedChipIndex}
        selectedChipIndexRef={selectedChipIndexRef}
        label={label}
        onChipAdded={props.onChipAdded}
        name={props.name}
        options={props.options}
        multiSelect={props.multiSelect}
        onChipClick={onChipClick}
        loading={props.loading}
        maxLength={props.maxLength}
      />
    </>
  );
}

Select.propTypes = {
  /**
   * array of inputs
   */
  chips: PropTypes.arrayOf(PropTypes.string),
  /**
   * callback function which returns the updated chip input array
   */
  onChipAdded: PropTypes.func,
  /**
   * disabled state of the select component
   */
  disabled: PropTypes.bool,
  /**
   * selector attribute for cypress testing , default value is input name with `-select-box-comp`
   */
  "data-cy": PropTypes.string,
  /**
   * name attribute of the select input element
   */
  name: PropTypes.string,
  /**
   * class name for the label element
   */
  labelClassName: PropTypes.string,
  /**
   * sets label to active state
   */
  alwaysActive: PropTypes.bool,
  /**
   * content for the label element
   */
  label: PropTypes.string,
  /**
   * AnimatedBottomModal title
   */
  modalTitle: PropTypes.string,
  /**
   * pre defined options for select option
   */
  options: PropTypes.arrayOf(PropTypes.string),
  /**
   * shows loading suspense for the component
   */
  loading: PropTypes.bool,
  /**
   * which enables the multi select options
   */
  multiSelect: PropTypes.bool,
  /**
   * Hide/show add option in select dropdown
   */
  showAddOption: PropTypes.bool,
  maxLength: PropTypes.number
};

Select.defaultProps = {
  onChipAdded: undefined,
  showAddOption: true,
  chips: [],
  disabled: false,
  "data-cy": undefined,
  name: "default",
  labelClassName: "",
  alwaysActive: false,
  label: "",
  modalTitle: "",
  options: [],
  loading: false,
  multiSelect: false,
  maxLength: 25
};
export default Select;

function AnimatedBottomModal(props) {
  const [entranceAnimation, setEntranceAnimation] = useState(false);
  const childElementRef = useRef(null);
  const bottomCardRef = useRef(null);
  const [value, setValue] = useState("");
  const inputRef = useRef(null);
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [complete, setComplete] = useState(false);
  const previousLoadingState = useRef(false);

  const closeModal = () => {
    setEntranceAnimation(false);
    setTimeout(() => {
      bottomCardRef.current &&
        bottomCardRef.current.classList.remove("height-70vh");
      bottomCardRef.current &&
        bottomCardRef.current.classList.add("height-50vh");
      props.onClickClose();
    }, 80);
  };
  // set searched options
  useEffect(() => {
    setSearchedOptions([...props.options]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set complete status
  useEffect(() => {
    if (previousLoadingState.current === true && props.loading === false) {
      setComplete(true);
    } else {
      if (complete) setComplete(false);
    }
    previousLoadingState.current = props.loading;
    // eslint-disable-next-line
  }, [props.loading]);

  //set animation
  useEffect(() => {
    if (props.show === true) {
      setEntranceAnimation(true);
      inputRef.current && inputRef.current.focus();
    }
    if (props.closeModal) {
      props.closeModal(closeModal);
    }
    if (!props.show && !isValidArray(props.chips)) {
      props.label.current && props.label.current.classList.remove("active");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  // handle click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !(
          (childElementRef.current &&
            childElementRef.current.children[0].contains(event.target)) ||
          (props.selectCompRef.current &&
            props.selectCompRef.current.contains(event.target))
        )
      ) {
        closeModal();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childElementRef]);

  const onItemSelect = (item) => {
    let newList = [];
    if (props.multiSelect === true) {
      inputRef.current && inputRef.current.focus();
      const prevState = [...props.chips];
      if (prevState.includes(item)) {
        newList = prevState.filter((e) => e !== item);
      } else {
        newList = [item, ...prevState];
      }
      if (newList.length > 0) {
        props.label.current.classList.add("active");
      } else {
        props.label.current.classList.remove("active");
      }
    } else {
      const prevState = [...props.chips];
      if (prevState.includes(item)) {
        newList = [];
      } else {
        newList = [item];
      }
    }
    props.onChipAdded && props.onChipAdded([...newList]);
  };

  // true for show
  // False for not to show
  const shouldShowAddOptionButton = () => {
    let result = true;
    if (!isValidArray(searchedOptions)) {
      if (isValidArray(props.chips)) {
        props.chips.some((chip) => {
          const _returnResult = chip.toLowerCase() === value.toLowerCase();
          if (_returnResult) {
            result = false;
          }
          return _returnResult;
        });
      } else {
        result = true;
      }
    } else if (isValidString(value) && isValidArray(searchedOptions)) {
      if (isValidArray(props.chips)) {
        props.chips.some((chip) => {
          const _returnResult = chip.toLowerCase() === value.toLowerCase();
          if (_returnResult) {
            result = false;
          }
          return _returnResult;
        });
      } else {
        searchedOptions.some((option) => {
          const _returnResult = option.toLowerCase() === value.toLowerCase();
          if (_returnResult) {
            result = false;
          }
          return _returnResult;
        });
      }
      // return result;
    } else {
      result = false;
    }

    return result;
  };

  const onSearch = (text) => {
    if (text.length === 0) {
      setSearchedOptions([...props.options]);
      return;
    }
    text = text.toLowerCase().split(" ");
    const searchedList = props.options.filter((item) => {
      item = item.toLowerCase();
      return text.every((el) => {
        return item.indexOf(el) > -1;
      });
    });
    setSearchedOptions([...searchedList]);
  };

  const InputSuffixIcon = () => {
    if (props.loading === true) {
      return (
        <div
          className="position-absolute right-0 top-30-percentage circular-loader"
          data-cy={`${props.name}-select-box-loader-icon`}
        />
      );
    } else if (isValidArray(props.chips) && complete === true) {
      return (
        <img
          className={`position-absolute right-0 top-30-percentage height-width-1em ${props.iconClassNames}`}
          src={inputValidIcon}
          alt="inputValidIcon"
          data-cy={`${props.name}-select-box-complete-icon`}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {props.show && (
        <article
          className="background-color-black-with-opacity-light inherit-parent-height inherit-parent-width position-fixed z-index-1 top-0 left-0 "
          data-cy="animated-bottom-modal"
        >
          <main
            className={` inherit-parent-height inherit-parent-width display-flex justify-content-flex-end align-items-flex-end  animated-bottom-modal-anime ${
              entranceAnimation ? "animated-bottom-modal-anime-entrance" : ""
            }`}
            ref={childElementRef}
          >
            <div
              ref={bottomCardRef}
              className="padding-top-default padding-left-large padding-right-large inherit-parent-width flex-center-children-vertically flex-direction-column height-50vh background-white"
            >
              <h5
                className="text-transform-uppercase font-weight-normal font-family-gilroy-regular font-color-secondary margin-top-default margin-bottom-default"
                data-cy="modal-title"
              >
                {props.modalTitle}
              </h5>
              {isValidArray(props.chips) && (
                <section
                  className="max-height-50px inherit-parent-width flex-direction-row gap-0-point-5em flex-wrap overflow-auto padding-top-default padding-bottom-default"
                  data-cy="modal-chips-container"
                >
                  {props.chips.map((chipData, index) => (
                    <div
                      data-cy={`modal-${chipData}-chip${
                        props.selectedChipIndex === index ? "-selected" : ""
                      }`}
                      className={`text-transform-capitalize text-overflow-ellipsis padding-small border-2px-lite-grey text-transform-uppercase border-radius-default font-size-small font-family-gilroy-regular font-color-secondary ${
                        props.selectedChipIndex === index
                          ? "background-color-lite-grey"
                          : ""
                      } flex-center-children-vertically `}
                      key={`chip-data-${index}`}
                      onClick={(event) => props.onChipClick(event, index)}
                    >
                      <span>{chipData}</span>
                      {props.selectedChipIndex === index && (
                        <img
                          className="margin-left-default height-width-0-point-5em"
                          src={closeIcon}
                          alt="close"
                        />
                      )}
                    </div>
                  ))}
                </section>
              )}
              <section className="inherit-parent-width position-relative">
                <input
                  className={` font-size-medium padding-default padding-right-larger display-block inherit-parent-width font-family-gilroy-medium input-search-border-bottom-default padding-left-small border-radius-0`}
                  data-cy={`${props.name}-select-box-search-input`}
                  id="add"
                  type={"text"}
                  name="search"
                  ref={inputRef}
                  onFocus={() => {
                    bottomCardRef.current &&
                      bottomCardRef.current.classList.add("height-70vh");
                  }}
                  value={value}
                  placeholder="SEARCH"
                  autoComplete="off"
                  onChange={(event) => {
                    event.preventDefault();
                    setValue(event.target.value);
                    onSearch(event.target.value);
                  }}
                  inputMode="search"
                  maxLength={props.maxLength ? props.maxLength : 25}
                />
                <InputSuffixIcon />
              </section>

              <section
                data-cy={`${props.name}-options-section`}
                className={`inherit-parent-width background-color-white border-bottom-2px-lite-grey overflow-auto ${
                  isValidArray(props.chips)
                    ? "flex-basis-70-percentage "
                    : "flex-basis-80-percentage "
                } `}
              >
                <ul className="list-style-type-none ">
                  <li className="hide-scroll-bar">
                    {searchedOptions.map((data, index) => {
                      return (
                        <ListItem
                          data={data}
                          key={index}
                          onItemSelect={onItemSelect}
                          selected={props.chips.includes(data)}
                        />
                      );
                    })}
                  </li>
                  <li>
                    {props.showAddOption !== false &&
                      shouldShowAddOptionButton() === true && (
                        <button
                          className="inherit-parent-width padding-top-default padding-bottom-default background-color-white flex-direction-row flex-justify-content-space-between"
                          onClick={() => {
                            let newArray = [value, ...props.chips];
                            props.onChipAdded &&
                              props.onChipAdded([...newArray]);
                            setValue("");
                            setSearchedOptions([...props.options]);
                            inputRef.current && inputRef.current.focus();
                          }}
                          data-cy="add-custom-value-button"
                        >
                          <span className="font-family-gilroy-regular font-size-medium font-color-secondary ">
                            Add "{`${value}`}"
                          </span>
                          <img src={addGreyIcon} alt="add" />
                        </button>
                      )}
                  </li>
                </ul>
              </section>
              <button
                type="button"
                className="inherit-parent-width padding-top-default padding-bottom-default background-color-white flex-direction-row justify-content-flex-start flex-center-children-vertically "
                onClick={(event) => {
                  event.preventDefault();
                  closeModal();
                }}
                data-cy="close-modal-btn"
              >
                <img src={closeIcon} alt="close" />
                <span className="font-family-gilroy-regular font-size-medium font-color-secondary margin-left-default ">
                  Close
                </span>
              </button>
            </div>
          </main>
        </article>
      )}
    </>
  );
}

function ListItem(props) {
  const [selected, setSelected] = useState(false);
  // set selected data
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const CheckIcon = () => {
    if (selected) {
      return (
        <svg
          data-cy={`${props.data}-selected`}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#00a000"
          className="bi bi-check2"
          viewBox="0 0 16 16"
        >
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
        </svg>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className="padding-small"
      onClick={() => {
        setSelected((prevState) => !prevState);
        props.onItemSelect(props.data);
      }}
      data-cy={`${props.data}-select-option-item`}
    >
      <div className="flex-center-children-vertically flex-justify-content-space-between">
        <span className="font-family-gilroy-regular font-size-medium font-color-secondary ">
          {props.data}
        </span>
        <CheckIcon />
      </div>
    </div>
  );
}
