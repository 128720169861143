import { put, takeEvery, all } from "redux-saga/effects";
import {
  checkPhoneNumberAvailability,
  sendOAuthAccessCode
} from "../../services/api";
import {
  otpValidate,
  logout,
  otpRequest,
  signInWithAuthCredentials
  // setDisplayName,
  // getCurrentUser
} from "../../services/authentication";
import store from "../store/store";
import { throwError } from "../../services/error";
import { createProfile } from "../../services/database";
import { setErrorStatus } from "../status/actions";
import { login, setOAuthAccessToken, signUp } from "./actions";

export const authActionTypes = {
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGOUT: "LOGOUT",
  PUT_AUTH_INFO: "PUT_AUTH_INFO",
  CLEAR_AUTH_CREDENTIALS: "CLEAR_AUTH_CREDENTIALS",
  REFRESH_ACCESS_TOKEN: "REFRESH_ACCESS_TOKEN",
  SET_OAUTH_ACCESS_TOKEN: "SET_OAUTH_ACCESS_TOKEN",
  SEND_OAUTH_ACCESS_CODE: "SEND_OAUTH_ACCESS_CODE"
};

function* sendOtpWorker(action) {
  try {
    yield setAuthLoading(true);
    if (
      typeof action.payload.phoneNumber !== "string" ||
      (action.payload.phoneNumber.length < 13 &&
        action.payload.phoneNumber.length > 15)
    ) {
      throwError("custom", "Please enter a valid phone number");
    } else if (isNaN(action.payload.phoneNumber)) {
      throwError("custom", "Please enter a valid phone number");
    }
    if (action.payload.type === "signUp") {
      const response = yield checkPhoneNumberAvailability(
        action.payload.phoneNumber
      );
      if (
        response.data.phoneNumberExists === true &&
        response.data.profileExists === true
      ) {
        //set existing account signup attempt
        yield put({
          type: "SET_AUTH_SIGNUP_ATTEMPT",
          payload: {
            phoneNumber: action.payload.phoneNumber
          }
        });
        throwError("custom", "User already registered.Please login");
      } else {
        const verificationId = yield otpRequest(action.payload.phoneNumber);
        yield put({
          type: "SET_AUTH_VERIFICATION_ID",
          payload: {
            verificationId: verificationId
          }
        });
      }
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: false
        }
      });
    } else if (action.payload.type === "login") {
      const response = yield checkPhoneNumberAvailability(
        action.payload.phoneNumber
      );
      if (
        response.data.phoneNumberExists === false ||
        response.data.profileExists === false
      ) {
        throwError("custom", "User does not exist. Please signup");
      } else {
        const verificationId = yield otpRequest(action.payload.phoneNumber);
        yield put({
          type: "SET_AUTH_VERIFICATION_ID",
          payload: {
            verificationId: verificationId
          }
        });
      }
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: false
        }
      });
    }
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* otpVerifyWorker(action) {
  try {
    yield setAuthLoading(true);
    const authCredentials = yield otpValidate(
      action.payload.loginOtp,
      store.getState().auth.credentials.verificationId
    );
    yield put({
      type: "SET_AUTH_CREDENTIALS",
      payload: {
        authCredential: authCredentials
      }
    });
    if (action.payload.type === "signUp") {
      signUp(action.payload.userName, action.payload.phoneNumber);
    } else {
      login();
    }
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* loginWorker() {
  try {
    yield setAuthLoading(true);
    const response = yield signInWithAuthCredentials(
      store.getState().auth.credentials.authCredential
    );
    yield put({
      type: "SET_AUTH_INFO",
      payload: {
        accessToken: response.user.accessToken,
        uid: response.user.uid,
        phoneNumber: response.user.phoneNumber,
        displayName: response.user.displayName
      }
    });
    yield setAuthLoading(false);
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/signup"
    ) {
      window.history.pushState({}, null, "/");
    }
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* signUpWorker(action) {
  try {
    if (store.getState().auth.data.accessToken === null) {
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: true
        }
      });
      const response = yield signInWithAuthCredentials(
        store.getState().auth.credentials.authCredential
      );
      // const displayNames = yield setDisplayName(action.payload.userName);
      yield put({
        type: "SET_AUTH_INFO",
        payload: {
          accessToken: response.user.accessToken,
          uid: response.user.uid,
          phoneNumber: response.user.phoneNumber,
          displayName: action.payload.userName
        }
      });
      yield createProfile();
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: false
        }
      });
      if (
        window.location.pathname === "/login" ||
        window.location.pathname === "/signup"
      ) {
        window.history.pushState({}, null, "/");
      }
    } else {
      throwError("custom", "User already logged in");
    }
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
    yield logout();
  }
}

function* putAuthInfoWorker(action) {
  try {
    yield setAuthLoading(true);
    yield put({
      type: "SET_AUTH_INFO",
      payload: action.payload.data
    });
  } catch (error) {
    yield setAuthLoading(false);
    yield setErrorStatus(error);
  }
}

function* logoutWorker() {
  try {
    yield setAuthLoading(true);
    yield logout();
    yield put({
      type: "RESET"
    });
    // yield indexedDB.databases().then((dataBase) => {
    //   dataBase.forEach((dbName) => {
    //     indexedDB.deleteDatabase(dbName.name);
    //   });
    // });
    yield setAuthLoading(false);
  } catch (error) {
    yield setAuthLoading(false);
    yield setErrorStatus(error);
  }
}

function* clearAuthCredentialsWorker() {
  try {
    yield put({
      type: "REMOVE_AUTH_CREDENTIALS"
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* accessTokenRefreshWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_TOKEN",
      payload: {
        ...store.getState().auth.data,
        accessToken: action.payload.accessToken,
        phoneNumber: action.payload.phoneNumber
      }
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* sendOAuthAccessCodeWorker(action) {
  try {
    /**
     * send redirected OAuth access code to backend to fetch the google fit data
     */
    const response = yield sendOAuthAccessCode(
      store.getState().auth.data.accessToken,
      store.getState().profile.currentProfile,
      action.payload.accessCode ? action.payload.accessCode : false
    );
    if (response) {
      if (
        response?.status === 500 &&
        response?.error?.message === "Session Expired"
      ) {
        setOAuthAccessToken("expired");
        return;
      }
      if (action.payload.accessCode) {
        window.history.replaceState({}, document.title, "/");
      }
      setOAuthAccessToken(null);
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setOAuthAccessTokenWorker(action) {
  try {
    yield put({
      type: "SET_OAUTH_TOKEN",
      payload: {
        accessToken: action.payload.accessToken
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* authWatcher() {
  yield all([
    takeEvery("SEND_OTP", sendOtpWorker),
    takeEvery("PUT_AUTH_INFO", putAuthInfoWorker),
    takeEvery("VERIFY_OTP", otpVerifyWorker),
    takeEvery("LOGIN", loginWorker),
    takeEvery("SIGNUP", signUpWorker),
    takeEvery("LOGOUT", logoutWorker),
    takeEvery("CLEAR_AUTH_CREDENTIALS", clearAuthCredentialsWorker),
    takeEvery("REFRESH_ACCESS_TOKEN", accessTokenRefreshWorker),
    takeEvery("SEND_OAUTH_ACCESS_CODE", sendOAuthAccessCodeWorker),
    takeEvery("SET_OAUTH_ACCESS_TOKEN", setOAuthAccessTokenWorker)
  ]);
}

function* setAuthLoading(loadingState) {
  yield put({
    type: "SET_AUTH_LOADING",
    payload: {
      loading: loadingState
    }
  });
}
