import { Validator } from "jsonschema";
// const Validator = require("jsonschema");

Validator.prototype.customFormats.userId = function (input) {
  return /^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/.test(input);
};

Validator.prototype.customFormats.name = function (input) {
  const name = input.split(" ");
  for (const i of name) {
    if (i) {
      if (!/^[a-zA-Z]+$/.test(i)) return false;
    }
  }
  return input.length > 2;
};

Validator.prototype.customFormats.documentId = function (input) {
  return /^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/.test(input);
};

Validator.prototype.customFormats.bucketUrl = function (input) {
  return input.includes("gs://") || input === "";
};

Validator.prototype.customFormats.gender = function (input) {
  return input === "male" || input === "female" || input === "other";
};

Validator.prototype.customFormats.registrationYear = function (input) {
  return input > 1950;
};

Validator.prototype.customFormats.bloodGroup = function (input) {
  return (
    input === "A +ve" ||
    input === "A -ve" ||
    input === "B +ve" ||
    input === "B -ve" ||
    input === "O +ve" ||
    input === "O +ve" ||
    input === "O -ve" ||
    input === "AB +ve" ||
    input === "AB -ve"
  );
};

Validator.prototype.customFormats.timestamp = function (input) {
  return new Date("01 Jan 1920") < new Date(input) < new Date();
};

Validator.prototype.customFormats.documentType = function (input) {
  return input === "prescription" || input === "report" || input === "symptom";
};

Validator.prototype.customFormats.userType = function (input) {
  return input === "doctor" || input === "patient" || input === "clinic";
};

Validator.prototype.customFormats.fitnessType = function (input) {
  return input === "googleFit";
};

let v = new Validator();

Validator.prototype.customFormats.timestamp = function (input) {
  return new Date("01 Jan 1920") < new Date(input) < new Date();
};

Validator.prototype.customFormats.userId = function (input) {
  return /^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/.test(input);
};

const vitalsModel = function (profile, method) {
  let vitalsSchema = {
    id: "/patientProfile",
    type: "object",
    properties: {
      height: { type: "number" },
      weight: { type: "number", minimum: 3 },
      to: {
        type: "object",
        properties: {
          fullName: { type: "string" },
          id: { type: "string", format: "documentId" },
          phoneNumber: { type: "string", format: "phone-number" }
        }
      },
      from: {
        type: "object",
        properties: {
          id: { type: "string", format: "documentId" },
          name: { type: "string", format: "documentId" },
          type: { type: "string", format: "userType" }
        }
      },
      timestamp: { type: "number", format: "timestamp" },
      heartRate: { type: "number" },
      bodyTemperature: { type: "number" },
      bloodOxygen: { type: "number" },
      bloodSugar: { type: "number" },
      bloodPressure: { type: "string" }
    },
    ...(method === "create" ? { required: ["timestamp", "to", "from"] } : {})
  };
  function preValidateProperty(object, key, schema, options, ctx) {
    let required = [];
    if (method === "create") {
      required = ["timestamp", "to", "from", "phoneNumber"];
    }
    let value = object[key];
    if (typeof value === "undefined" && required.includes(key)) {
      throw new Error(`Undefined value at : ${key}`);
    }
    if (key === "fullName") {
      let name = value.split(" ");
      let result = "";
      for (const i of name) {
        if (i) result += " " + i;
      }
      object[key] = result.trim();
    }
  }
  function rewriteQuery(data) {
    const result = {
      processing: true,
      ...(data.heartRate
        ? {
            heartRate: {
              value: data.heartRate,
              status: ""
            }
          }
        : {}),
      ...(data.bodyTemperature
        ? {
            bodyTemperature: {
              value: data.bodyTemperature,
              status: ""
            }
          }
        : {}),
      ...(data.bloodOxygen
        ? {
            bloodOxygen: {
              value: data.bloodOxygen,
              status: ""
            }
          }
        : {}),
      ...(data.bloodSugar
        ? {
            bloodSugar: {
              value: data.bloodSugar,
              status: ""
            }
          }
        : {}),
      ...(data.bloodPressure
        ? {
            bloodPressure: {
              value: data.bloodPressure,
              status: ""
            }
          }
        : {}),
      type: "vitals",
      ...(data.timestamp ? { timestamp: data.timestamp } : {}),
      ...(data.to ? { to: data.to } : {}),
      ...(data.from ? { from: data.from } : {}),
      ...(data.weight ? { weight: data.weight } : {}),
      ...(data.height ? { height: data.height } : {})
    };
    return result;
  }

  v.validate(profile, vitalsSchema, {
    throwAll: true,
    preValidateProperty: preValidateProperty
  });
  return rewriteQuery(profile);
};

const documentModel = function (profile, method) {
  let documentCollectionSchema = {
    id: "/document",
    type: "object",
    properties: {
      from: {
        type: "object",
        properties: {
          id: { type: "string", format: "documentId" },
          name: { type: "string" },
          type: { type: "string", format: "userType" }
        }
      },
      processing: { type: "boolean" },
      type: { type: "string", format: "documentType" },
      url: { type: "string", format: "bucketUrl" },
      notes: {
        type: "object",
        properties: {
          "^[a-zA-Z0-9_.-]*$": {
            type: "object",
            properties: {
              pages: { type: "array", items: { type: "integer" } },
              authorName: { type: "string", format: "name" },
              isDraft: { type: "boolean" }
            }
          }
        }
      },
      timestamp: { type: "integer", format: "timestamp" },
      to: {
        type: "object",
        properties: {
          fullName: { type: "string", format: "name" },
          id: { type: "string", format: "documentId" },
          phoneNumber: { type: "string", format: "phone-number" }
        }
      }
    },
    ...(method === "create"
      ? {
          required: ["from", "processing", "type", "url", "timestamp", "to"]
        }
      : {})
  };
  function preValidateProperty(object, key, schema, options, ctx) {
    let required = [];
    if (method === "create") {
      required = ["from", "processing", "type", "url", "timestamp", "to"];
    }
    let value = object[key];
    if (typeof value === "undefined" && required.includes(key)) {
      throw new Error(`Undefined value at : ${key}`);
    }
  }
  function rewriteDocuments(data) {
    const result = {
      ...(data.from ? { from: data.from } : {}),
      ...(data.processing ? { processing: data.processing } : {}),
      ...(data.type ? { type: data.type } : {}),
      ...(data.url ? { url: data.url } : {}),
      ...(data.notes ? { notes: data.notes } : {}),
      ...(data.timestamp ? { timestamp: data.timestamp } : {}),
      ...(data.to ? { to: data.to } : {})
    };
    return result;
  }
  v.validate(profile, documentCollectionSchema, {
    throwAll: true,
    preValidateProperty: preValidateProperty
  });
  return rewriteDocuments(profile);
};

const fitnessModel = function (profile, method) {
  let fitnessSchema = {
    id: "/patientProfile",
    type: "object",
    properties: {
      to: {
        type: "object",
        properties: {
          fullName: { type: "string" },
          id: { type: "string", format: "documentId" },
          phoneNumber: { type: "string", format: "phone-number" }
        }
      },
      from: {
        type: "object",
        properties: {
          id: { type: "string", format: "documentId" },
          name: { type: "string", format: "documentId" },
          type: { type: "string", format: "fitnessType" }
        }
      },
      timestamp: { type: "number", format: "timestamp" },
      stepCount: { type: "number" },
      heartPoints: { type: "number" },
      caloriesBurned: { type: "number" },
      moveMinutes: { type: "number" }
    },
    ...(method === "create" ? { required: ["timestamp", "to", "from"] } : {})
  };

  function preValidateProperty(object, key, schema, options, ctx) {
    let required = [];
    if (method === "create") {
      required = ["timestamp", "to", "from"];
    }
    let value = object[key];
    if (typeof value === "undefined" && required.includes(key)) {
      throw new Error(`Undefined value at : ${key}`);
    }
    if (key === "fullName") {
      let name = value.split(" ");
      let result = "";
      for (const i of name) {
        if (i) result += " " + i;
      }
      object[key] = result.trim();
    }
  }

  function rewriteQuery(data) {
    const result = {
      type: "vitals",
      ...(data.timestamp ? { timestamp: data.timestamp } : {}),
      ...(data.to ? { to: data.to } : {}),
      ...(data.from ? { from: data.from } : {}),
      ...(data.type ? { type: data.type } : {}),
      ...(data.stepCount ? { stepCount: data.stepCount } : {}),
      ...(data.heartPoints ? { heartPoints: data.heartPoints } : {}),
      ...(data.caloriesBurned ? { caloriesBurned: data.caloriesBurned } : {}),
      ...(data.moveMinutes ? { moveMinutes: data.moveMinutes } : {})
    };
    return result;
  }

  v.validate(profile, fitnessSchema, {
    throwAll: true,
    preValidateProperty: preValidateProperty
  });
  return rewriteQuery(profile);
};
const symptomsModel = function (profile, method) {
  let symptomsCollectionSchema = {
    id: "/symptom",
    type: "object",
    properties: {
      pid: { type: "string", format: "documentId" },
      fullName: { type: "string" },
      description: { type: "string" },
      url: {
        type: "object",
        properties: {
          audio: { type: "string", format: "bucketUrl" },
          images: {
            type: "array",
            items: {
              type: "string",
              format: "bucketUrl"
            }
          }
        }
      },
      severityScale: { type: "number" },
      type: { type: "string", format: "documentType" },
      location: {
        type: "object",
        properties: {
          front: {
            type: "array",
            items: {
              type: "object",
              properties: {
                posx: { type: "number" },
                posX: { type: "number" }
              }
            }
          },
          back: {
            type: "array",
            items: {
              type: "object",
              properties: {
                posx: { type: "number" },
                posY: { type: "number" }
              }
            }
          }
        }
      },
      phoneNumber: { type: "string", format: "phone-number" },
      timestamp: { type: "integer", format: "timestamp" }
    },
    ...(method === "create"
      ? {
          required: ["pid", "severityScale", "timestamp", "type", "fullName"]
        }
      : {})
  };
  function preValidateProperty(object, key, schema, options, ctx) {
    let required = [];
    if (method === "create") {
      required = ["pid", "severityScale", "timestamp", "type", "fullName"];
    }
    let value = object[key];
    if (typeof value === "undefined" && required.includes(key)) {
      throw new Error(`Undefined value at : ${key}`);
    }
  }
  function rewriteSymptoms(data) {
    const result = {
      ...(data.pid
        ? {
            to: {
              id: data.pid,
              phoneNumber: data.phoneNumber,
              fullName: data.fullName
            }
          }
        : {}),
      ...(data.pid
        ? { from: { id: data.pid, name: data.fullName, type: "patient" } }
        : {}),
      ...(data.description
        ? { description: data.description }
        : { description: "" }),
      ...(data.url ? { url: data.url } : { url: {} }),
      ...(data.severityScale ? { severityScale: data.severityScale } : {}),
      ...(data.location ? { location: data.location } : {}),
      ...(data.timestamp ? { timestamp: data.timestamp } : {}),
      ...(data.type ? { type: data.type } : {}),
      ...(method === "create" ? { processing: true } : { processing: false })
    };
    return result;
  }
  v.validate(profile, symptomsCollectionSchema, {
    throwAll: true,
    preValidateProperty: preValidateProperty
  });
  return rewriteSymptoms(profile);
};

export function documentSchema(data, method, documentType) {
  switch (documentType) {
    case "report":
      return documentModel(data, method);
    case "prescription":
      return documentModel(data, method);
    case "symptom":
      return symptomsModel(data, method);
    case "vitals":
      return vitalsModel(data, method);
    case "fitness":
      return fitnessModel(data, method);
    default:
      throw new Error("documentType misMatch");
  }
}

export function healthIdSchema(data) {
  try {
    const result = {
      phrAddress: data.healthId,
      transactionId: data.transactionId,
      password: data.password,
      patientProfileDetails: {
        name: {
          first: data.firstName,
          middle: data.middleName,
          last: data.lastName
          // ...(data.middleName ? { middle: data.middleName } : {}),
          // ...(data.lastName ? { last: data.lastName } : {})
        },
        dateOfBirth: {
          date: new Date(data.dateOfBirth).getDate().toString(),
          month: (new Date(data.dateOfBirth).getMonth() + 1).toString(),
          year: new Date(data.dateOfBirth).getFullYear().toString()
        },
        gender: data.gender.slice(0, 1).toUpperCase(),
        stateCode: data.stateCode,
        ...(data.email ? { email: data.email } : {}),
        mobile: data.phoneNumber.slice(3, data.phoneNumber.length),
        pinCode: data.pinCode,
        countryCode: "+91",
        districtCode: data.districtCode,
        address: data.address
      }
    };
    return result;
  } catch (error) {
    return error;
  }
}
// {
//   "phrAddress": "chandraEr@sbx",
//   "transactionId": "de70cee8-ac29-4bf5-9913-5ed9b7e40213",
//   "patientProfileDetails" : {
//     "name": {
//       "first": "Shubhanshu",
//       "middle": "Kumar",
//       "last": "Shukla"
//     },
//     "dateOfBirth": {
//       "date": 14,
//       "month": 1,
//       "year": 1996
//     },
//     "gender": "M",
//     "stateCode": "35",
//     "email": "abc@gmail.com",
//     "mobile": "7299610168",
//     "pinCode": "110001",
//     "districtCode": "603",
//     "address": "ghfghfh"
//   }
// }
// ...(data.s ? { from: data.from } : {}),
// ...(data.processing ? { processing: data.processing } : {}),
// ...(data.type ? { type: data.type } : {}),
// ...(data.url ? { url: data.url } : {}),
// ...(data.notes ? { notes: data.notes } : {}),
// ...(data.timestamp ? { timestamp: data.timestamp } : {}),
// ...(data.to ? { to: data.to } : {})
// const value = (value) => {
//   return {
//     ...(value ? { value } : {})
//   };
// };
