import React, { useEffect, useState } from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import PinInputBox from "../PinInputBox/PinInputBox";

function ConsentPinManagement({
  method,
  loading,
  onSubmit,
  onClickSendOtpToResetPin,
  error
}) {
  const [pin, setPin] = useState({
    current: "",
    new: "",
    confirm: "",
    reset: false,
    otp: ""
  });
  const [errorMessage, setErrorMessage] = useState("");
  function buttonDisableHandler() {
    switch (method) {
      case "create":
        if (
          pin.new?.length === 4 &&
          pin.confirm?.length === 4 &&
          pin.new === pin.confirm
        ) {
          return false;
        } else {
          return true;
        }

      default:
        if (pin.reset === true) {
          if (
            pin.otp?.length === 6 &&
            pin.new?.length === 4 &&
            pin.confirm?.length === 4 &&
            pin.new === pin.confirm
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          if (
            pin.current?.length === 4 &&
            pin.new?.length === 4 &&
            pin.confirm?.length === 4 &&
            pin.new === pin.confirm
          ) {
            return false;
          } else {
            return true;
          }
        }
    }
  }
  useEffect(() => {
    if (error?.includes("attempts exceeded")) {
      setErrorMessage(
        "Invalid Pin attempts exceeded; Try again after sometime."
      );
    } else if (!!error && error?.includes("Invalid Pin")) {
      setErrorMessage(error);
    }
  }, [error]);

  return (
      <form className=" inherit-parent-height flex-justify-content-space-between flex-direction-column max-width-588px margin-horizontal-auto "
        onSubmit={(event) => {
          event.preventDefault();
          if (method === "create") {
            return onSubmit({ newPin: pin.new });
          } else {
            if (pin.reset) {
              return onSubmit({ newPin: pin.new, otp: pin.otp });
            } else {
              return onSubmit({ newPin: pin.new, currentPin: pin.current });
            }
          }
        }}
      >
        <div className=" padding-top-large ">
          {method === "create" ? (
            <div className=" font-size-medium font-color-secondary font-family-gilroy-regular text-align-center line-height-140-percentage  padding-bottom-large ">
              Consent PIN is required to review & approve any request from your
              doctors, clinics & other service providers.
            </div>
          ) : (
            <>
              <PinInputBox
                type={pin.reset ? "number" : "password"}
                setValue={(value) => {
                  if (pin.reset === true) {
                    setPin({ ...pin, otp: value });
                  } else {
                    setPin({ ...pin, current: value });
                  }
                }}
                digits={pin.reset ? 6 : 4}
                label={pin.reset ? "PLEASE ENTER OTP" : "CURRENT PIN"}
              />
              <p className="font-size-smaller font-color-red margin-top-large ">
                {errorMessage}
              </p>
              <div
                className=" font-family-gilroy-regular font-size-small text-align-center margin-top-large  margin-bottom-larger "
                onClick={() => {
                  if (loading) return;
                  if (pin.reset === false) {
                    setPin({ ...pin, reset: true });
                  }
                  onClickSendOtpToResetPin();
                }}
              >
                {pin.reset === false && "Forgot your consent PIN?"}&nbsp;
                <span className=" font-color-primary font-family-gilroy-medium">
                  {pin.reset ? "Resend OTP" : "Reset now"}
                </span>
              </div>
            </>
          )}
          <PinInputBox
            digits={4}
            error={
              pin.new.length === 4 &&
              pin.confirm.length === 4 &&
              pin.new !== pin.confirm
                ? true
                : false
            }
            label="CREATE NEW PIN"
            labelClassName={
              method === "create"
                ? " padding-top-large "
                : " padding-top-larger margin-top-larger "
            }
            setValue={(pinValue) => {
              setPin({ ...pin, new: pinValue });
            }}
          />
          <PinInputBox
            digits={4}
            error={
              pin.new.length === 4 &&
              pin.confirm.length === 4 &&
              pin.new !== pin.confirm
                ? true
                : false
            }
            label="CONFIRM PIN"
            labelClassName=" padding-top-large"
            setValue={(pinValue) => {
              setPin({ ...pin, confirm: pinValue });
            }}
          />
          <p className="font-size-small padding-top-small font-color-red">
            {pin.new?.length === 4 &&
            pin.confirm?.length === 4 &&
            pin.new !== pin.confirm
              ? "Consent pins does not match"
              : ` `}
          </p>
        </div>
        <div>
          <div className=" font-size-small font-family-gilroy-regular font-color-secondary text-align-center line-height-140-percentage">
            Do not share the consent PIN with anyone except you emergency
            contact. Always review the request and enter the consent PIN to
            approve.
          </div>
          <Button
            loading={loading}
            // onClick={() => {
            //   if (method === "create") {
            //     return onSubmit({ newPin: pin.new });
            //   } else {
            //     if (pin.reset) {
            //       return onSubmit({ newPin: pin.new, otp: pin.otp });
            //     } else {
            //       return onSubmit({ newPin: pin.new, currentPin: pin.current });
            //     }
            //   }
            // }}
            type="submit"
            disabled={buttonDisableHandler()}
            text={method === "create" ? "Create" : "Continue"}
            boxShadow={false}
            className=" font-family-gilroy-medium margin-top-larger margin-bottom-larger"
          />
        </div>
      </form>
  );
}
export default ConsentPinManagement;
