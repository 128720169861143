import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "../../front-end-global-components/components/Button/Button";
import Header from "../../front-end-global-components/components/Header/Header";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import { createQuery } from "../../redux/query/actions";

const Query = (props) => {
  const [queryForm, setQueryForm] = useState({
    topic: "",
    description: ""
  });

  const queryFormOnChangeHandler = (event) => {
    setQueryForm({ ...queryForm, [event.target.name]: event.target.value });
  };

  const querySubmitHandler = (event) => {
    event.preventDefault();
    let data = {
      topic: queryForm.topic,
      description: queryForm.description
    };
    props.createQuery(data, props.navigate);
  };
  return (
    <div className="inherit-parent-height">
      <Header
        title="Query"
        hideSupport={true}
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
      />
      <form
        className="padding-top-large padding-left-larger padding-right-larger max-width-630px margin-horizontal-auto remaining-body-height overflow-y-auto"
        onChange={(event) => {
          queryFormOnChangeHandler(event);
        }}
        onSubmit={(event) => event.preventDefault()}
      >
        <div className="font-family-gilroy-bold font-size-medium font-color-secondary text-align-center">
          Please let us know your issue
        </div>
        <div className=" font-family-gilroy-medium text-align-center font-size-medium font-color-secondary padding-large">
          Describe the issue you’ve encountered.
        </div>
        <InputBox
          label="What action did you try to perform?"
          name="topic"
          type="text"
          removeResponsive={true}
          data-cy="query-topic"
          className=""
          value={queryForm.topic}
          autoFocus={false}
        />
        <TextArea
          type="text"
          value={queryForm.description}
          name="description"
          label="Provide additional information"
          data-cy="query-description"
          category="textarea"
          className="margin-top-large"
        />
        <div className="display-flex margin-vertical-larger">
          <Button
            data-cy="query-submit-button"
            boxShadow={false}
            disabled={
              queryForm.topic === "" || queryForm.description === ""
                ? true
                : false
            }
            className="margin-right-small"
            text="Submit"
            onClick={(event) => querySubmitHandler(event)}
          />
          <Button
            data-cy="query-cancel-button"
            boxShadow={false}
            className="margin-left-small"
            variant="secondary"
            text="Cancel"
            onClick={() => {
              props.navigate(-1);
            }}
          />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    documents: state.documents,
    profile: state.profile
  };
};

const mapDispatchToProps = function () {
  return {
    createQuery: (data, navigate) => createQuery(data, navigate)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Query);
