import React from "react";
import {
  isValidArray,
  isValidObject,
  isValidProfileId
} from "../../utils/validators";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { validValue } from "../../front-end-global-components/utils/Functions";
import addIcon from "../../assets/icons/addbutton.svg";

export default function ProfileSwitch(props) {
  function returnNotificationCounts(profileId, notifications) {
    /**
     * profileId => props.patients.currentProfile
     * notification => props.props.notifications.data */
    if (
      typeof profileId === "string" &&
      props.notifications !== null &&
      props.notifications[profileId] &&
      isValidArray(Object.values(props.notifications[profileId]))
    ) {
      const count = Object.values(props.notifications[profileId]).filter(
        (notification) => notification.read === false
      ).length;
      return count;
    } else {
      return 0;
    }
  }
  return (
    <section
      className="margin-left-medium margin-right-medium inherit-parent-width overflow-x-auto hide-scrollbar padding-top-default padding-bottom-default"
      data-cy={
        props.profiles && isValidObject(props.profiles)
          ? `props.profiles-${Object.keys(props.profiles)?.length}`
          : "no-props.profiles"
      }
    >
      <ul className="display-flex flex-align-items-center list-style-none flex-justify-content-center margin-top-default margin-bottom-default overflow-x-auto props.profiles-section-height">
        {props.profiles &&
          isValidObject(props.profiles) &&
          Object.keys(props.profiles).map((profileId, index) => (
            <li
              key={profileId}
              id={profileId}
              className={` flex-place-children-page-center inherit-parent-height ${
                props.primaryProfileId === profileId
                  ? "settings-primary-profile-horizontal-margin"
                  : "settings-secondary-profile-horizontal-margin"
              }`}
            >
              <RoundedProfilePicture
                data-cy={`profilePic-${props.profiles[profileId].name}`}
                background={validValue(
                  () => props.backgroundColorForProfileFallBack[profileId]
                )}
                newProfilePictureName={
                  props.primaryProfileId &&
                  isValidProfileId(props.primaryProfileId)
                    ? props.primaryProfileId
                    : ""
                }
                onProfilePictureSave={(file) => {
                  if (typeof props.profilePictureUploader === "function") {
                    props.profilePictureUploader(file);
                  }
                }}
                displayName={props.profiles?.[profileId]?.public?.fullName}
                showNotificationCount={
                  props.primaryProfileId === profileId ? false : true
                }
                notificationCount={
                  props.primaryProfileId === profileId
                    ? 0
                    : returnNotificationCounts(profileId, props.notifications)
                }
                onClick={() => {
                  if (typeof props.profileOnClick === "function") {
                    props.profileOnClick(profileId);
                  } else {
                    console.warn("props.profileOnClick is not a function");
                  }
                }}
                size={
                  props.primaryProfileId === profileId &&
                  props.addProfile === false
                    ? "medium"
                    : "small"
                }
                src={
                  props.profileData?.[profileId]
                    ? props.profileData?.[profileId]?.profilePhoto
                      ? `data:image/png;base64, ${props.profileData?.[profileId]?.profilePhoto}`
                      : null
                    : null
                }
                alt=""
                loading={props.profilePictureLoading}
              />
            </li>
          ))}
        <div
          className={` ${
            props.addProfile === true
              ? "settings-primary-profile-horizontal-margin"
              : "settings-secondary-profile-horizontal-margin"
          }`}
          onClick={() => {
            if (
              typeof props.addProfileOnClick === "function" &&
              props.profilePictureLoading === false
            ) {
              if (Object.values(props.profiles).length >= 4) {
                props.addProfileOnClick("addProfile");
              } else {
                props.navigate("/abdm/authentication?force=true");
              }
            } else {
              console.warn("addProfileOnClick is not a function");
            }
          }}
          data-cy="add-profile-button"
        >
          <img
            src={addIcon}
            className={`border-radius-50-percentage ${
              props.addProfile === true
                ? "settings-add-profile-active-icon-height"
                : "settings-add-profile-icon-height"
            }`}
            width="auto"
            alt=""
          />
        </div>
      </ul>
    </section>
  );
}
