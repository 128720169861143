import React, { useState } from "react";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { validation } from "../../utils/validators";
import Header from "../../front-end-global-components/components/Header/Header";
import Button from "../../front-end-global-components/components/Button/Button";
import { connect } from "react-redux";
import { abdmResetAbhaPassword } from "../../redux/patients/actions";

function ResetPassword(props) {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: ""
  });
  return (
    <>
      <Header
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        title={"Reset Password"}
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
      />
      <section className="remaining-body-height margin-horizontal-auto padding-right-larger padding-left-larger overflow-y-auto inherit-parent-width ">
        <form
          className=" flex-direction-column max-width-588px inherit-parent-height flex-justify-content-space-between"
          onChange={(event) => {
            setFormData({
              ...formData,
              [event.target.name]: event.target.value
            });
          }}
          onSubmit={(event) => {
            event.preventDefault();
            if (formData?.password) {
              props.abdmResetAbhaPassword(formData?.password, props.navigate);
            }
          }}
        >
          <div>
            <div className="text-align-center padding-top-larger font-color-secondary">
              RESET ABHA ADDRESSES PASSWORD
            </div>

            <InputBox
              className="inherit-parent-width border-radius-default margin-top-larger margin-bottom-default"
              name="password"
              label="Password *"
              type="password"
              value={formData.password}
              validation={(value) => validation("password", value)}
              required
            />
            <InputBox
              className="inherit-parent-width border-radius-default margin-bottom-default"
              name="confirmPassword"
              label="Confirm Password *"
              type="password"
              validation={(value) => {
                if (!(value === formData.password)) {
                  return {
                    status: false,
                    message: "Password does not match"
                  };
                }
              }}
              value={formData.confirmPassword}
              required
            />
          </div>
          <div>
            <Button
              text="Save"
              data-cy="Save-button"
              boxShadow={false}
              className="margin-top-large margin-bottom-larger"
              type="submit"
              disabled={
                formData.password &&
                formData.confirmPassword &&
                validation("password", formData.password).status &&
                formData.password === formData.confirmPassword
                  ? false
                  : true
              }
              loading={props.patients.loading}
            />
          </div>
        </form>
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  patients: state.patients
});

const mapDispatchToProps = function () {
  return {
    abdmResetAbhaPassword: (password, navigate) => abdmResetAbhaPassword(password, navigate)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
