import { getApp, getApps, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  initializeFirestore
} from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";
import { getMessaging } from "firebase/messaging";

//Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

let app;

if (getApps().length === 0) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}

export const auth = getAuth(app);

export const db = initializeFirestore(app, {
  experimentalAutoDetectLongPolling: true
});

export const analytics = getAnalytics(app);

export const remoteConfig = getRemoteConfig(app);

export const messaging = getMessaging(app);

export const switchBucket = (bucketName) => {
  if (process.env.REACT_APP_STAGING === "local") {
    connectStorageEmulator(getStorage(app, bucketName), "localhost", 9199);
  }
  return getStorage(app, bucketName);
};

if (process.env.REACT_APP_STAGING === "local") {
  connectAuthEmulator(auth, "http://localhost:9099", {
    disableWarnings: true
  });
  connectFirestoreEmulator(db, "localhost", 8080);
} else {
  if (!window.Cypress) {
    try {
      enableIndexedDbPersistence(db, { forceOwnership: true });
    } catch (error) {
      if (error.code === "failed-precondition") {
        console.error(error.code);
      } else if (error.code === "unimplemented") {
        console.error(error.code);
      } else {
        console.error(error);
      }
    }
  }
}
