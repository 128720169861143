import { useEffect, useState } from "react";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { validation } from "../../utils/validators";
import Button from "../../front-end-global-components/components/Button/Button";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";

export default function CreateAbhaNumber(props) {
  const [aadharData, setAadharData] = useState({
    aadhaarNumber: "",
    checkbox: false
  });
  const [OTP, setOTP] = useState("");
  const [counter, setCounter] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+91");

  useEffect(() => {
    if (props.getOtp) {
      let counterTimer;
      if (counter && typeof counter === "number") {
        counterTimer = setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
      } else {
        clearInterval(counterTimer);
      }
    } else {
      setCounter(null);
    }

    if (counter === 0) {
      setCounter(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useEffect(() => {
    if (props.getOtp) {
      setCounter(60);
    }
  }, [props.getOtp]);

  const profilePhoto = () => {
    if (props.abhaNumberDetails.profilePhoto) {
      return `data:image/png;base64, ${props.abhaNumberDetails.profilePhoto}`;
    } else {
      return null;
    }
  };

  return (
    <Modal
      show={props.showModal}
      onClose={() => {
        props.closeModal();
      }}
      canIgnore={true}
      position="position-fixed bottom-0 left-0 right-0"
      boxShadow="none"
      width="inherit-parent-width"
      background="background-transparent"
      height="height-fit-to-content"
    >
      <div className="background-white padding-larger box-shadow-default font-color-secondary">
        {!props.getOtp && !props.getMobileNumber && !props.abhaNumberDetails && (
          <>
            <div className=" text-align-center font-family-gilroy-regular font-size-small">
              {" "}
              CREATE ABHA NUMBER{" "}
            </div>
            <div className=" text-align-center font-family-gilroy-regular font-size-small padding-top-large ">
              An OTP will be send to your mobile number which is registered with
              your Aadhar number
            </div>

            <form
              onChange={(event) => {
                if (event.target.name === "aadhaarNumber") {
                  setAadharData({
                    ...aadharData,
                    [event.target.name]: event.target.value
                  });
                }
                if (event.target.name === "checkbox") {
                  setAadharData({
                    ...aadharData,
                    [event.target.name]: event.target.checked
                  });
                }
              }}
              onSubmit={(event) => {
                event.preventDefault();
                if (aadharData.checkbox) {
                  props.registerNewAbhaNumber({
                    aadhaarNumber: aadharData.aadhaarNumber,
                    type: "sendAadharOtp"
                  });
                }
                // props.searchHealthIdAuthMode(abhaNumber);
              }}
            >
              <InputBox
                className="inherit-parent-width border-radius-default margin-vertical-large"
                name="aadhaarNumber"
                label="Aadhar Number"
                value={aadharData.aadhaarNumber}
                type="number"
                autoComplete="off"
                required
                data-cy="aadhar-number-field"
                validation={(value) => validation("aadhaarNumber", value)}
              />
              <div className=" display-flex flex-align-items-center font-size-small padding-bottom-medium">
                <CheckBox
                  data-cy="terms-and-conditions-check-box"
                  id="userAgreement"
                  checked={aadharData.checkbox}
                />

                <div>
                  I Agree to{" "}
                  <spam className=" font-color-primary">
                    {" "}
                    Terms and conditions{" "}
                  </spam>
                </div>
              </div>

              <Button
                text="Continue"
                variant={"primary"}
                boxShadow={false}
                type="submit"
                disabled={
                  !aadharData.checkbox || aadharData.aadhaarNumber.length !== 12
                    ? true
                    : false
                }
                className={"margin-top-default"}
                data-cy={"continue-button"}
                loading={props.loading}
              />
            </form>
          </>
        )}

        {props.getOtp && !props.getMobileNumber && !props.abhaNumberDetails && (
          <>
            <form
              onSubmit={(event) => {
                event.preventDefault();

                if (props.getMobileOtp) {
                  props.registerNewAbhaNumber({
                    otp: OTP,
                    type: "verifyMobileOtp"
                  });
                } else {
                  props.registerNewAbhaNumber({
                    otp: OTP,
                    type: "verifyAadharOtp"
                  });
                }
                if (counter !== null) {
                  setCounter(null);
                }
              }}
            >
              <OTPInputBox
                name="otp"
                autoFocus={true}
                value={OTP}
                setValue={(otpValue) => {
                  setOTP(otpValue);
                }}
                required
                className="margin-bottom-large"
              />

              <div className="display-flex flex-justify-content-space-between font-size-medium margin-bottom-larger">
                <div>
                  {counter &&
                    typeof counter === "number" &&
                    `${
                      counter?.toString().length === 1 ? "0" : ""
                    }${counter?.toString()} seconds`}
                </div>
                <div
                  className={`${
                    counter ? "font-color-tertiary" : "font-color-primary"
                  }`}
                  onClick={() => {
                    setCounter(60)
                    props.registerNewAbhaNumber({
                      aadhaarNumber: aadharData.aadhaarNumber,
                      type: "sendAadharOtp"
                    });
                    // props.generateAbhaAddressOtp(abhaNumber, selectedAuthMode);
                  }}
                >
                  Resend OTP
                </div>
              </div>

              <Button
                text="Continue"
                variant={"primary"}
                boxShadow={false}
                required
                type="submit"
                disabled={OTP.length !== 6 ? true : false}
                className={"margin-top-default margin-left-default"}
                data-cy={"Continue-button"}
                loading={props.loading}
              />
            </form>
          </>
        )}

        {props.getMobileNumber && props.getOtp && !props.abhaNumberDetails && (
          <>
            <div className=" text-align-center font-family-gilroy-regular font-size-small">
              ENTER MOBILE NUMBER
            </div>
            <div className=" text-align-center font-family-gilroy-regular font-size-small padding-top-large ">
              Connect your mobile number with ABHA number
            </div>

            <form
              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
              onSubmit={(event) => {

                event.preventDefault();
                props.registerNewAbhaNumber({
                  mobileNumber: phoneNumber,
                  type: "sendMobileOtp"
                });
              }}
            >
              <InputBox
                className="inherit-parent-width border-radius-default margin-vertical-large"
                name="phoneNumber"
                label="Phone Number"
                value={phoneNumber}
                type="number"
                autoComplete="off"
                required
                data-cy="aadhar-number-field"
                validation={(value) => validation("phoneNumber", value)}
              />

              <Button
                text="Continue"
                variant={"primary"}
                boxShadow={false}
                required
                type="submit"
                disabled={phoneNumber.length !== 13 ? true : false}
                className={"margin-top-default"}
                data-cy={"continue-button"}
                loading={props.loading}
              />
            </form>
          </>
        )}

        {props.abhaNumberDetails && (
          <>
            <div className=" text-align-center font-family-gilroy-regular font-size-small">
              ABHA NUMBER WAS CREATED SUCCESSFULLY
            </div>

            <div className=" display-flex flex-align-items-center flex-justify-content-space-between padding-vertical-larger">
              <div>
                <div className=" font-size-small">NAME</div>
                <div className=" padding-top-small">
                  {props.abhaNumberDetails.name}
                </div>

                <div className=" font-size-small padding-top-large">
                  ABHA NUMBER
                </div>
                <div className=" padding-top-small">
                  {props.abhaNumberDetails.healthIdNumber}
                </div>
              </div>
              <RoundedProfilePicture src={profilePhoto()} size="medium" />
            </div>
            <Button
              text="Done"
              variant={"primary"}
              boxShadow={false}
              required
              type="button"
              className={"margin-top-default"}
              data-cy={"continue-button"}
              loading={props.loading}
              onClick={() => {
                props.closeModal();
              }}
            />
          </>
        )}
      </div>
    </Modal>
  );
}
