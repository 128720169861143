import React, { useEffect, useState } from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import "./Connections.css";
import { CSSTransition } from "react-transition-group";
import { consentStatus } from "../../utils/constants";
import { StatusChip } from "../Chip/Chip";
import UnderMaintenance from "../../front-end-global-components/components/UnderMaintenance/UnderMaintenance";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";
import filterGrey from "../../front-end-global-components/assets/filterGrey.svg";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import { isValidObject } from "../../utils/validators";

export default function Connections(props) {
  useEffect(() => {
    if (typeof props.fetchAbdmRequests === "function") {
      props.fetchAbdmRequests();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <CSSTransition
      in={props.show}
      timeout={2000}
      classNames="fade-in-fade-out-transition"
    >
      <>
        {!!props.isHealthIdLoggedIn ? (
          <ABDMRequests
            loading={props.loading}
            requests={props.requests}
            abdmRequestOnClick={(abdmRequestType, abdmRequestId) => {
              props.abdmRequestOnClick(abdmRequestType, abdmRequestId);
            }}
            autoApprovalPolicies={props.autoApprovalPolicies}
            setRequestsFilter={(filterBy, id) => {
              props.setRequestsFilter(filterBy, id);
            }}
            filters={props.filters}
          />
        ) : (
          <>
            {false && (
              <UnderMaintenance className="settings-screen-content-remaining-height" />
            )}
          </>
        )}
      </>
    </CSSTransition>
  );
}

const ABDMRequests = (props) => {
  const [abdmRequests, setAbdmRequests] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [clinicNames, setClinicNames] = useState([]);

  const requestTypes = [
    { id: "authorization", name: "Authorization request" },
    { id: "consent", name: "Consent request" },
    { id: "subscription", name: "Subscription request" },
    { id: "lockerSetup", name: "Locker request" }
  ];

  const statusTypes = [
    { id: consentStatus.approved, name: "Approved" },
    { id: consentStatus.autoApproved, name: "Auto-approved" },
    { id: consentStatus.denied, name: "Denied" },
    { id: consentStatus.expired, name: "Expired" },
    { id: consentStatus.pending, name: "Pending" }
  ];

  useEffect(() => {
    const combinedList = props.requests && Object.values(props.requests).flat();

    combinedList?.sort(
      (first, second) =>
        +new Date(second.lastUpdated) - +new Date(first.lastUpdated)
    );

    setAbdmRequests(combinedList);
  }, [props.requests]);

  useEffect(() => {
    let _clinicNames = {};

    abdmRequests?.forEach((data) => {
      _clinicNames = { ..._clinicNames, [data.hiu.id]: data.hiu };
    });

    if (isValidObject(_clinicNames)) {
      setClinicNames(_clinicNames);
    }
  }, [abdmRequests]);

  return (
    <>
      {false ? (
        <UnderMaintenance className="settings-screen-content-remaining-height" />
      ) : props.loading ? (
        <ABDMRequestsCardLoading />
      ) : (
        <>
          {abdmRequests?.length > 0 ? (
            <section className="settings-screen-content-remaining-height overflow-y-auto">
              <div className=" position-sticky top-0 background-white z-index-1 inherit-parent-width flex-justify-content-space-between flex-align-items-center font-size-medium font-color-secondary padding-top-large padding-bottom-default padding-right-large padding-left-large">
                <div>Connections & requests</div>
                <div
                  className=" font-family-gilroy-medium display-flex flex-align-items-center "
                  onClick={() => {
                    setShowFilterModal(!showFilterModal);
                  }}
                  data-cy="filter-icon"
                >
                  <div className=" ">Filters </div>
                  <div className="display-flex flex-align-items-start">
                    <img
                      src={filterGrey}
                      className="padding-left-default"
                      height="12px"
                      alt="filter-icon"
                    />
                  </div>
                </div>
              </div>

              {abdmRequests.map((request, index) => {
                if (
                  (props.filters.clinics.includes(request.hiu.id) ||
                    props.filters.clinics.length === 0) &&
                  (props.filters.requests.includes(request.type) ||
                    props.filters.requests.length === 0) &&
                  (props.filters.status.includes(request.status) ||
                    props.filters.status.length === 0)
                ) {
                  return (
                    <React.Fragment key={`${request.id}/${index}`}>
                      <AbdmRequestCard
                        onClick={(abdmRequestType, abdmRequestId) => {
                          props.abdmRequestOnClick(
                            abdmRequestType,
                            abdmRequestId
                          );
                        }}
                        status={request.status}
                        loading={props.loading}
                        data={request}
                      />
                    </React.Fragment>
                  );
                }

                return null;
              })}
            </section>
          ) : (
            <section className="settings-screen-content-remaining-height overflow-y-auto flex-place-children-page-center">
              <div className=" font-family-gilroy-medium font-color-tertiary font-size-large ">
                No connections found
              </div>
            </section>
          )}
        </>
      )}

      <Modal
        onClose={() => {
          setShowFilterModal(!showFilterModal);
        }}
        canIgnore={true}
        show={showFilterModal}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-color-white"
        height="height-40-percentage"
        data-cy="requests-filter-modal"
      >
        <div className="background-white border-top-radius-default padding-default font-family-gilroy-medium user-select-none inherit-parent-height ">
          <div className="flex-justify-content-space-between flex-align-items-center font-size-medium font-color-secondary padding-default ">
            <div className=" ">
              FILTER
              <img
                src={filterGrey}
                className="padding-left-default"
                height="12px"
                alt="filter-icon"
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                props.setRequestsFilter("clear");
                setShowFilterModal(!showFilterModal);
              }}
              data-cy="clearFilter"
            >
              Clear
            </div>
          </div>
          <div className="display-flex font-size-medium font-family-gilroy-medium text-align-left inherit-parent-height">
            <div className="border-right-1px ">
              <div
                className={` cursor-pointer width-max-content padding-default ${
                  props.filters.filterType.includes("clinics") &&
                  "background-color-grey"
                }`}
                onClick={() => {
                  props.setRequestsFilter("filterType", "clinics");
                }}
                data-cy="clinicFilter"
              >
                Clinic & lab
              </div>

              <div
                className={` cursor-pointer padding-default ${
                  props.filters.filterType.includes("requests") &&
                  "background-color-grey"
                }`}
                onClick={() => {
                  props.setRequestsFilter("filterType", "requests");
                }}
                data-cy="requestFilter"
              >
                Requests
              </div>

              <div
                className={` cursor-pointer padding-default ${
                  props.filters.filterType.includes("status") &&
                  "background-color-grey"
                }`}
                onClick={() => {
                  props.setRequestsFilter("filterType", "status");
                }}
                data-cy="statusFilter"
              >
                Status
              </div>
            </div>
            <div className=" font-color-secondary padding-left-default">
              {/* doctor names filter chips */}
              <div className="display-flex flex-wrap">
                {props.filters.filterType === "clinics" &&
                  isValidObject(clinicNames) &&
                  Object.values(clinicNames)?.map((clinic, index) => (
                    <div
                      data-cy={clinic.id}
                      key={index}
                      className={`padding-default border-radius-default background-white margin-default height-fit-to-content ${
                        props.filters.clinics.includes(clinic.id)
                          ? "background-color-grey"
                          : "box-shadow-secondary"
                      } `}
                      onClick={() => {
                        props.setRequestsFilter("clinics", clinic.id);
                      }}
                    >
                      {clinic.name ? clinic.name : clinic.id}
                    </div>
                  ))}
              </div>

              <div className="display-flex flex-direction-column">
                {props.filters.filterType === "requests" &&
                  requestTypes.map((request, index) => (
                    <div
                      data-cy={request.id}
                      key={index}
                      className={`padding-default border-radius-default background-white margin-default height-fit-to-content ${
                        props.filters.requests.includes(request.id)
                          ? "background-color-grey"
                          : "box-shadow-secondary"
                      } `}
                      onClick={() => {
                        props.setRequestsFilter("requests", request.id);
                      }}
                    >
                      {request.name}
                    </div>
                  ))}
              </div>

              <div className="display-flex flex-wrap">
                {props.filters.filterType === "status" &&
                  statusTypes.map((status, index) => (
                    <div
                      data-cy={status.id}
                      key={index}
                      className={`padding-default border-radius-default background-white margin-default height-fit-to-content ${
                        props.filters.status.includes(status.id)
                          ? "background-color-grey"
                          : "box-shadow-secondary"
                      } `}
                      onClick={() => {
                        props.setRequestsFilter("status", status.id);
                      }}
                    >
                      {status.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

function remainingDateConversion(date) {
  const timeStamp = +new Date(date) - +new Date();
  const remainingMonth = Math.round(timeStamp / (1000 * 60 * 60 * 24 * 30));
  const remainingDays = Math.round(timeStamp / (1000 * 60 * 60 * 24));

  if (remainingMonth < 1) {
    return `Expires in ${remainingDays} Day${remainingMonth !== 1 && "s"}`;
  } else {
    return `Expires in ${remainingMonth} Month${remainingMonth !== 1 && "s"}`;
  }
}

const AbdmRequestCard = ({ status, data, loading, onClick }) => {
  const [requester, setRequester] = useState({
    name: "",
    suffixText: "",
    expiresIn: ""
  });
  useEffect(() => {
    switch (data.type) {
      case "subscription":
        setRequester({
          name: !!data?.hiu?.name ? data.hiu.name : "Abdm",
          suffixText:
            data?.status === consentStatus.pending
              ? " wants to follow and store your health record when new record is available in your ABHA address."
              : "Subscription request"
        });
        break;

      case "lockerSetup":
        setRequester({
          name: !!data?.authorization?.requester?.name
            ? data.authorization.requester.name
            : "Abdm",
          suffixText:
            data?.status === consentStatus.pending
              ? " wants to link new records and store existing records in your health locker."
              : "Locker request",
          expiresIn:
            (data?.status === consentStatus.approved ||
              data?.status === consentStatus.autoApproved) &&
            remainingDateConversion(data?.subscription?.period?.to)
        });
        break;
      case "authorization":
        setRequester({
          name: !!data?.requester?.name ? data.requester.name : "Abdm",
          suffixText:
            data?.status === consentStatus.pending
              ? " wants to authenticate and link records to your ABHA address"
              : "Authorization request"
        });
        break;
      case "consent":
        setRequester({
          name: !!data?.requester?.name ? data.requester.name : "Abdm",
          suffixText:
            data?.status === consentStatus.pending
              ? "want to access select records in your ABHA address."
              : "Consent request",
          expiresIn:
            new Date(data?.permission?.dataEraseAt) > new Date() &&
            data?.status === consentStatus.approved &&
            remainingDateConversion(data?.permission?.dataEraseAt)
        });
        break;
      default:
        break;
    }
  }, [data, status]);
  return (
    <div
      data-cy={`${data.type}-${status}`}
      id={`${data.type}-${status}-${data.hiu.id}`}
      className=" margin-top-medium margin-bottom-medium margin-left-large margin-right-large flex-justify-content-space-between"
    >
      <div
        onClick={() => {
          if (loading === false) {
            onClick(data.type, data.id);
          }
        }}
        className={` display-flex flex-grow-1   
      }`}
      >
        <RoundedProfilePicture
          loading={loading}
          className={`${loading ? "" : "margin-top-default"}`}
          size={"medium"}
          src={""}
        />
        <div>
          <div className=" flex-grow-1  padding-left-medium   padding-top-default font-color-secondary">
            <div className="font-family-gilroy-medium">{requester.name}</div>
            <div className="font-family-gilroy-regular">
              {requester.suffixText}
              {requester.expiresIn && (
                <div className=" font-size-small padding-top-small">
                  {requester.expiresIn}
                </div>
              )}
            </div>
          </div>
          <div className="padding-left-medium  padding-top-small">
            <StatusChip status={status} />
          </div>
        </div>
      </div>
      <div className=" margin-top-default">
        <ArrowIcon />
      </div>
    </div>
  );
};

const ABDMRequestsCardLoading = () => {
  return (
    <>
      <div className=" display-flex flex-justify-content-center padding-top-medium">
        <div className="shimmer margin-top-default padding-small border-radius-default width-200px ">
          <div className="padding-top-small" />
        </div>
      </div>
      {[...Array(8)].map((index) => (
        <div
          key={index}
          className=" padding-top-default padding-left-large padding-right-large "
        >
          <div className="flex-center-children-vertically padding-top-large  ">
            <div className="shimmer padding-larger border-radius-50-percentage height-width-16px" />
            <div className=" margin-left-default">
              <div className="shimmer  padding-default border-radius-default width-200px " />
              <div className="shimmer margin-top-default padding-small border-radius-default width-150px ">
                <div className=" padding-smaller" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
