import React, { useState } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import "./Settings.css";
import notificationIcon from "../../assets/icons/notification.svg";
import profileIcon from "../../assets/icons/profile.svg";
import connectionIcon from "../../assets/icons/connection.svg";
import notificationIconActive from "../../assets/icons/notification-active.svg";
import profileIconActive from "../../assets/icons/profile-active.svg";
import connectionIconActive from "../../assets/icons/connection-active.svg";
import { isValidObject } from "../../front-end-global-components/services/validators";
import Connections from "../../components/connections/Connections";
import { isValidArray, validation } from "../../utils/validators";
import { CSSTransition } from "react-transition-group";
import Profile from "../../components/profile/Profile";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Button from "../../front-end-global-components/components/Button/Button";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import { MAX_PROFILE_COUNT } from "../../utils/constants";
import maxProfileFallBack from "../../assets/images/Max-profile-fallback.svg";
import { logout } from "../../redux/authentication/actions";
import { getProfilePictureDownloadUrl } from "../../redux/downloads/actions";
import {
  setNotificationRead,
  updateNotifications
} from "../../redux/notifications/actions";
import {
  createDemographic,
  getPatientsRequests,
  switchPatientProfile,
  setRequestsFilterData
} from "../../redux/patients/actions";
import ConnectABDM from "../../components/ConnectABDM/ConnectABDM";
import { setNotificationPermission } from "../../redux/app/actions";
import Notification from "../../components/Notification/Notification";
import ProfileSwitch from "../../components/ProfileSwitch/ProfileSwitch";
import { requestsValidation } from "../../utils/requestsValidation";

function returnNotificationCounts(profileId, notifications) {
  /**
   * profileId => props.patients.currentProfile
   * notification => props.notifications.data */
  if (
    typeof profileId === "string" &&
    notifications !== null &&
    notifications[profileId] &&
    isValidArray(Object.values(notifications[profileId]))
  ) {
    const count = Object.values(notifications[profileId]).filter(
      (notification) => notification.read === false
    ).length;
    return count;
  } else {
    return 0;
  }
}

const Settings = (props) => {
  const [activeMenu, setActiveMenu] = useState("notification");
  const [addProfile, setAddProfile] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [profileNameIsValid, setProfileNameIsValid] = useState("");

  return (
    <section data-cy="settings-screen" className="inherit-parent-height">
      <Header
        title="Settings"
        backButtonOnClick={() => {
          props.navigate("/");
        }}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
      />
      <section className="max-width-588px margin-horizontal-auto remaining-body-height overflow-hidden position-relative">
        <ProfileSwitch
          profiles={props.patients.profiles}
          // downloadURL={props.downloads.data.profilePictures.patients}
          profileData={props.patients.healthIdData}
          primaryProfileId={props.patients.currentProfile}
          addProfile={addProfile}
          profileOnClick={(profileId) => {
            props.switchPatientProfile(profileId);
            setActiveMenu("notification");
            setAddProfile(false);
          }}
          notifications={props.notifications.data}
          addProfileOnClick={() => {
            setAddProfile(true);
          }}
          profilePictureUploader={(file) => {
            props.uploadProfilePicture(file);
          }}
          // backgroundColorForProfileFallBack={}
          profilePictureLoading={false}
          navigate={props.navigate}
        />

        {addProfile === false && (
          <SettingsMenu
            loading={props.patients.loading}
            show={addProfile === false ? true : false}
            userName={
              props.patients.profiles &&
              props.patients.currentProfile &&
              props.patients.profiles[props.patients.currentProfile]?.name
            }
            healthId={
              props.patients.currentProfile &&
              props.patients.profiles?.[props.patients.currentProfile]?.healthId
                ? props.patients.profiles?.[props.patients.currentProfile]
                    .healthId
                : null
            }
            // show={props.patients.p}
            phoneNumber={
              props.patients.profiles &&
              props.patients.currentProfile &&
              props.patients.profiles[props.patients.currentProfile]
                ?.phoneNumber
            }
            notificationCount={
              props.notifications.data &&
              props.patients.currentProfile &&
              returnNotificationCounts(
                props.patients.currentProfile,
                props.notifications.data
              )
            }
            connectionRequestCount={
              props.clinics?.requests &&
              props.patients.currentProfile &&
              isValidArray(props.clinics.requests)
                ? props.clinics.requests.filter(
                    (request) =>
                      request.recipient === props.patients.currentProfile
                  ).length
                : 0
            }
            activeMenu={activeMenu}
            menuOnClick={(menuType) => {
              if (activeMenu === menuType) return;
              setActiveMenu(menuType);
            }}
          />
        )}
        {isValidObject(props.patients.profiles) &&
        Object.keys(props.patients.profiles).length >= MAX_PROFILE_COUNT ? (
          addProfile && <MaxProfileFallBack />
        ) : (
          <Modal
            show={addProfile}
            width="inherit-parent-width"
            maxWidth="max-width-630px"
            background="false"
            boxShadow="false"
            borderRadius="false"
            height="height-fit-to-content"
            modalBodyClassName="padding-horizontal-medium"
          >
            <div className="background-white padding-medium border-radius-default box-shadow-default font-color-secondary">
              <div className=" text-align-center font-family-gilroy-medium padding-top-large">
                {" "}
                Create New Profile{" "}
              </div>
              <form
                onChange={(event) => {
                  setProfileName(event.target.value);
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  props.createDemographic(profileName);
                  setAddProfile(false);
                }}
              >
                <InputBox
                  className="inherit-parent-width border-radius-default margin-vertical-large"
                  name="userName"
                  label="Profile Name"
                  // value={signUpFormData.userName}
                  type="text"
                  autoComplete="off"
                  required
                  data-cy="create-new-demographic-name-field"
                  validation={(value) => {
                    setProfileNameIsValid(validation("name", value).status);
                    return validation("name", value);
                  }}
                />
                <div className=" display-flex">
                  <Button
                    text="Create"
                    boxShadow={false}
                    required
                    type="submit"
                    className={
                      "margin-top-default margin-bottom-medium margin-right-default"
                    }
                    disabled={!profileNameIsValid || profileName.length <= 2}
                    data-cy={"create-new-demographic-button"}
                    loading={props.patients.loading}
                  />
                  <Button
                    text="Cancel"
                    variant={"secondary"}
                    boxShadow={false}
                    required
                    type="button"
                    className={
                      "margin-top-default margin-bottom-medium margin-left-default"
                    }
                    data-cy={"cancel-create-demographic-button"}
                    onClick={() => {
                      setAddProfile(false);
                    }}
                  />
                </div>
              </form>
            </div>
          </Modal>
        )}

        {activeMenu === "connections" &&
        addProfile === false &&
        props.patients.accessToken &&
        props.patients.currentProfile &&
        props.patients.accessToken[props.patients.currentProfile] ? (
          <Connections
            connectOnClick={() => {
              props.navigate("/abdm/authentication");
            }}
            loading={props.patients.loading}
            fetchAbdmRequests={() => {
              if (
                props.patients.accessToken &&
                props.patients.currentProfile &&
                props.patients.accessToken[props.patients.currentProfile]
              ) {
                props.getPatientsRequests();
              }
            }}
            requests={
              props.patients.requests &&
              props.patients.currentProfile &&
              props.patients.requests[props.patients.currentProfile] &&
              requestsValidation(
                props.patients.requests[props.patients.currentProfile],
                props.patients.autoApprovalPolicies &&
                  props.patients.autoApprovalPolicies
              )
            }
            isHealthIdLoggedIn={
              props.patients.accessToken &&
              props.patients.accessToken[props.patients.currentProfile]
            }
            abdmRequestOnClick={(abdmRequestType, abdmRequestId) => {
              props.navigate(`/abdm/${abdmRequestType}/${abdmRequestId}`);
            }}
            autoApprovalPolicies={
              props.patients.autoApprovalPolicies &&
              props.patients.autoApprovalPolicies
            }
            setRequestsFilter={(filterBy, Id) => {
              props.setRequestsFilterData(filterBy, Id);
            }}
            filters={props.patients.requestsFilter}
          />
        ) : (
          activeMenu === "connections" &&
          addProfile === false &&
          (!props.patients.accessToken ||
            !props.patients.currentProfile ||
            !props.patients.accessToken[props.patients.currentProfile]) && (
            <div className=" profile-setting-height  padding-horizontal-large flex-justify-content-center flex-align-items-center ">
              <ConnectABDM
                connectOnClick={() => {
                  props.navigate("/abdm/authentication");
                }}
              />
            </div>
          )
        )}
        {activeMenu === "profile" && addProfile === false && (
          <Profile
            changeConsentPinOnClick={() => {
              props.navigate("/abdm/changeconsentpin");
            }}
            abdmHealthIdQrOnClick={() => {
              props.navigate("/abdm/abdmHealthId");
            }}
            logoutOnClick={() => {
              props.logout();
            }}
            resetPasswordOnClick={() => {
              props.navigate("/resetPassword");
            }}
            editProfileOnClick={() => {
              props.navigate("/abdm/editProfile");
            }}
            abhaNumberOnClick={() => {
              props.navigate("/abdm/abhaNumber");
            }}
            linkedFacilitiesOnClick={() => {
              props.navigate("/abdm/linkedFacilities");
            }}
            navigate={props.navigate}
            abdmLoggedIn={
              props.patients.accessToken?.[props.patients.currentProfile]
                ? true
                : false
            }
          />
        )}
        <Notification
          paginationLoading={props.notifications?.paginationLoading}
          // paginationHandler={() => {
          //   props.notificationPagination(
          //     props.patients.currentProfile,
          //     props.notifications.paginationCount
          //   );
          // }}
          app={props.app}
          onCloseClick={() => {
            props.setNotificationPermission(false);
          }}
          loading={props.notifications.loading}
          show={
            activeMenu === "notification" && addProfile === false ? true : false
          }
          notificationCount={returnNotificationCounts(
            props.patients.currentProfile,
            props.notifications.data
          )}
          notifications={
            props.notifications.data &&
            props.patients.currentProfile &&
            isValidObject(
              props.notifications.data[props.patients.currentProfile]
            ) &&
            Object.values(
              props.notifications.data[props.patients.currentProfile]
            )
          }
          notificationCardOnClick={(notification) => {
            if (typeof notification?.url === "string") {
              props.navigate(`/${notification?.url}`);
            }
            if (notification?.read === false) {
              props.setNotificationRead(notification);
            }
          }}
          updateNotifications={() => {
            props.updateNotifications();
          }}
          pagination={
            props.patients.currentProfile &&
            props.notifications.pagination &&
            props.notifications.pagination[props.patients.currentProfile]
          }
          markAllAsReadOnClick={() => {
            props.setNotificationRead(null, "all");
          }}
        />
      </section>
    </section>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
  abdm: state.abdm,
  profile: state.profile,
  notifications: state.notifications,
  clinics: state.clinics,
  documents: state.documents,
  downloads: state.downloads,
  patients: state.patients
});

const mapDispatchToProps = function () {
  return {
    setNotificationPermission: (status) => setNotificationPermission(status),
    updateNotifications: () => updateNotifications(),
    getProfilePictureUrl: (profileType, profileId) =>
      getProfilePictureDownloadUrl(profileType, profileId),
    switchPatientProfile: (profileId) => {
      switchPatientProfile(profileId);
    },
    setNotificationRead: (notificationId, type) =>
      setNotificationRead(notificationId, type),
    logout: () => logout(),
    getPatientsRequests: () => {
      getPatientsRequests();
    },
    createDemographic: (profileName) => {
      createDemographic(profileName);
    },
    setRequestsFilterData: (filterBy, Id) => setRequestsFilterData(filterBy, Id)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

//settings menu component
const SettingsMenu = ({
  loading,
  show,
  phoneNumber,
  userName,
  healthId,
  activeMenu,
  menuOnClick,
  notificationCount,
  connectionRequestCount
}) => {
  const menu = [
    {
      type: "notification",
      icon: notificationIcon,
      activeIcon: notificationIconActive,
      showCount: true,
      countDataCy: "notification-count",
      count: notificationCount,
      "data-cy": "notification-menu"
    },
    {
      type: "profile",
      icon: profileIcon,
      activeIcon: profileIconActive,
      showCount: false,
      countDataCy: "profile-count",
      "data-cy": "profile-menu"
    },
    {
      type: "connections",
      icon: connectionIcon,
      activeIcon: connectionIconActive,
      countDataCy: "connection-count",
      showCount: true,
      count: connectionRequestCount,
      "data-cy": "connections-menu"
    }
  ];

  return (
    <CSSTransition
      in={show}
      timeout={2000}
      classNames="fade-in-fade-out-transition"
    >
      <React.Fragment>
        {show === true && (
          <>
            <section className="padding-left-medium padding-right-medium flex-justify-content-space-between flex-align-items-center justify-between">
              <div>
                <h3 className="font-size-larger font-family-gilroy-medium font-weight-normal font-color-secondary">
                  {userName}
                </h3>
                <div className="font-color-secondary font-family-gilroy-medium font-size-small padding-bottom-large padding-top-small">
                  {healthId ? (
                    healthId
                  ) : (
                    <>
                      <span className="padding-right-small ">
                        {typeof phoneNumber === "string" &&
                          phoneNumber.substring(0, 3)}
                      </span>
                      <span className="padding-right-small">
                        {typeof phoneNumber === "string" &&
                          phoneNumber.substring(3, 8)}
                      </span>
                      <span className="padding-right-small">
                        {typeof phoneNumber === "string" &&
                          phoneNumber.substring(8, 13)}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="display-flex flex-align-items-center">
                {menu.map((menu) => (
                  <div
                    key={menu.type}
                    className="display-flex flex-direction-column margin-horizontal-small"
                  >
                    <div
                      data-cy={menu["data-cy"]}
                      className={`settings-menu-icon-size border-radius-default display-flex flex-align-items-center flex-justify-content-center cursor-pointer ${
                        activeMenu === menu.type
                          ? "background-color-grey "
                          : "background-color-white"
                      } ${menu.showCount === true ? "position-relative" : ""}`}
                      onClick={() => {
                        if (typeof menuOnClick === "function") {
                          menuOnClick(menu.type);
                        }
                      }}
                    >
                      <img
                        src={
                          activeMenu === menu.type ? menu.activeIcon : menu.icon
                        }
                        alt=""
                        className="settings-menu-icon-height"
                        width="auto"
                      />
                      {menu.count > 0 && menu.showCount === true && (
                        <div
                          data-cy={menu.countDataCy}
                          className={`settings-notification-icon-count-size${
                            menu.count > 99 ? "-large" : ""
                          } settings-notification-icon-count-position box-shadow-default flex-place-children-page-center border-radius-50-percentage font-color-secondary background-white position-absolute text-align-center font-size-extra-small ${
                            menu.count > 9
                              ? "height-and-width-for-double-digit-number"
                              : menu.count > 99
                              ? "height-and-width-for-triple-digit-number"
                              : "height-and-width-for-single-digit-number"
                          }`}
                        >
                          {menu.count > 99 ? "99+" : menu.count}
                        </div>
                      )}
                    </div>
                    <span
                      className={`padding-top-larger ${
                        activeMenu === menu.type
                          ? "active-menu-border-bottom-2px-green"
                          : "menu-border-bottom-2px"
                      }`}
                    />
                  </div>
                ))}
              </div>
            </section>
            <div className="settings-menu-horizontal-ruler inherit-parent-width background-white" />
          </>
        )}
      </React.Fragment>
    </CSSTransition>
  );
};

const MaxProfileFallBack = () => {
  return (
    <React.Fragment>
      <div
        data-cy="max-profile-fallback"
        className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary text-transform-uppercase padding-top-large padding-bottom-large"
      >
        Add new profile
      </div>
      <section className=" margin-left-auto margin-right-auto flex-place-children-page-center settings-screen-content-remaining-height ">
        <img
          src={maxProfileFallBack}
          className=" max-profile-limit-fallback-image-height"
          alt=""
          width={"auto"}
        />
        <p className=" font-family-gilroy-medium padding-top-larger font-size-medium font-color-secondary text-align-center ">
          Max profile limit reached!
        </p>
        <p className=" font-family-gilroy-regular padding-top-large font-size-medium text-align-center padding-right-larger padding-left-larger">
          You can only have {MAX_PROFILE_COUNT} profiles associated with one
          phone number. Try using another phone number or removing a profile
          from this phone number
        </p>
      </section>
    </React.Fragment>
  );
};
