const patientsReducer = (
  state = {
    loading: false,
    transactionId: null,
    profiles: null,
    currentProfile: null,
    healthIdData: null,
    accessToken: null,
    linkingToken: null,
    careContexts: null,
    selectedLinkedFacility: null,
    usedHealthId: null,
    unusedHealthId: null,
    authModes: null,
    abhaNumberData: null,
    requests: null,
    subscription: null,
    autoApprovalPolicies: null,
    requestsFilter: {
      filterType: "",
      clinics: [],
      requests: [],
      status: []
    }
  },
  action
) => {
  switch (action.type) {
    case "SET_PATIENTS_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_TRANSACTION_ID":
      return {
        ...state,
        transactionId: action.payload
      };

    case "SET_PATIENTS_DEMOGRAPHICS":
      return {
        ...state,
        profiles: action.payload
      };

    case "SET_CURRENT_PATIENT_PROFILE_ID":
      return {
        ...state,
        currentProfile: action.payload.currentProfile
      };

    case "SET_ABDM_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload.abdmAccessToken
      };

    case "PUT_AUTH_MODE":
      return {
        ...state,
        authModes: action.payload.data
      };

    case "SET_ABHA_NUMBER_DATA":
      return {
        ...state,
        abhaNumberData: action.payload.data
      };

    case "REMOVE_ABDM_ACCESS_TOKEN": {
      const currentAccessTokens = JSON.parse(JSON.stringify(state.accessToken));
      delete currentAccessTokens[action.payload.patientId];
      return {
        ...state,
        accessToken: currentAccessTokens ? currentAccessTokens : null
      };
    }
    case "SET_LINKING_TOKEN_DETAILS":
      return {
        ...state,
        linkingToken: action.payload.linkingTokenData
      };

    case "SET_ABDM_UNUSED_HEALTH_ID":
      return {
        ...state,
        unusedHealthId: action.payload.healthId
      };

    case "SET_ABDM_USED_HEALTH_ID":
      return {
        ...state,
        usedHealthId: action.payload.healthId
      };

    case "SET_PATIENTS_REQUESTS":
      return {
        ...state,
        requests: action.payload
      };

    case "SET_PATIENTS_CARE_CONTEXTS":
      return {
        ...state,
        careContexts: action.payload
      };

    case "SET_PATIENT_SUBSCRIPTION":
      return {
        ...state,
        subscription: action.payload.subscription
      };

    case "SET_AUTO_APPROVAL_POLICIES":
      return {
        ...state,
        autoApprovalPolicies: action.payload.autoApprovalPolicies
      };

    case "SET_HEALTH_ID_INFORMATION":
      return {
        ...state,
        healthIdData: {
          ...state.healthIdData,
          [action.payload.currentProfile]: action.payload.data
        }
      };

    case "SET_FILTER_TYPE":
      return {
        ...state,
        requestsFilter: {
          ...state.requestsFilter,
          filterType: action.payload.id
        }
      };

    case "SET_CLINICS_FILTER":
      return {
        ...state,
        requestsFilter: {
          ...state.requestsFilter,
          clinics: action.payload.clinics
        }
      };

    case "SET_REQUESTS_FILTER":
      return {
        ...state,
        requestsFilter: {
          ...state.requestsFilter,
          requests: action.payload.requests
        }
      };

    case "SET_STATUS_FILTER":
      return {
        ...state,
        requestsFilter: {
          ...state.requestsFilter,
          status: action.payload.status
        }
      };

    case "SET_SELECTED_LINKED_FACILITY":
      return {
        ...state,
        selectedLinkedFacility: action.payload.linkedFacilityId
      };

    case "CLEAR_REQUEST_FILTER":
      return {
        ...state,
        requestsFilter: {
          filterType: "",
          clinics: [],
          requests: [],
          status: []
        }
      };

    case "LOGOUT":
    case "LOGOUT_ABDM":
    case "RESET":
      return {
        loading: false,
        transactionId: null,
        profiles: null,
        currentProfile: null,
        healthIdData: null,
        accessToken: null,
        linkingToken: null,
        careContexts: null,
        selectedLinkedFacility: null,
        usedHealthId: null,
        unusedHealthId: null,
        authModes: null,
        abhaNumberData: null,
        requests: null,
        subscription: null,
        autoApprovalPolicies: null,
        requestsFilter: {
          filterType: "",
          clinics: [],
          requests: [],
          status: []
        }
      };
    default:
      return state;
  }
};

export default patientsReducer;
