import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./SignUp.css";
import Button from "../../front-end-global-components/components/Button/Button";
import { reCaptchaVerifier } from "../../services/authentication";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Header from "../../front-end-global-components/components/Header/Header";
import { setErrorStatus } from "../../redux/status/actions";
import {
  clearAuthCredentials,
  sendOtp,
  verifyOtp
} from "../../redux/authentication/actions";
import { mockAuthVerifyOtp } from "../../redux/mock/actions";

function SignUp(props) {
  const [signUpFormData, setSignUpFormData] = useState({
    userName: "",
    phoneNumber: "+91",
    OTP: null,
    termsAndConditions: false
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    if (signUpFormData.phoneNumber === null) {
    }
    setSignUpFormData({
      ...signUpFormData,
      OTP: otp
    });
  };

  const signUpFormOnChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      signUpFormData.phoneNumber !== event.target.value
    ) {
      //clear auth verification
      clearAuthCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else if (event.target.name === "termsAndConditions") {
      setSignUpFormData({
        ...signUpFormData,
        [event.target.name]: event.target.checked
      });
    } else {
      setSignUpFormData({
        ...signUpFormData,
        [event.target.name]: event.target.value
      });
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    if (signUpFormData.userName.trim().length === 0) {
      return props.setErrorStatus({
        code: "custom",
        message: "Please enter a valid name"
      });
    }
    props.sendOtp(signUpFormData.phoneNumber, "signUp");
  };

  const signUpHandler = (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthVerifyOtp(
        signUpFormData.OTP,
        signUpFormData.userName,
        signUpFormData.phoneNumber,
        "signUp"
      );
    } else {
      props.verifyOtp(
        signUpFormData.OTP,
        signUpFormData.userName,
        signUpFormData.phoneNumber,
        "signUp"
      );
    }
  };

  return (
    <React.Fragment>
      <article className="inherit-parent-height">
        <Header
          backButtonOnClick={() => {}}
          hideSupport={true}
          hideBackButton={true}
          maxStepperCount={3}
        />
        <form
          data-cy="login-or-signup"
          className="remaining-body-height overflow-x-scroll width-100-percent grey-abstract-background-image flex-center-children-vertically flex-direction-column"
          onChange={(event) => {
            signUpFormOnChange(event);
          }}
          onSubmit={(event) => event.preventDefault()}
        >
          <section className="padding-larger inherit-parent-width max-width-500px flex-grow-1">
            <h1 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
              PATIENT SIGNUP
            </h1>
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="userName"
              label="Name"
              value={signUpFormData.userName}
              type="text"
              autoComplete="off"
              required
              data-cy="signup-screen-name-field"
            />
            <InputBox
              className="inherit-parent-width border-radius-default margin-bottom-large"
              name="phoneNumber"
              label="Phone Number"
              value={signUpFormData.phoneNumber}
              type="tel"
              autoComplete="off"
              show="otpButton"
              buttonTileId="login-button"
              onButtonClick={(event) => {
                otpRequestHandler(event);
              }}
              data-cy="signup-screen-phone-number-field"
              suffixButtonDisabled={signUpFormData.phoneNumber?.length !== 13}
              buttonTile={
                props.auth.credentials.verificationId !== null
                  ? "Resend OTP"
                  : "Get OTP"
              }
              buttonType={
                typeof props.auth.credentials.verificationId === "string"
                  ? "button"
                  : "submit"
              }
              required
            />
            {props.auth.credentials.verificationId ? (
              <>
                <OTPInputBox
                  name="otp"
                  setValue={setOTPValue}
                  className="margin-bottom-large"
                  required
                />
                <div className="inherit-parent-width display-flex padding-bottom-large text-align-left font-size-small font-family-gilroy-medium margin-top-small">
                  <CheckBox name="termsAndConditions" label="Agree to" />
                  <span className="margin-left-default  font-color-primary">
                    Terms and conditions.
                  </span>
                </div>
              </>
            ) : null}
          </section>
          <footer className="padding-larger inherit-parent-width max-width-500px">
            <Button
              loading={props.auth.loading}
              type={
                typeof props.auth.credentials.verificationId === "string"
                  ? "submit"
                  : "button"
              }
              className="margin-bottom-large"
              text="Signup"
              onClick={(event) => {
                signUpHandler(event);
              }}
              boxShadow={false}
              disabled={
                !(
                  typeof signUpFormData.OTP === "string" &&
                  signUpFormData.OTP.length === 6 &&
                  signUpFormData.termsAndConditions === true
                )
              }
              data-cy={
                props.auth.loading === true
                  ? "signup-loading-button"
                  : "signup-button"
              }
            />
            <h2 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
              ALREADY HAVE AN ACCOUNT?
              <span
                className="margin-left-default font-color-primary"
                onClick={() => {
                  props.clearAuthCredentials();
                  props.navigate("/login");
                }}
              >
                Login
              </span>
            </h2>
          </footer>
        </form>
        <div id="recaptcha-placeholder"></div>
      </article>
    </React.Fragment>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (otp, userName, phoneNumber, type) =>
      verifyOtp(otp, userName, phoneNumber, type),
    mockAuthVerifyOtp: (otp, userName, phoneNumber, type) =>
      mockAuthVerifyOtp(otp, userName, phoneNumber, type),
    sendOtp: (phoneNumber, type) => sendOtp(phoneNumber, type),
    clearAuthCredentials: () => clearAuthCredentials(),
    setErrorStatus: (errorObj) => {
      setErrorStatus(errorObj);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
