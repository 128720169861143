import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Login.css";
import Button from "../../front-end-global-components/components/Button/Button";
import { reCaptchaVerifier } from "../../services/authentication";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Header from "../../front-end-global-components/components/Header/Header";
import {
  clearAuthCredentials,
  sendOtp,
  verifyOtp
} from "../../redux/authentication/actions";
import { mockAuthVerifyOtp } from "../../redux/mock/actions";

function Login(props) {
  const [loginFormData, setLoginFormData] = useState({
    phoneNumber: "+91",
    OTP: null
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    if (loginFormData.phoneNumber === null) {
    }
    setLoginFormData({
      ...loginFormData,
      OTP: otp
    });
  };

  const loginFormChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      loginFormData.phoneNumber !== event.target.value
    ) {
      clearAuthCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else {
      setLoginFormData({
        ...loginFormData,
        [event.target.name]: event.target.value
      });
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    props.sendOtp(loginFormData.phoneNumber, "login");
    setLoginFormData({ ...loginFormData, show: "otpInput" });
  };

  const otpVerifyHandler = (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthVerifyOtp("login");
    } else {
      props.verifyOtp(loginFormData.OTP);
    }
  };

  return (
    <>
      <div className="inherit-parent-height" data-cy="login-screen">
        <Header hideSupport={true} hideBackButton={true} maxStepperCount={3} />
        <form
          data-cy="login-or-signup"
          className="remaining-body-height overflow-x-scroll width-100-percent grey-abstract-background-image flex-place-children-page-center"
          onChange={(event) => {
            loginFormChange(event);
          }}
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div className="padding-larger inherit-parent-width max-width-500px flex-grow-1">
            <h1 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
              PATIENT LOGIN
            </h1>
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="phoneNumber"
              label="Phone Number"
              type="tel"
              otpFocus={true}
              autoComplete="off"
              value={loginFormData.phoneNumber}
              buttonTileId="login-button"
              buttonTile={
                props.auth.credentials.verificationId !== null
                  ? "Resend OTP"
                  : "Get OTP"
              }
              buttonType={
                typeof props.auth.credentials.verificationId === "string"
                  ? "button"
                  : "submit"
              }
              onButtonClick={(event) => {
                otpRequestHandler(event);
              }}
              data-cy="login-screen-phone-number-field"
              suffixButtonDisabled={loginFormData.phoneNumber?.length !== 13}
              required
            />
            {props.auth.credentials.verificationId ? (
              <OTPInputBox
                name="otp"
                setValue={setOTPValue}
                className="margin-bottom-large"
                required
              />
            ) : null}
          </div>
          <div className="padding-larger inherit-parent-width max-width-500px">
            <Button
              data-cy={
                props.auth.loading === true
                  ? "login-loading-button"
                  : "login-button"
              }
              loading={props.auth.loading}
              type={
                typeof props.auth.credentials.verificationId === "string"
                  ? "submit"
                  : "button"
              }
              className="margin-bottom-large"
              text="Login"
              onClick={(event) => {
                otpVerifyHandler(event);
              }}
              boxShadow={false}
              disabled={
                !(
                  typeof loginFormData.OTP === "string" &&
                  loginFormData.OTP.length === 6
                )
              }
            />
            <h2 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
              DONT HAVE AN ACCOUNT?
              <span
                className="margin-left-default font-color-primary"
                onClick={() => {
                  props.clearAuthCredentials();
                  props.navigate("/signup");
                }}
              >
                Signup
              </span>
            </h2>
          </div>
        </form>
        <div id="recaptcha-placeholder"></div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (loginOtp) => verifyOtp(loginOtp),
    mockAuthVerifyOtp: (type) => mockAuthVerifyOtp(type),
    sendOtp: (phoneNumber, type) => sendOtp(phoneNumber, type),
    clearAuthCredentials: () => clearAuthCredentials()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
