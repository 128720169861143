import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../front-end-global-components/components/Button/Button";
import Header from "../../front-end-global-components/components/Header/Header";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import {
  isValidArray,
  isValidObject,
  validation
} from "../../utils/validators";
import "./Abdm.css";
import uniqueIdentityIcon from "../../assets/icons/uniqueIdentity.svg";
import unificationIcon from "../../assets/icons/unification.svg";
import phrIcon from "../../assets/icons/phr.svg";
import nextIconGreen from "../../assets/icons/nextIconGreen.svg";
import accessibleIcon from "../../assets/icons/accessible.svg";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import { Regex } from "../../utils/regex";
import { healthIdSchema } from "../../utils/models";
import arrow from "../../front-end-global-components/assets/DropDownArrow.svg";
import {
  consentStatus,
  getValidAbhaNumber,
  getValidDateOfBirth,
  getValidGender,
  stateCodeWithDistricts,
  stateOptionsWithCode
} from "../../utils/constants";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import logoutIcon from "../../assets/icons/logout.svg";
import nextIcon from "../../assets/icons/Next.svg";
import UnderMaintenance from "../../front-end-global-components/components/UnderMaintenance/UnderMaintenance";
import {
  CircleWithTickIcon,
  TickIcon
} from "../../front-end-global-components/assets/assets";
import {
  checkHealthIdExists,
  clearAbdmAuthCredentials,
  clearHealthIdExistsStatus,
  registerNewAbhaNumber
} from "../../redux/abdm/actions";
import {
  clearUnusedHealthId,
  createHealthId,
  getModesForAbdmAuth,
  getPatientsRequests,
  loginWithHealthId,
  logoutHealthId,
  sendOtpForAbdmAuthentication,
  setAuthModes,
  setTransactionId,
  verifyAbdmAuthenticationOtp
} from "../../redux/patients/actions";
import phone from "../../assets/icons/phoneLogo.svg";
import at from "../../assets/icons/atLogo.svg";
import number from "../../assets/icons/abhaNumberLogo.svg";
import mail from "../../assets/icons/mailLogo.svg";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import CreateAbhaNumber from "../../components/CreateAbhaNumber/CreateAbhaNumber";
const headerTitle = (authMode) => {
  switch (authMode) {
    case "login":
      return "ABHA address login";
    case "register":
      return "Create ABHA address";
    case "loggedIn":
      return "Ayushman Bharat Health Account";
    case CREATE_HEALTH_ID:
      return "Create ABHA";
    default:
      return "Link your ABHA address";
  }
};

const LINK_HEALTH_ID = "healthIdLinkComponent";
const CREATE_HEALTH_ID = "healthIdCreateComponent";
const ABDM_AUTH = "abdmAuthComponent";
const ABDM = "abdmComponent";
const ABDM_CONSENT = "abdmConsentScreen";

const AbdmAuthentication = (props) => {
  const [show, setShow] = useState(ABDM);
  const [showAuthModeModal, setShowAuthModeModal] = useState(false);
  const [showLoginModes, setShowLoginModes] = useState(false);
  const [authType, setAuthType] = useState(null);
  const [authMode, setAuthMode] = useState(null);
  const [createNewHealId, setCreateNewHealId] = useState(false);
  const [counter, setCounter] = useState(null);
  const [step, setStep] = useState("initial");
  const [showConnectModal, setShowConnectModal] = useState({
    value: false,
    type: null
  });
  const [loginFormData, setLoginFormData] = useState({
    healthId:
      props.patients.currentProfile &&
      props.patients.profiles[props.patients.currentProfile]?.healthId &&
      !window.location.search.includes("force=true")
        ? props.patients.profiles[props.patients.currentProfile]?.healthId
        : "",
    abhaNumber: "",
    email: "",
    otp: "",
    agreeTo: false,
    yearOfBirth: "",
    aadhaarNumber: "",
    phoneNumber:
      props.patients.currentProfile &&
      props.patients.profiles[props.patients.currentProfile]?.phoneNumber
        ? props.patients.profiles[props.patients.currentProfile]?.phoneNumber
        : "+91",
    password: ""
  });

  const [authenticationMode, setAuthenticationMode] = useState({
    name: "Phone",
    value: "phoneNumber",
    logo: phone,
    description: "LOGIN VIA PHONE NO. CONNECTED WITH ABHA"
  });

  const [showCreateAbhaNumberModal, setShowCreateAbhaNumberModal] =
    useState(false);

  useEffect(() => {
    if (
      props.patients.currentProfile &&
      props.patients.profiles[props.patients.currentProfile]?.healthId &&
      !window.location.search.includes("force=true") &&
      authMode === "login"
    ) {
      setAuthenticationMode({
        name: "ABHA Address",
        value: "healthId",
        logo: at,
        description: "LOGIN VIA ABHA ADDRESS CONNECTED WITH YOUR PROFILE"
      });
    } else {
      setAuthenticationMode({
        name: "Phone",
        value: "phoneNumber",
        logo: phone,
        description: "LOGIN VIA PHONE NO. CONNECTED WITH ABHA"
      });
    }
    if (authenticationMode.value === "healthId" && authMode === "register") {
      setAuthenticationMode({
        name: "Phone",
        value: "phoneNumber",
        logo: phone,
        description: "LOGIN VIA PHONE NO. CONNECTED WITH ABHA"
      });
    }
    // eslint-disable-next-line
  }, [props.patients.currentProfile, authMode]);

  const headerGoBackOnClickHandler = () => {
    switch (authMode) {
      case "login":
      case "register":
        if (step === "second") {
          return setStep("initial");
        } else if (window.location.search.includes("force=true")) {
          setShow(ABDM);
          setAuthMode(null);
        } else if (
          props.patients.profiles &&
          props.patients.currentProfile &&
          props.patients.profiles[props.patients.currentProfile]?.healthId
        ) {
          return props.navigate(-1);
        } else if (createNewHealId === true) {
          setCreateNewHealId(false);
          setShow(LINK_HEALTH_ID);
          props.setTransactionId(null);
        } else if (!(show === LINK_HEALTH_ID)) {
          resetAuthState();
          props.setTransactionId(null);
        } else {
          return props.navigate(-1);
        }
        break;
      case "loggedIn":
        return props.navigate(-1);
      default:
        props.navigate(-1);
        break;
    }
  };

  useEffect(() => {
    if (
      isValidArray(props.patients.usedHealthId) ||
      isValidArray(props.patients.unusedHealthId)
    ) {
      setShowConnectModal({
        ...showConnectModal,
        value: false
      });
    }
    // eslint-disable-next-line
  }, [props.patients.usedHealthId, props.patients.unusedHealthId]);

  useEffect(() => {
    if (authMode) {
      let counterTimer;
      if (counter && typeof counter === "number") {
        counterTimer = setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
      }

      if (counter === null) {
        setCounter(0);
        clearTimeout(counterTimer);
      }
    } else {
      setCounter(null);
      props.clearAbdmAuthCredentials();
    }
    // eslint-disable-next-line
  }, [counter, authMode]);

  // clear unUsed healthIds
  useEffect(() => {
    if (isValidArray(props.patients.unusedHealthId)) {
      props.clearUnusedHealthId();
    }
    if (props.patients?.authModes?.length < 0) {
      props.setAuthModes();
    }
    // eslint-disable-next-line
  }, []);

  const resetAuthState = () => {
    if (props.patients?.transactionId || props.abdm?.auth?.transactionId) {
      props.clearAbdmAuthCredentials();
    }
    setAuthMode(null);
    setShow(ABDM);
  };

  const returnYearArrayForSelectBox = (startYear) => {
    const currentYear = new Date().getFullYear();
    let yearArray = [];
    for (let i = startYear; i <= currentYear; i++) {
      yearArray.push(i);
    }
    return yearArray;
  };

  useEffect(() => {
    if (isValidArray(props.patients.authModes) && authMode) {
      setShowLoginModes(true);
    }
  }, [props.patients.authModes, authMode]);

  //abdm health account authentication handler
  const abdmAuthOnSubmitHandler = () => {
    if (
      authenticationMode.value === "healthId" &&
      loginFormData[authenticationMode.value]
    ) {
      props.getModesForAbdmAuth(
        authMode,
        authenticationMode.name,
        loginFormData[authenticationMode.value]
      );
      return;
    }

    if (
      authenticationMode.value === "abhaNumber" &&
      loginFormData[authenticationMode.value]
    ) {
      props.getModesForAbdmAuth(
        authMode,
        authenticationMode.name,
        loginFormData[authenticationMode.value],
        loginFormData.yearOfBirth
      );
      return;
    }

    if (
      typeof props.patients.transactionId === "string" &&
      loginFormData.otp.length === 6
    ) {
      props.verifyAbdmAuthenticationOtp(
        authMode,
        authenticationMode.name,
        loginFormData.otp,
        props.navigate,
        null,
        loginFormData.healthId
      );
    } else {
      setCounter(60);
      props.sendOtpForAbdmAuthentication(
        authMode,
        authType,
        authenticationMode.name,
        loginFormData[authenticationMode.value]
      );
    }
  };

  //abdm health account logout handler
  const logoutHandler = () => {
    props.logoutAbdm(props.patients.currentProfile);
  };

  const buttonDisableHandler = () => {
    if (counter && typeof counter === "number") {
      return true;
    }
    switch (authenticationMode.value) {
      case "healthId":
        if (authMode === "login") {
          if (loginFormData[authenticationMode.value].trim().length > 4) {
            return false;
          } else {
            return true;
          }
        } else {
          Regex.healthId?.test(loginFormData[authenticationMode.value]);
        }
        break;

      default:
        return !Regex[authenticationMode.value].test(
          loginFormData[authenticationMode.value]
        );
    }
  };

  // show health Id link component
  useEffect(() => {
    if (
      show === ABDM_AUTH &&
      Array.isArray(props.patients.unusedHealthId) &&
      createNewHealId === false
    ) {
      setShow(LINK_HEALTH_ID);
    }
    // eslint-disable-next-line
  }, [
    createNewHealId,
    props.patients.transactionId,
    props.patients.unusedHealthId,
    props.patients.usedHealthId
  ]);

  // change ABDM auth screen
  useEffect(() => {
    if (
      props.patients.currentProfile &&
      props.patients.accessToken &&
      props.patients.accessToken[props.patients.currentProfile] &&
      !window.location.search.includes("force=true")
    ) {
      setShow(ABDM_CONSENT);
      return;
    }
    if (
      props.patients.profiles &&
      props.patients.currentProfile &&
      props.patients.profiles[props.patients.currentProfile]?.healthId &&
      !window.location.search.includes("force=true") &&
      show !== ABDM_AUTH
    ) {
      setShow(ABDM_AUTH);
      if (!authMode) {
        setAuthMode("login");
      }
    } else {
      if (authMode === null && window.location.search.includes("force=true")) {
        setShow(ABDM);
      }
    }
    // eslint-disable-next-line
  }, [props.patients.currentProfile]);

  useEffect(() => {
    if (
      props.patients.transactionId &&
      (authenticationMode.value === "healthId" ||
        authenticationMode.value === "abhaNumber") &&
      show !== CREATE_HEALTH_ID
    ) {
      setShowConnectModal({
        ...showConnectModal,
        value: true
      });
    }
    // eslint-disable-next-line
  }, [props.patients.transactionId]);

  useEffect(() => {
    if (isValidObject(props.patients.abhaNumberData)) {
      setShow(CREATE_HEALTH_ID);
      setShowConnectModal({
        ...showConnectModal,
        value: false
      });
      setShowLoginModes(false);
    }
    // eslint-disable-next-line
  }, [props.patients.abhaNumberData]);

  const disableContinueButton = () => {
    if (authenticationMode?.value === "healthId" && loginFormData?.healthId) {
      if (
        props.patients.currentProfile &&
        !props.patients.profiles[props.patients.currentProfile]?.healthId
      ) {
        if (loginFormData?.agreeTo) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    if (
      authenticationMode?.value === "abhaNumber" &&
      loginFormData?.abhaNumber &&
      loginFormData.yearOfBirth
    ) {
      if (
        props.patients.currentProfile &&
        !props.patients.profiles[props.patients.currentProfile]?.healthId
      ) {
        if (loginFormData?.agreeTo) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
    if (
      typeof props.patients.transactionId === "string" &&
      loginFormData.otp.length === 6
    ) {
      if (
        props.patients.currentProfile &&
        !props.patients.profiles[props.patients.currentProfile]?.healthId
      ) {
        if (loginFormData?.agreeTo) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    if (loginFormData?.agreeTo) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Header
        title={headerTitle(
          show === CREATE_HEALTH_ID ? CREATE_HEALTH_ID : authMode
        )}
        backButtonOnClick={() => {
          headerGoBackOnClickHandler(authMode);
          if (props.patients.transactionId !== null) {
            props.setTransactionId(null);
            props.setAuthModes(null);
          }
        }}
      />
      <section className="remaining-body-height max-width-588px margin-horizontal-auto overflow-y-auto inherit-parent-width ">
        <ProtectedRender condition={show === ABDM_CONSENT}>
          <AbdmComponent
            changeConsentPinOnClick={() => {
              props.navigate("/abdm/changeconsentpin");
            }}
            consentsCount={
              props.patients.currentProfile &&
              props.patients.requests &&
              props.patients.requests[props.patients.currentProfile] &&
              Array.isArray(
                props.patients.requests[props.patients.currentProfile].consents
              )
                ? props.patients.requests[
                    props.patients.currentProfile
                  ].consents.filter(
                    (req) => req?.status === consentStatus.pending
                  )?.length
                : 0
            }
            abdmHealthIdQrOnClick={() => {
              props.navigate("/abdm/abdmHealthId");
            }}
            consentsOnClick={() => {}}
            logoutOnClick={() => {
              logoutHandler();
            }}
            // abdmConsentPinStatus={abdmConsentPinStatus}
          />
        </ProtectedRender>

        <ProtectedRender condition={authMode === null && show === ABDM}>
          <>
            {/* for remote config */}
            {false ? (
              <UnderMaintenance className="inherit-parent-height" />
            ) : (
              <HealthIdAuthComponent
                loginOnClick={() => {
                  setShow(ABDM_AUTH);
                  setAuthMode("login");
                  setCounter(null);
                  props.setAuthModes(null);
                  setShowLoginModes(false);
                }}
                createOnClick={() => {
                  setShow(ABDM_AUTH);
                  setAuthMode("register");
                  setCounter(null);
                  props.setAuthModes(null);
                  setShowLoginModes(false);
                }}
                // abdmAuthFeatureStatus={abdmAuthFeatureStatus}
              />
            )}
          </>
        </ProtectedRender>
        {/* Create new health record(NDHM) component */}
        <ProtectedRender
          condition={show === CREATE_HEALTH_ID && authMode === "register"}
        >
          <CreateHealthId
            checkHealthIdExists={(healthId) => {
              if (!healthId) return;
              props.checkHealthIdExists(healthId);
            }}
            abhaNumberData={props.patients.abhaNumberData}
            setStep={setStep}
            step={step}
            clearHealthIdExistStatus={() => {
              props.clearHealthIdExistStatus();
            }}
            loading={props.patients.loading}
            patient={props.patients.profiles?.[props.patients.currentProfile]}
            authMethod={authenticationMode.value}
            isHealthIdExists={props.patients.healthIdExists}
            createHealthIdOnSubmit={(data) => {
              let _data = { ...data };
              _data.transactionId = props.patients.transactionId;
              const responseData = { ...healthIdSchema(_data) };
              const editedData = {
                ...responseData,
                alreadyExistedPHR: false,
                ...(!window.location.search.includes("force=true")
                  ? { patientId: props.patients.currentProfile }
                  : {})
              };
              props.createHealthId(
                editedData,
                authenticationMode.name,
                props.navigate
              );
            }}
          />
        </ProtectedRender>
        {/* Abha profiles linking component */}
        <ProtectedRender condition={show === LINK_HEALTH_ID}>
          <AbhaLinkComponent
            patients={props.patients}
            abdm={props.abdm}
            loading={props.patients.loading}
            profileData={
              props.patients.profiles?.[props.patients.currentProfile]
            }
            profilePictureBackground={
              props.patients.backgroundColors?.[props.patients.currentProfile]
            }
            profilePicture={
              props.downloads.data.profilePictures.patients?.[
                props.patients.currentProfile
              ]
            }
            createAbhaIdOnClick={() => {
              setShow(CREATE_HEALTH_ID);
              setAuthMode("register");
              setCreateNewHealId(true);
            }}
            onClickLinkHealthId={(healthId, alreadyExistedPHR) => {
              if (authMode === "login") {
                props.loginWithHealthId(
                  authenticationMode.name,
                  healthId,
                  props.navigate
                );
              } else if (authMode === "register") {
                props.createHealthId(
                  {
                    phrAddress: healthId,
                    alreadyExistedPHR: alreadyExistedPHR,
                    transactionId: props.patients.transactionId,
                    ...(!window.location.search.includes("force=true")
                      ? { patientId: props.patients.currentProfile }
                      : {})
                  },
                  authenticationMode.value,
                  props.navigate
                );
              }
            }}
            onClickCreateNewHealthId={() => {
              setShow(ABDM_AUTH);
              setAuthMode("register");
            }}
          />
        </ProtectedRender>
        {/* Health record authentication component */}
        <ProtectedRender condition={show === ABDM_AUTH}>
          <form
            className="inherit-parent-height flex-justify-content-space-between flex-direction-column"
            onChange={(event) => {
              if (event.target.name === "agreeTo") {
                setLoginFormData({
                  ...loginFormData,
                  [event.target.name]: event.target.checked
                });
              } else {
                setLoginFormData({
                  ...loginFormData,
                  [event.target.name]: event.target.value
                });
              }
            }}
            onSubmit={(event) => {
              event.preventDefault();
              abdmAuthOnSubmitHandler();
            }}
          >
            <section>
              <AuthModeSwitcher
                disable={
                  props.patients.currentProfile &&
                  props.patients.profiles[props.patients.currentProfile]
                    ?.healthId &&
                  authMode === "login" &&
                  !window.location.search.includes("force=true")
                    ? true
                    : false
                }
                data={authenticationMode}
                downArrowOnClick={() => {
                  setShowAuthModeModal(true);
                }}
              />

              <div className="padding-right-larger padding-left-larger">
                {(authenticationMode.name === "Phone" ||
                  authenticationMode.name === "Email") && (
                  <>
                    <InputBox
                      className="inherit-parent-width border-radius-default margin-bottom-default"
                      name={
                        authenticationMode.name === "Phone"
                          ? "phoneNumber"
                          : "email"
                      }
                      label={
                        authenticationMode.name === "Phone"
                          ? "Phone Number"
                          : "Email"
                      }
                      type={
                        authenticationMode.name === "Phone" ? "tel" : "email"
                      }
                      otpFocus={true}
                      autoFocus={true}
                      value={
                        authenticationMode.name === "Phone"
                          ? loginFormData.phoneNumber
                          : loginFormData.email
                      }
                      buttonClassName=" background-color-white"
                      buttonTile={
                        typeof counter === "number" && counter !== 0
                          ? `${
                              counter?.toString().length === 1 ? "0" : ""
                            }${counter?.toString()} seconds`
                          : props.patients.transactionId
                          ? "Resend OTP"
                          : "Get OTP"
                      }
                      disabled={
                        authenticationMode.name === "Phone" &&
                        props.patients.currentProfile &&
                        props.patients.profiles[props.patients.currentProfile]
                          ?.phoneNumber
                          ? true
                          : false
                      }
                      suffixButtonDisabled={buttonDisableHandler()}
                      buttonType={
                        props.patients.transactionId &&
                        loginFormData.otp.length === 6
                          ? "button"
                          : "submit"
                      }
                      data-cy={`abha-login-${
                        authenticationMode.name === "Phone"
                          ? "phone-number"
                          : "email"
                      }-field`}
                      validation={(value) =>
                        validation(
                          authenticationMode.name === "Phone"
                            ? "phoneNumber"
                            : "email",
                          value
                        )
                      }
                      required
                    />
                    {props.patients.transactionId &&
                      typeof props.patients.transactionId === "string" && (
                        <OTPInputBox
                          name="otp"
                          autoFocus={true}
                          value={loginFormData.otp}
                          setValue={(otpValue) => {
                            setLoginFormData({
                              ...loginFormData,
                              otp: otpValue
                            });
                          }}
                          required
                          className="margin-bottom-larger"
                        />
                      )}
                  </>
                )}

                {authenticationMode.name === "ABHA Address" && (
                  <InputBox
                    // inputSuffixPlaceHolder={"@sbx"}
                    labelClassName="letter-spacing-4-percentage"
                    className="inherit-parent-width border-radius-default margin-bottom-default"
                    label="ABHA Address"
                    type="text"
                    name="healthId"
                    autoFocus={true}
                    value={loginFormData.healthId}
                    disabled={
                      props.patients.currentProfile &&
                      props.patients.profiles[props.patients.currentProfile]
                        ?.healthId &&
                      !window.location.search.includes("force=true")
                        ? true
                        : false
                    }
                    inputNotesClassName="font-color-primary font-size-smaller padding-top-small"
                  />
                )}

                {authenticationMode.name === "ABHA Number" && (
                  <>
                    <InputBox
                      className="inherit-parent-width border-radius-default margin-bottom-default"
                      name="abhaNumber"
                      label="ABHA Number"
                      type="text"
                      autoFocus={true}
                      value={[loginFormData.abhaNumber]}
                      required
                      disabled={props.patients.loading}
                    />
                    <NativeSelect
                      removeResponsive={true}
                      name="yearOfBirth"
                      data-cy="demographics-patient-year-of-birth"
                      label="Year Of Birth"
                      defaultValue={loginFormData.yearOfBirth}
                      options={["", ...returnYearArrayForSelectBox(1900)]}
                      required
                    />
                    <div
                      className=" font-size-small font-color-primary text-align-right"
                      onClick={() => {
                        setShowCreateAbhaNumberModal(true);
                      }}
                    >
                      Create ABHA Number
                    </div>
                  </>
                )}
              </div>
            </section>
            <section className="padding-left-larger padding-right-larger">
              {props.patients.currentProfile &&
                !props.patients.profiles?.[props.patients.currentProfile]
                  ?.healthId && (
                  <div className="inherit-parent-width display-flex padding-top-large text-align-left font-size-small font-family-gilroy-medium margin-bottom-default flex-align-items-center">
                    <CheckBox name="agreeTo" label="I Agree with" />
                    <span className="text-underline-hover padding-left-small">
                      ABDM's terms and conditions
                    </span>
                  </div>
                )}
              <Button
                text="Continue"
                data-cy="abdm-login-button"
                boxShadow={false}
                required
                type={
                  authenticationMode?.value === "healthId" &&
                  loginFormData?.healthId
                    ? "submit"
                    : authenticationMode?.value === "abhaNumber" &&
                      loginFormData?.abhaNumber
                    ? "submit"
                    : props.patients.transactionId &&
                      loginFormData.otp.length !== 6
                    ? "button"
                    : "submit"
                }
                className="margin-top-default"
                disabled={disableContinueButton()}
                loading={props.patients.loading}
              />
              <AuthModeSwitch
                authMode={authMode}
                onClick={(selectedAuthMode) => {
                  setAuthMode(selectedAuthMode);
                  props.setTransactionId(null);
                  setCounter(null);
                  props.setAuthModes(null);
                  if (
                    typeof props.abdm?.auth?.otp?.requestMethod === "string"
                  ) {
                    props.clearAbdmAuthCredentials();
                  }
                }}
              />
            </section>
          </form>
          <CreateAbhaNumber
            showModal={showCreateAbhaNumberModal}
            registerNewAbhaNumber={(data) => {
              props.registerNewAbhaNumber(data);
            }}
            getOtp={typeof props.abdm.transactionId === "string" ? true : false}
            getMobileNumber={
              typeof props.abdm.transactionId === "string" &&
              props.abdm.transactionId.includes("aadhaarVerified")
            }
            getMobileOtp={
              typeof props.abdm.transactionId === "string" &&
              props.abdm.transactionId.includes("getMobileOtp")
            }
            abhaNumberDetails={props.abdm.newHealthNumberData}
            loading={props.abdm.loading}
            closeModal={() => {
              setShowCreateAbhaNumberModal(false);
              if (props.abdm.newHealthNumberData) {
                props.registerNewAbhaNumber({
                  clearNewHealthNumberData: true
                });
              }
            }}
          />
        </ProtectedRender>

        <Modal
          show={showAuthModeModal}
          onClose={() => {
            setShowAuthModeModal(!showAuthModeModal);
            props.setAuthModes(null);
          }}
          canIgnore={true}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-fit-to-content"
        >
          <div
            className="inherit-parent-width inherit-parent-height "
            style={{ flexBasis: "1" }}
          />
          <footer
            data-cy="abdm-auth-switcher-modal"
            className="inherit-parent-width background-white"
          >
            <div className="padding-large">
              <div className="text-align-center padding-bottom-default font-size-smaller">
                SELECT TO LOGIN WITH YOUR ABHA ADDRESS
              </div>
              {Object.values(authModes).map(
                (data, index) =>
                  (authMode === "login" ||
                    (authMode === "register" &&
                      !(data.value === "healthId"))) && (
                    <div
                      key={index}
                      className="inherit-parent-width display-flex flex-justify-content-space-between flex-center-children-vertically padding-bottom-large"
                      data-cy={`login-mode-${data.value}`}
                      onClick={() => {
                        setAuthenticationMode(data);
                        setAuthType(data.name);
                        setShowAuthModeModal(!showAuthModeModal);
                        setCounter(null);
                        props.setTransactionId(null);
                      }}
                    >
                      <div className="display-flex flex-direction-row">
                        <img
                          className="padding-right-default"
                          src={data.logo}
                          alt=""
                        />
                        <div className="inherit-parent-width padding-default">
                          <div className="font-family-gilroy-regular padding-bottom-smaller">
                            {data.name}
                          </div>
                          <div className="font-size-smaller">
                            {data.description}
                          </div>
                        </div>
                      </div>
                      {authenticationMode.name === data.name ? (
                        <CircleWithTickIcon
                          circleColor={"white"}
                          circleOutline={"#00a000"}
                          tickColor={"#00a000"}
                        />
                      ) : (
                        <div className="rotate-90">
                          <img src={arrow} alt="arrow" />
                        </div>
                      )}
                    </div>
                  )
              )}
            </div>
          </footer>
        </Modal>
        {/* Modal for abha login types */}
        <Modal
          show={showLoginModes}
          onClose={() => {
            setShowLoginModes(false);
            props.setAuthModes(null);
          }}
          canIgnore={true}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-fit-to-content"
        >
          <div
            className="inherit-parent-width inherit-parent-height "
            style={{ flexBasis: "1" }}
          />
          <footer
            data-cy="login-mode-select-modal"
            className="inherit-parent-width background-white"
          >
            <div className="padding-large">
              <div className="text-align-center padding-bottom-default font-size-smaller">
                CHOOSE HOW YOU WANT TO LOGIN
              </div>
              {Object.values(authTypes).map(
                (data, index) =>
                  props.patients.authModes &&
                  props.patients.authModes.includes(data.value) && (
                    <div
                      key={index}
                      data-cy={`login-mode-${data["data-cy"]}`}
                      className="inherit-parent-width display-flex flex-justify-content-space-between flex-center-children-vertically padding-bottom-large"
                      onClick={() => {
                        setAuthType(data.name);
                        setShowConnectModal({
                          value: false,
                          type: data.name
                        });
                        setCounter(60);
                        props.sendOtpForAbdmAuthentication(
                          authMode,
                          data.name,
                          authenticationMode.name,
                          authenticationMode.name === "ABHA Number"
                            ? getValidAbhaNumber(
                                loginFormData[authenticationMode.value]
                              )
                            : loginFormData[authenticationMode.value]
                        );
                        setShowLoginModes(!showLoginModes);
                      }}
                    >
                      <div className="display-flex flex-direction-row">
                        <div className="inherit-parent-width padding-default">
                          <div className="font-family-gilroy-regular padding-bottom-smaller">
                            {data.name}
                          </div>
                          <div className="font-size-smaller">
                            {data.description}
                          </div>
                        </div>
                      </div>
                      <div className="rotate-90">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  )
              )}
            </div>
          </footer>
        </Modal>
        {/* Modal for login types */}
        <Modal
          show={
            showConnectModal.value &&
            (authMode === "login" || (authMode === "register" && authType)) &&
            typeof props.patients.transactionId === "string"
              ? true
              : false
          }
          onClose={() => {
            setShowConnectModal({
              ...showConnectModal,
              value: false
            });
            setShowLoginModes(false);
            props.setAuthModes(null);
          }}
          canIgnore={true}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-fit-to-content"
        >
          <div
            className="inherit-parent-width inherit-parent-height "
            style={{ flexBasis: "1" }}
          />
          <footer className="inherit-parent-width background-white">
            <div className="padding-large">
              <div className="text-align-center padding-bottom-default font-size-smaller">
                {showConnectModal.type === "Password" && "ENTER YOUR PASSWORD"}
              </div>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  props.verifyAbdmAuthenticationOtp(
                    authMode,
                    authenticationMode.name,
                    loginFormData.otp
                      ? loginFormData.otp
                      : loginFormData.password,
                    props.navigate,
                    loginFormData.password ? true : false,
                    loginFormData.healthId
                  );
                }}
              >
                {showConnectModal.type === "Password" && (
                  <InputBox
                    className="inherit-parent-width border-radius-default margin-bottom-default"
                    name="password"
                    label="Password"
                    type="password"
                    otpFocus={true}
                    autoFocus={true}
                    value={loginFormData.password}
                    onChange={(event) => {
                      setLoginFormData({
                        ...loginFormData,
                        password: event.target.value
                      });
                    }}
                    validation={(value) => validation("password", value)}
                    required
                    disabled={props.patients.loading}
                  />
                )}
                {(showConnectModal.type === "Phone" ||
                  showConnectModal.type === "Aadhar" ||
                  showConnectModal.type === "Email") &&
                  props.patients.transactionId &&
                  typeof props.patients.transactionId === "string" && (
                    <>
                      <OTPInputBox
                        name="otp"
                        autoFocus={true}
                        value={loginFormData.otp}
                        setValue={(otpValue) => {
                          setLoginFormData({
                            ...loginFormData,
                            otp: otpValue
                          });
                        }}
                        required
                        className="margin-bottom-large"
                      />
                      <div className="display-flex flex-justify-content-space-between font-size-medium margin-bottom-larger">
                        {counter &&
                        counter !== 0 &&
                        typeof counter === "number" ? (
                          <div>
                            {`${
                              counter?.toString().length === 1 ? "0" : ""
                            }${counter?.toString()} seconds`}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div
                          className={`${
                            counter
                              ? "font-color-tertiary"
                              : "font-color-primary"
                          }`}
                          onClick={() => {
                            if (counter && typeof counter === "number") {
                              return;
                            } else {
                              setCounter(60);
                              props.sendOtpForAbdmAuthentication(
                                authMode,
                                authType,
                                authenticationMode.name,
                                authenticationMode.name === "ABHA Number"
                                  ? getValidAbhaNumber(
                                      loginFormData[authenticationMode.value]
                                    )
                                  : loginFormData[authenticationMode.value]
                              );
                            }
                          }}
                        >
                          Resend OTP
                        </div>
                      </div>
                    </>
                  )}
                <Button
                  text="Login"
                  boxShadow={false}
                  required
                  type="submit"
                  className="margin-top-default margin-bottom-medium"
                  disabled={
                    Regex.password?.test(loginFormData.password)
                      ? false
                      : loginFormData.otp.length === 6 &&
                        props.patients.transactionId
                      ? false
                      : true
                  }
                  loading={props.patients.loading}
                />
              </form>
            </div>
          </footer>
        </Modal>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  abdm: state.abdm,
  downloads: state.downloads,
  auth: state.auth,
  patients: state.patients
});

const mapDispatchToProps = function () {
  return {
    createHealthId: (data, requestMethod, push) =>
      createHealthId(data, requestMethod, push),
    checkHealthIdExists: (healthId) => checkHealthIdExists(healthId),
    sendOtpForAbdmAuthentication: (authMode, authType, method, value) =>
      sendOtpForAbdmAuthentication(authMode, authType, method, value),
    getModesForAbdmAuth: (auth, method, value, yearOfBirth) =>
      getModesForAbdmAuth(auth, method, value, yearOfBirth),
    verifyAbdmAuthenticationOtp: (
      auth,
      method,
      otp,
      push,
      skipHealthInfo,
      healthId
    ) =>
      verifyAbdmAuthenticationOtp(
        auth,
        method,
        otp,
        push,
        skipHealthInfo,
        healthId
      ),
    clearAbdmAuthCredentials: () => clearAbdmAuthCredentials(),
    loginWithHealthId: (method, healthId, push) =>
      loginWithHealthId(method, healthId, push),
    clearHealthIdExistStatus: () => clearHealthIdExistsStatus(),
    logoutAbdm: (patientId) => logoutHealthId(patientId),
    getPatientsRequests: () => getPatientsRequests(),
    clearUnusedHealthId: () => clearUnusedHealthId(),
    setAuthModes: () => setAuthModes(),
    setTransactionId: (transactionId) => setTransactionId(transactionId),
    registerNewAbhaNumber: (data) => registerNewAbhaNumber(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AbdmAuthentication);

const CreateHealthId = ({
  loading,
  patient,
  step,
  setStep,
  abhaNumberData,
  createHealthIdOnSubmit,
  isHealthIdExists,
  userName,
  authMethod,
  checkHealthIdExists,
  clearHealthIdExistStatus
}) => {
  const [formData, setFormData] = useState({
    healthId: typeof userName === "string" ? userName : "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    gender: "",
    stateCode: "",
    pinCode: "",
    address: "",
    districtCode: "",
    password: "",
    phoneNumber:
      typeof patient?.phoneNumber === "string" ? patient?.phoneNumber : "",
    prevHealthId: null,
    patientId:
      typeof patient?.documentId === "string" ? patient?.documentId : "",
    agreeTo: false
  });
  const [error, setError] = useState(null);

  // check healthId exists
  useEffect(() => {
    healthIdExistCheckHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (abhaNumberData) {
      setFormData({
        ...formData,
        firstName: abhaNumberData?.name?.first
          ? abhaNumberData?.name?.first
          : "",
        middleName: abhaNumberData?.name?.middle
          ? abhaNumberData?.name?.middle
          : "",
        lastName: abhaNumberData?.name.last ? abhaNumberData?.name.last : "",
        dateOfBirth: abhaNumberData?.dateOfBirth
          ? getValidDateOfBirth(abhaNumberData?.dateOfBirth)
          : "",
        email: abhaNumberData?.email ? abhaNumberData?.email : "",
        gender: abhaNumberData?.gender
          ? getValidGender(abhaNumberData?.gender)
          : "",
        stateCode: abhaNumberData?.stateCode ? abhaNumberData?.stateCode : "",
        pinCode: abhaNumberData?.pincode ? abhaNumberData?.pincode : "",
        address: abhaNumberData?.address ? abhaNumberData?.address : "",
        districtCode: abhaNumberData?.districtCode
          ? abhaNumberData?.districtCode
          : ""
      });
    }
    // eslint-disable-next-line
  }, [abhaNumberData]);

  // validate the formData
  useEffect(() => {
    let errorCount = 0;
    for (const [key, value] of Object.entries(formData)) {
      if (key === "email" || key === "phoneNumber") {
        continue;
      }

      if (!["prevHealthId"].includes(key)) {
        if (
          key === "healthId"
            ? Regex.healthId.test(value) === false || isHealthIdExists
            : false
        ) {
          errorCount++;
        }
        const validate = validation(key, value);
        if (validate.status === false) {
          errorCount++;
        }
      }
    }
    if (errorCount > 0) {
      setError(true);
    } else {
      setError(false);
    }
  }, [formData, isHealthIdExists]);

  const healthIdExistCheckHandler = () => {
    if (
      formData.healthId.trim().length < 4 ||
      formData.healthId.trim().length > 14 ||
      Regex.healthId.test(formData.healthId) === false ||
      loading ||
      formData.prevHealthId === formData.healthId
    )
      return checkHealthIdExists(null);
    setFormData({ ...formData, prevHealthId: formData.healthId });
    checkHealthIdExists(formData.healthId);
  };
  const disableContinueButton = () => {
    if (
      formData.firstName &&
      formData.dateOfBirth &&
      formData.gender &&
      formData.stateCode &&
      formData.pinCode &&
      formData?.pinCode.length === 6 &&
      formData.address &&
      formData.districtCode &&
      formData.agreeTo
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      {step === "initial" && (
        <form
          className="padding-horizontal-larger inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between"
          onChange={(event) => {
            /** clear healthIdExists status in redux store */
            if (
              event.target.name === "healthId" &&
              typeof isHealthIdExists === "boolean" &&
              typeof clearHealthIdExistStatus === "function"
            ) {
              clearHealthIdExistStatus();
            }

            if (loading === true && event.target.name === "healthId") {
              return;
            }
            if (event.target.name === "agreeTo") {
              setFormData({
                ...formData,
                [event.target.name]: event.target.checked
              });
            } else {
              setFormData({
                ...formData,
                [event.target.name]: event.target.value
              });
            }
          }}
          onSubmit={() => {
            setStep("second");
          }}
        >
          <div>
            <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary padding-top-larger padding-bottom-large">
              CREATE ABHA ADDRESS
            </div>
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default padding-top-medium"
              label="First Name *"
              type="text"
              required
              name="firstName"
              value={formData.firstName}
              disabled={abhaNumberData?.name?.first ? true : false}
            />
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default padding-top-medium"
              label="Middle Name"
              type="text"
              name="middleName"
              value={formData.middleName}
              disabled={abhaNumberData?.name?.middle ? true : false}
            />
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default padding-top-medium"
              label="Last Name"
              type="text"
              disabled={abhaNumberData?.name?.last ? true : false}
              name="lastName"
              value={formData.lastName}
            />
            {!(authMethod === "email") && (
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default padding-top-medium"
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                disabled={abhaNumberData?.email ? true : false}
                validation={(value) => validation("email", value)}
              />
            )}
            {!(authMethod === "phoneNumber") && (
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default padding-top-medium"
                label="Phone Number"
                type="tel"
                name="phoneNumber"
                disabled={true}
                value={formData.phoneNumber}
                validation={(value) => validation("phoneNumber", value)}
              />
            )}
            <TextArea
              type="text"
              className="padding-top-default"
              value={formData.address}
              name="address"
              label="Address *"
              data-cy="address"
              required
              disabled={abhaNumberData?.address ? true : false}
              category="textarea"
            />
            <div className="display-flex flex-center-children-vertically padding-top-medium">
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default padding-top-medium padding-right-default"
                label="Date Of Birth *"
                type="date"
                required
                size={"half"}
                name="dateOfBirth"
                disabled={abhaNumberData?.dateOfBirth?.year ? true : false}
                value={formData.dateOfBirth}
              />
              <NativeSelect
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large padding-left-default"
                optionsClassName="text-transform-capitalize"
                name="gender"
                label="Gender *"
                required
                size="half"
                data-cy="gender"
                disabled={abhaNumberData?.gender ? true : false}
                defaultValue={formData.gender}
                options={["", "male", "female", "others"]}
              />
            </div>
            <div className="display-flex flex-center-children-vertically padding-top-large">
              <NativeSelect
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large padding-right-default"
                optionsClassName="text-transform-capitalize"
                name="stateCode"
                label="State *"
                required
                size="half"
                data-cy="stateCode"
                disabled={abhaNumberData?.stateCode ? true : false}
                defaultValue={formData.stateCode}
                options={stateOptionsWithCode}
              />
              <NativeSelect
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large padding-left-default"
                optionsClassName="text-transform-capitalize"
                name="districtCode"
                label="District *"
                required
                size="half"
                data-cy="districtCode"
                disabled={abhaNumberData?.districtCode ? true : false}
                defaultValue={formData.districtCode}
                options={stateCodeWithDistricts[formData.stateCode]}
              />
            </div>
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default padding-top-larger"
              label="Pincode *"
              type="tel"
              required
              name="pinCode"
              disabled={abhaNumberData?.pincode ? true : false}
              value={formData.pinCode}
              validation={(value) => validation("pinCode", value)}
            />
            <div className="inherit-parent-width display-flex padding-top-large text-align-left font-size-small font-family-gilroy-medium margin-top-small">
              <CheckBox
                name="agreeTo"
                label="I am willing to share profile details with NHA for creating ABHA
                address"
              />
            </div>
          </div>
          <div className="padding-bottom-larger">
            <Button
              text="Continue"
              data-cy="continue-button"
              boxShadow={false}
              className={"margin-top-large "}
              type="submit"
              disabled={disableContinueButton()}
              loading={loading}
            />
          </div>
        </form>
      )}
      {step === "second" && (
        <form
          className="padding-horizontal-larger margin-bottom-larger"
          onChange={(event) => {
            /** clear healthIdExists status in redux store */
            if (
              event.target.name === "healthId" &&
              typeof isHealthIdExists === "boolean" &&
              typeof clearHealthIdExistStatus === "function"
            ) {
              clearHealthIdExistStatus();
            }
            if (loading === true && event.target.name === "healthId") return;
            setFormData({
              ...formData,
              [event.target.name]: event.target.value
            });
          }}
          onSubmit={(event) => {
            event.preventDefault();
            let preSubmitData = { ...formData };
            preSubmitData.dateOfBirth = +new Date(formData.dateOfBirth);
            preSubmitData.healthId = formData.healthId + "@sbx";
            createHealthIdOnSubmit(preSubmitData);
          }}
        >
          <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary padding-top-larger padding-bottom-large">
            CREATE ABHA
          </div>
          <InputBox
            inputSuffixPlaceHolder={"@sbx"}
            labelClassName="letter-spacing-4-percentage"
            className="inherit-parent-width border-radius-default padding-top-large"
            label="ABHA Address *"
            type="text"
            name="healthId"
            autoFocus={true}
            value={formData.healthId}
            loading={loading}
            inputNotesClassName="font-color-primary font-size-smaller padding-top-small"
            notes={
              isHealthIdExists === false ? "This address is available!" : ""
            }
            validation={() => {
              if (Regex.healthId.test(formData.healthId) === false) {
                return {
                  status: false,
                  message:
                    "Id should starts and end with alphabet only. Allowed characters a-z A-Z 0-9"
                };
              } else if (isHealthIdExists === true) {
                return {
                  status: false,
                  message: "This address is taken! Please try another address"
                };
              } else {
                return {
                  status: true
                };
              }
            }}
            onOutOfFocus={(event) => {
              healthIdExistCheckHandler(event);
            }}
          />
          <InputBox
            className="inherit-parent-width border-radius-default margin-bottom-default"
            name="password"
            label="Password *"
            type="password"
            value={formData.password}
            validation={(value) => validation("password", value)}
            required
          />
          <InputBox
            className="inherit-parent-width border-radius-default margin-bottom-default"
            name="confirmPassword"
            label="Confirm Password *"
            type="password"
            validation={(value) => {
              if (!(value === formData.password)) {
                return {
                  status: false,
                  message: "Password does not match"
                };
              }
            }}
            value={formData.confirmPassword}
            required
          />
          <Button
            text="Create ABHA address"
            data-cy="continue-button"
            boxShadow={false}
            className="margin-top-large margin-bottom-larger"
            required
            type="submit"
            disabled={
              formData.password === formData.confirmPassword && !error
                ? false
                : true
            }
            loading={loading}
          />
        </form>
      )}
    </>
  );
};

const HealthIdBenefitsTile = ({ src, alt, title, description }) => {
  return (
    <section className="display-flex flex-align-items-center padding-vertical-large">
      <div className="padding-medium border-radius-default display-flex flex-align-items-center">
        <img src={src} alt={alt} className="health-id-benefits-icons-size" />
      </div>
      <div className="padding-left-large">
        <div className="font-family-gilroy-regular font-size-medium font-color-secondary padding-bottom-small ">
          {title}
        </div>
        <div className="font-family-gilroy-regular font-size-small font-color-secondary ">
          {description}
        </div>
      </div>
    </section>
  );
};

const AbhaLinkComponent = ({
  patients,
  abdm,
  loading,
  profilePicture,
  profileData,
  profilePictureBackground,
  createAbhaIdOnClick,
  onClickLinkHealthId,
  onClickCreateNewHealthId
}) => {
  const [selectedAbhaId, setSelectedAbhaId] = useState("");
  const alreadyExistedPHR = true;
  return (
    <div className="padding-left-larger padding-right-larger">
      <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary padding-top-large padding-bottom-large">
        LINK YOUR ABHA ADDRESSES WITH YOUR NINTO PROFILE
      </div>
      <section className="display-flex flex-align-items-center padding-default background-color-secondary border-radius-default">
        <RoundedProfilePicture
          data-cy={""}
          background={profilePictureBackground}
          displayName={profileData?.name}
          size={"medium"}
          src={profilePicture}
          alt=""
          loading={loading}
        />
        <div className="padding-left-medium font-color-secondary text-transform-capitalize font-family-gilroy-regular ">
          <div className="padding-bottom-small font-size-medium ">
            {profileData?.name}
          </div>
          <div className="font-size-small">{profileData?.phoneNumber}</div>
        </div>
      </section>
      {patients.unusedHealthId && isValidArray(patients.unusedHealthId) ? (
        <div className="padding-bottom-medium">
          <div className="line-height-140-percentage text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary padding-top-larger  ">
            SELECT THE ABHA ADDRESSES YOU WOULD LIKE TO LINK TO THE ABOVE
            PROFILE
          </div>

          <form
            onChange={(event) => {
              if (selectedAbhaId === event.target.name) {
                setSelectedAbhaId("");
              } else {
                setSelectedAbhaId(event.target.name);
              }
            }}
          >
            {(() => {
              const HealthIds = [];
              patients.unusedHealthId &&
                patients.unusedHealthId.forEach((healthId) => {
                  HealthIds.push(
                    <div
                      key={healthId}
                      className="display-flex flex-justify-content-space-between margin-top-large"
                    >
                      <span
                        className="font-family-gilroy-regular font-size-medium font-color-secondary "
                        onClick={() => {
                          if (selectedAbhaId === healthId) {
                            setSelectedAbhaId("");
                          } else {
                            setSelectedAbhaId(healthId);
                          }
                        }}
                      >
                        {healthId}
                      </span>
                      <CheckBox
                        id={healthId}
                        name={healthId}
                        label=""
                        checked={selectedAbhaId === healthId ? true : false}
                      />
                    </div>
                  );
                });
              return HealthIds;
            })()}
          </form>
        </div>
      ) : (
        <div>
          <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary padding-top-larger ">
            YOU DO NOT HAVE AN ABHA ADDRESS THEY ARE ALREADY LINKED WITH ANOTHER
            PROFILE
          </div>
          <Button
            text="Create new ABHA Address"
            className="margin-vertical-larger"
            boxShadow={false}
            required
            onClick={() => {
              onClickCreateNewHealthId();
            }}
          />
        </div>
      )}

      {patients.usedHealthId && isValidArray(patients.usedHealthId) && (
        <div>
          <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary  padding-top-larger ">
            ABHA ADDRESS WHICH ARE ALREADY LINKED
          </div>
          {patients.usedHealthId.map((healthId, index) => (
            <div
              key={index}
              className="display-flex flex-justify-content-space-between margin-top-large font-color-secondary font-family-gilroy-medium"
            >
              <div>
                <div>
                  {patients?.profiles &&
                    Object.values(patients.profiles).find(
                      (demographic) => demographic?.healthId === healthId
                    ).name}
                </div>

                <div className="font-size-small padding-top-default">
                  {healthId}
                </div>
              </div>
              <div className="flex-center-children-vertically">
                <TickIcon />
              </div>
            </div>
          ))}
        </div>
      )}
      {/* {patients.unusedHealthId && isValidArray(patients.unusedHealthId) && ( */}
      <Button
        text="Continue with selected Id"
        className="margin-vertical-larger"
        disabled={selectedAbhaId.trim().length > 0 ? false : true}
        boxShadow={false}
        required
        onClick={() => {
          onClickLinkHealthId(selectedAbhaId, alreadyExistedPHR);
        }}
        loading={loading}
      />
      {/* )} */}
      {/* {patients.unusedHealthId &&
        isValidArray(patients.unusedHealthId) &&
        !selectedAbhaId && (
          <Button
            text="Create new"
            // disabled={selectedAbhaId.trim().length > 0 ? false : true}
            boxShadow={false}
            variant="secondary"
            required
            onClick={() => {
              onClickCreateHealthId;
            }}
            // loading={loading}
          />
        )} */}
      <ProtectedRender condition={!!patients.transactionId}>
        <div
          className="text-align-center font-family-gilroy-medium font-size-small font-color-primary padding-bottom-larger"
          onClick={() => {
            if (typeof createAbhaIdOnClick === "function") {
              createAbhaIdOnClick();
            }
          }}
        >
          Create new ABHA address
        </div>
      </ProtectedRender>
    </div>
  );
};

const HealthIdBenefitsArray = [
  {
    src: uniqueIdentityIcon,
    alt: "",
    title: "Unique identity",
    desc: "Unique identity across different healthcare providers within the healthcare ecosystem"
  },
  {
    src: unificationIcon,
    alt: "",
    title: "Unification",
    desc: "Link all healthcare benefits to your unique ABHA number"
  },
  {
    src: accessibleIcon,
    alt: "",
    title: "Accessible",
    desc: "Avoid spending time & energy for registration in healthcare facilities across the country"
  },
  {
    src: phrIcon,
    alt: "",
    title: "Easy PHR",
    desc: "Seamless Personal Health Records connection such as Ninto for Health data sharing"
  }
];

const ProtectedRender = (props) => {
  return props.condition === true ? props.children : "";
};

const HealthIdAuthComponent = ({ loginOnClick, createOnClick }) => {
  return (
    <>
      <section className="display-flex flex-justify-content-center padding-top-larger">
        <div
          className="cursor-pointer display-flex flex-direction-column flex-align-items-center width-fit-content padding-horizontal-large border-1px-solid-f1f1f1 border-radius-default margin-right-larger"
          data-cy="healthId-login"
          onClick={() => {
            loginOnClick();
          }}
        >
          <div className="display-flex flex-align-items-center padding-top-larger padding-bottom-small ">
            <span className="font-size-medium font-family-gilroy-medium font-color-primary  padding-right-default">
              Login
            </span>
            <img src={nextIconGreen} alt="" height="10" width="6" />
          </div>
          <div className="font-size-small font-family-gilroy-regular font-color-secondary padding-bottom-larger  padding-left-small padding-right-small">
            Existing ABHA Address
          </div>
        </div>

        <div
          className="cursor-pointer display-flex flex-direction-column flex-align-items-center width-fit-content padding-bottom-small  padding-horizontal-large border-1px-solid-f1f1f1 border-radius-default"
          data-cy="healthId-register"
          onClick={() => {
            createOnClick();
          }}
        >
          <div className=" display-flex flex-align-items-center padding-top-larger padding-bottom-small ">
            <span className="font-size-medium font-family-gilroy-medium font-color-primary padding-right-default">
              Create
            </span>
            <img src={nextIconGreen} alt="" height="10" width="6" />
          </div>
          <div className="font-size-small font-family-gilroy-regular font-color-secondary padding-bottom-larger  padding-left-default padding-right-default">
            New ABHA Address
          </div>
        </div>
      </section>
      <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary  padding-top-larger margin-top-larger">
        ABHA Address - benefits
      </div>
      {(() => {
        const HealthIdBenefits = [];
        HealthIdBenefitsArray.forEach((tile) => {
          HealthIdBenefits.push(
            <React.Fragment key={tile.title}>
              <HealthIdBenefitsTile
                src={tile.src}
                alt={tile.alt}
                title={tile.title}
                description={tile.desc}
              />
            </React.Fragment>
          );
        });
        return HealthIdBenefits;
      })()}
    </>
  );
};

const authModes = {
  phone: {
    name: "Phone",
    value: "phoneNumber",
    logo: phone,
    "data-cy": "auth-mode-phoneNumber",
    description: "LOGIN VIA PHONE NO. CONNECTED WITH ABHA"
  },
  abhaAddress: {
    name: "ABHA Address",
    value: "healthId",
    logo: at,
    "data-cy": "auth-mode-healthId",
    description: "LOGIN VIA ABHA ADDRESS CONNECTED WITH YOUR PROFILE"
  },
  abhaNumber: {
    name: "ABHA Number",
    value: "abhaNumber",
    logo: number,
    "data-cy": "auth-mode-abhaNumber",
    description: "LOGIN VIA ABHA NO. CONNECTED WITH ABHA"
  },
  mail: {
    name: "Email",
    value: "email",
    logo: mail,
    "data-cy": "auth-mode-email",
    description: "LOGIN VIA MAIL ID CONNECTED WITH ABHA"
  }
};

const authTypes = {
  phone: {
    name: "Phone",
    value: "MOBILE_OTP",
    "data-cy": "auth-type-mobile",
    description: "Enter your OTP which is sent to your registered Phone number"
  },
  password: {
    name: "Password",
    value: "PASSWORD",
    "data-cy": "auth-type-password",
    description: "Enter your password to continue using ABHA address"
  },
  aadhar: {
    name: "Aadhar",
    value: "AADHAAR_OTP",
    "data-cy": "auth-type-aadhar",
    description: "Enter your OTP which is sent to your registered Aadhar number"
  },
  email: {
    name: "Email",
    value: "EMAIL_OTP",
    "data-cy": "auth-type-email",
    description: "Enter your OTP which is sent to your registered Email ID"
  }
};

const AuthModeSwitch = ({ authMode, onClick }) => {
  return (
    <div
      className="cursor-pointer font-size-small text-align-center margin-top-larger padding-bottom-large"
      onClick={() => {
        onClick(authMode === "login" ? "register" : "login");
      }}
    >
      <span className="font-family-gilroy-regular font-color-secondary padding-right-smaller">
        {authMode === "login"
          ? "Don’t have a ABHA address?"
          : "Already have a ABHA address? "}
      </span>
      <span className="font-family-gilroy-medium font-color-primary">
        {authMode === "login" ? "Create new" : "Login"}
      </span>
    </div>
  );
};

const AbdmComponent = ({
  consentsOnClick,
  logoutOnClick,
  consentsCount,
  changeConsentPinOnClick,
  abdmHealthIdQrOnClick
}) => {
  return (
    <div className="padding-left-larger padding-right-larger">
      <div className="display-flex flex-grow-1 flex-align-items-center margin-top-large padding-top-large ">
        <div
          className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
          onClick={abdmHealthIdQrOnClick}
        >
          <span className="font-size-medium font-family-gilroy-regular">
            ABHA address
          </span>
          <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
            View and share your ABHA address
          </span>
        </div>
        <div className="display-flex">
          <img
            src={nextIcon}
            alt=""
            className="margin-left-large height-width-16px "
          />
        </div>
      </div>

      <div className="display-flex flex-grow-1 flex-align-items-center margin-top-large margin-bottom-large padding-vertical-large ">
        <div
          className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
          onClick={() => {
            changeConsentPinOnClick();
          }}
        >
          <span className="font-size-medium font-family-gilroy-regular">
            Change consent PIN
          </span>
          <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
            Required to approve requests to share health data
          </span>
        </div>
        <img
          src={nextIcon}
          alt=""
          className="margin-left-large height-width-16px "
        />
      </div>

      <div className="display-flex flex-grow-1 flex-align-items-center margin-top-large">
        <div
          className="flex-grow-1 flex-direction-column display-flex font-color-red"
          onClick={() => {
            logoutOnClick();
          }}
        >
          <span className="font-size-medium font-family-gilroy-regular">
            Logout
          </span>
          <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
            Logout from Ninto
          </span>
        </div>
        <img src={logoutIcon} alt="" className="height-width-16px" />
      </div>
    </div>
  );
};

const AuthModeSwitcher = (props) => {
  return (
    <div
      className="inherit-parent-width padding-top-large margin-bottom-larger display-flex flex-justify-content-space-between flex-center-children-vertically border-bottom-1px-e5e5e5"
      data-cy="abdm-auth-switcher"
      onClick={props.disable ? () => {} : props.downArrowOnClick}
    >
      <div
        data-cy={`login-auth-mode-${props.data.value}`}
        className="padding-left-large padding-right-default display-flex flex-direction-row"
      >
        <img className="padding-right-default" src={props.data.logo} alt="" />
        <div className="inherit-parent-width padding-default">
          <div className="font-family-gilroy-regular padding-bottom-smaller">
            {props.data.name}
          </div>
          <div className="font-size-smaller">{props.data.description}</div>
        </div>
      </div>
      {!props.disable && (
        <div className="padding-right-large">
          <img src={arrow} alt="arrow" />
        </div>
      )}
    </div>
  );
};
