import React, { useRef, useState, useEffect } from "react";
import backIcon from "../../assets/Back.svg";
import ninto_logo from "../../assets/ninto_logo_with_name.svg";
import ninto_doctor_logo from "../../assets/ninto-doctor-logo.png";
import supportIcon from "../../assets/Support.svg";
// import QrIcon from "../../assets/Qr_scan_icon.svg";
import QrIcon1 from "../../assets/QrScanIcon.svg";
import ninto_corporate_logo from "../../assets/ninto_corporate_logo.svg";
import RoundedProfilePicture from "../RoundedProfilePicture/RoundedProfilePicture";
import searchIcon from "../../assets/Search.svg";
import closeIcon from "../../assets/close.svg";
import shareIcon from "../../assets/shareIcon.svg";
import { isValidObject } from "../../services/validators";
import "../../design-system.css";
import "./Header.css";
import PropTypes from "prop-types";
import { LogoutIcon } from "../../assets/assets";

function Header(props) {
  const [isSearchOn, setIsSearchOn] = useState(false);
  const stepperHeaderRef = useRef(null);
  // to set the search status
  useEffect(() => {
    if (typeof props.searchKeyword === "string") {
      setIsSearchOn(true);
    } else {
      setIsSearchOn(false);
    }
  }, [props.searchKeyword]);
  // set initial search status
  useEffect(() => {
    if (typeof props.onSearchOn === "function") {
      props.onSearchOn(isSearchOn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchOn]);

  useEffect(() => {
    setIsSearchOn(props.isSearchOn);
  }, [props.isSearchOn]);

  const stepperWidth = () => {
    const singleStepperValue = stepperHeaderRef.current
      ? stepperHeaderRef.current.offsetWidth / props.maxStepperCount
      : 0;
    return singleStepperValue * props.currentStepperValue;
  };

  return (
    <>
      {props.primary === true ? (
        <header
          id="primary-header"
          className={`${
            props.backGroundColor
              ? props.backGroundColor
              : "background-color-secondary"
          } ${
            props.removeResponsive ? "" : "adaptive-header-height"
          } header-height margin-vertical-auto flex-align-items-center padding-horizontal-medium flex-justify-content-space-between right-0 left-0 z-index-1`}
        >
          <HeaderPrefixSection
            inputType={props.inputType}
            searchType={props.searchType}
            searchPlaceHolder={props.searchPlaceHolder}
            searchPrefix={props.searchPrefix}
            userType={props.userType}
            isSearchOn={isSearchOn}
            setIsSearchOn={setIsSearchOn}
            onSearchUser={props.onSearchUser}
            searchResultElementRef={props.searchResultElementRef}
            searchResults={props.searchResults}
            removeSearchResults={props.removeSearchResults}
            searchKeyword={props.searchKeyword}
            clinicNameAndLocality={props.clinicNameAndLocality}
          />
          <HeaderSuffixSection
            displayName={props.displayName}
            isSearchOn={isSearchOn}
            showSearch={props.showSearch}
            loading={props.loading}
            setIsSearchOn={setIsSearchOn}
            hideSupport={props.hideSupport}
            showQr={props.showQr}
            supportIconOnClick={props.supportIconOnClick}
            onQrClick={props.onQrClick}
            notificationsCount={props.notificationsCount}
            onProfilePictureClick={props.onProfilePictureClick}
            userProfilePictureSrc={props.userProfilePictureSrc}
            profile-picture-data-cy={props["profile-picture-data-cy"]}
            background={props.background}
            onSearchIconClick={props.onSearchIconClick}
          />
        </header>
      ) : typeof props.maxStepperCount === "number" ? (
        <header
          ref={stepperHeaderRef}
          className={` ${
            props.backGroundColor
              ? props.backGroundColor
              : "background-color-secondary"
          } ${
            props.removeResponsive ? "" : "adaptive-header-height"
          } header-height right-0 left-0  z-index-1`}
        >
          <div
            className={`${
              props.backGroundColor
                ? props.backGroundColor
                : "background-color-secondary"
            } display-flex flex-align-items-center flex-justify-content-space-between inherit-parent-height`}
          >
            {props.hideBackButton !== true ? (
              <img
                src={backIcon}
                alt="back-arrow"
                width="auto"
                onClick={props.backButtonOnClick}
                className={`cursor-pointer margin-horizontal-medium padding-top-small ${
                  props.removeResponsive
                    ? ""
                    : "adaptive-back-arrow-row-icon-size"
                }  back-arrow-row-icon-size`}
                data-cy="secondary-header-back-arrow"
              />
            ) : (
              <div
                className={`${
                  props.removeResponsive ? "" : "adaptive-header-logo-height"
                } header-logo-height`}
              />
            )}
            <div
              className={`${
                props.removeResponsive ? "" : "adaptive-header-logo-height"
              } ${
                props.headerPositionLeft ? "position-absolute" : ""
              } header-logo-height padding-left-larger`}
            >
              <img
                data-cy="header-logo"
                src={
                  props.userType === "doctor"
                    ? ninto_doctor_logo
                    : props.userType === "corporate"
                    ? ninto_corporate_logo
                    : ninto_logo
                }
                alt="header-logo"
                width="auto"
                className={`${
                  props.removeResponsive ? "" : "adaptive-header-logo-height"
                } header-logo-height`}
              />
            </div>

            {!props.hideSupport ||
            props.headerSuffixElement ||
            props.showLogoutIcon ? (
              <div className="display-flex flex-align-items-center margin-default">
                {props.showLogoutIcon === true && (
                  <div
                    data-cy="logout-icon"
                    className={`cursor-pointer margin-right-larger ${
                      props.removeResponsive
                        ? ""
                        : "adaptive-support-icon-height"
                    } support-icon-height `}
                    onClick={props.logoutIconOnClick}
                  >
                    <LogoutIcon height="100%" width="100%" color="#4E4E4E" />
                  </div>
                )}
                {props.hideSupport === true ? null : (
                  <img
                    data-cy="support-icon"
                    className={`cursor-pointer margin-right-medium ${
                      props.removeResponsive
                        ? ""
                        : "adaptive-support-icon-height"
                    } support-icon-height`}
                    src={supportIcon}
                    alt="support-icon"
                    width="auto"
                    onClick={() => {
                      if (typeof props.supportIconOnClick === "function") {
                        props.supportIconOnClick();
                      }
                    }}
                  />
                )}
                {props.headerSuffixElement && props.headerSuffixElement()}
              </div>
            ) : (
              <div
                className={`cursor-pointer margin-horizontal-medium padding-top-small ${
                  props.removeResponsive
                    ? ""
                    : "adaptive-back-arrow-row-icon-size"
                }  back-arrow-row-icon-size`}
                data-cy="place-holder-for-centering-img"
              />
            )}
          </div>
          <div
            className="stepper-progress-anime"
            style={{
              width: `${stepperWidth()}px`
            }}
          />
        </header>
      ) : (
        <header
          className={`${
            props.backGroundColor
              ? props.backGroundColor
              : "background-color-secondary"
          } ${
            props.removeResponsive ? "" : "adaptive-header-height"
          } header-height display-flex flex-align-items-center flex-justify-content-space-between right-0 left-0 z-index-1`}
        >
          <div className="display-flex flex-align-items-center">
            <img
              src={backIcon}
              alt="back-arrow"
              width="auto"
              onClick={props.backButtonOnClick}
              className={`cursor-pointer margin-horizontal-medium padding-top-small ${
                props.removeResponsive
                  ? ""
                  : "adaptive-back-arrow-row-icon-size"
              } back-arrow-row-icon-size`}
              data-cy="secondary-header-back-arrow"
            />
            <span className="font-size-medium font-family-gilroy-medium font-color-secondary text-transform-capitalize ">
              {props.title}
            </span>
          </div>
          <div className="display-flex flex-align-items-center margin-default">
            {props.showShare === true && (
              <img
                data-cy="share-icon"
                className={`cursor-pointer margin-right-medium ${
                  props.removeResponsive ? "" : "adaptive-support-icon-height"
                } support-icon-height `}
                src={shareIcon}
                alt="share-icon"
                width="auto"
                onClick={() => {
                  if (typeof props.shareIconOnClick === "function") {
                    props.shareIconOnClick();
                  }
                }}
              />
            )}
            {props.showLogoutIcon === true && (
              <div
                data-cy="logout-icon"
                className={`cursor-pointer margin-right-medium ${
                  props.removeResponsive ? "" : "adaptive-support-icon-height"
                } support-icon-height `}
                onClick={props.logoutIconOnClick}
              >
                <LogoutIcon eight="100%" width="100%" color="#4E4E4E" />
              </div>
            )}
            {props.hideSupport === true ? null : (
              <img
                data-cy="support-icon"
                className={`cursor-pointer margin-right-medium ${
                  props.removeResponsive ? "" : "adaptive-support-icon-height"
                } support-icon-height `}
                src={supportIcon}
                alt="support-icon"
                width="auto"
                onClick={props.supportIconOnClick}
              />
            )}
            {props.headerSuffixElement && props.headerSuffixElement()}
          </div>
        </header>
      )}
    </>
  );
}

function SearchElement(props) {
  const [searchValue, setSearchValue] = useState("");
  const searchElementRef = useRef(null);
  //to set search value
  useEffect(() => {
    if (!!props.searchPrefix) {
      setSearchValue(props.searchPrefix);
    }
  }, [props.searchPrefix]);
  //set current key values
  useEffect(() => {
    if (
      typeof props.searchKeyword === "string" &&
      searchElementRef.current &&
      document.activeElement !== searchElementRef.current
    ) {
      setSearchValue(props.searchKeyword);
    }
  }, [props.searchKeyword]);
  // set search status
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !(
          (searchElementRef.current &&
            searchElementRef.current.contains(event.target)) ||
          (props.searchResultElementRef &&
            props.searchResultElementRef.current &&
            props.searchResultElementRef.current.contains(event.target)) ||
          typeof props.searchKeyword === "string"
        )
      ) {
        props.setIsSearchOn(false);
        // props.removeSearchResults();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      if (isValidObject(props.searchResults)) {
      }
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchElementRef]);

  // set search focus
  useEffect(() => {
    searchElementRef.current?.children[0]?.focus();
  }, []);
  const showSearchIcon = () => {
    if (searchValue) {
      return true;
    } else {
      return false;
    }
  };

  // const showCloseIcon = () => {};

  const onClickSearchOrClose = () => {
    if (
      typeof props.searchKeyword === "string" &&
      searchValue === props.searchKeyword
    ) {
      typeof props.removeSearchResults === "function" &&
        props.removeSearchResults(props.searchKeyword);
      props.setIsSearchOn(false);
    } else {
      props.onSearchUser(searchValue);
    }
  };

  return (
    <div
      className="position-relative inherit-parent-width margin-right-large"
      ref={searchElementRef}
    >
      <input
        className={` font-color-secondary font-size-medium padding-top-medium padding-bottom-medium padding-left-medium padding-right-larger border-radius-default display-block inherit-parent-width font-family-gilroy-medium background-white input-search-border-bottom-default padding-left-small`}
        id="add"
        type={props.inputType ? props.inputType : "text"}
        name="search"
        data-cy={
          props["data-cy"] ? props["data-cy"] : "header-search-bar-input"
        }
        value={searchValue}
        onChange={(event) => {
          event.preventDefault();
          setSearchValue(event.target.value);
        }}
        placeholder={
          typeof props.searchPlaceHolder === "string"
            ? props.searchPlaceHolder
            : "Search"
        }
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            onClickSearchOrClose();
          }
        }}
      />
      <img
        data-cy={showSearchIcon() ? "searchIcon" : "closeIcon"}
        src={showSearchIcon() ? searchIcon : closeIcon}
        alt="close_icon"
        className="cursor-pointer position-absolute right-1px top-50-percentage transform-translateY-minus-50-percentage margin-right-medium "
        onClick={onClickSearchOrClose}
      />
    </div>
  );
}

Header.propTypes = {
  /**
   * callback function for header back arrow onClick
   */
  backButtonOnClick: PropTypes.func,
  /**
   * change background color
   */
  backGroundColor: PropTypes.bool,
  /**
   * clinicNameAndLocality
   */
  clinicNameAndLocality: PropTypes.object,
  /**
   * current stepper value
   */
  currentStepperValue: PropTypes.number,
  /**
   * which you want to search
   */
  searchKeyword: PropTypes.string,
  /**
   * which returns the state of search bar
   * return value `true` or `false`
   */
  onSearchOn: PropTypes.func,
  /**
   * remove adaptiveness in large screen
   */
  removeResponsive: PropTypes.bool,

  /**
   * total number of steps
   */
  maxStepperCount: PropTypes.number,

  /**
   * which renders primary header
   */
  primary: PropTypes.bool,
  /**
   * setIsSearchOn
   */
  setIsSearchOn: PropTypes.bool,
  /**
   * onSearchUser
   */
  onSearchUser: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.func,
    PropTypes.string
  ]),
  /**
   * searchResultElementRef
   */
  searchResultElementRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element
  ]),
  /**
   * searchResults
   */
  searchResults: PropTypes.object,
  /**
   * removeSearchResults
   */
  removeSearchResults: PropTypes.any,

  /**
   * show Search bar, available only for primary header
   */
  showSearch: PropTypes.bool,
  /**
   * loading state of header, available only for primary header
   */
  loading: PropTypes.bool,

  /**
   * number of notifications
   */
  notificationsCount: PropTypes.number,
  /**
   * callback function prop for profile picture onClick
   */
  onProfilePictureClick: PropTypes.func,
  /**
   * selector attribute for profile picture
   */
  "profile-picture-data-cy": PropTypes.string,
  /**
   * which hides the back arrow of header. `hideBackButton=true` will hide the back arrow
   */
  hideBackButton: PropTypes.bool,

  /**
   * hides the support icon header
   */
  hideSupport: PropTypes.bool,
  /**
   * headerSuffixElement
   */
  headerSuffixElement: PropTypes.func,

  /**
   * displayName prop for primary header profile picture to show profile picture fallback
   */
  displayName: PropTypes.string,

  /**
   * shareIconOnClick is callback function for share icon onClick
   */
  shareIconOnClick: PropTypes.func,
  /**
   * searchPlaceHolder props used to insert placeholder for header search box
   */
  searchPlaceHolder: PropTypes.string,
  /**
   * searchPrefix prop used to add prefix for header search box
   * Default empty string
   */
  searchPrefix: PropTypes.string,
  /**
   * showShare prop used to show share icon in secondary header default `false`
   */
  showShare: PropTypes.bool,
  /**
   * callback function prop for support icon onClick.
   */
  supportIconOnClick: PropTypes.func,
  /**
   * title for header , not for primary header
   */
  title: PropTypes.string,
  /**
   * user profile picture source. And if the value is null or undefined it shows default avatar
   */
  userProfilePictureSrc: PropTypes.node,
  /**
   * type of user
   */
  userType: PropTypes.string
};

Header.defaultProps = {
  "profile-picture-data-cy": "user-profile-picture",
  backButtonOnClick: undefined,
  backGroundColor: false,
  clinicNameAndLocality: null,
  currentStepperValue: null,
  displayName: "",
  headerSuffixElement: undefined,
  hideBackButton: false,
  hideSupport: false,
  inputType: "",
  loading: false,
  maxStepperCount: null,
  notificationsCount: null,
  onProfilePictureClick: undefined,
  onQrClick: undefined,
  onSearchIconClick: undefined,
  onSearchOn: undefined,
  onSearchUser: null,
  primary: false,
  removeResponsive: false,
  removeSearchResults: null,
  searchKeyword: null,
  searchPlaceHolder: "Search",
  searchPrefix: "",
  searchResultElementRef: null,
  searchResults: null,
  searchType: "",
  setIsSearchOn: false,
  shareIconOnClick: undefined,
  showQr: false,
  showSearch: false,
  showShare: false,
  supportIconOnClick: undefined,
  title: "",
  userProfilePictureSrc: null,
  userType: ""
};

export default Header;

const HeaderPrefixSection = (props) => {
  if (props.isSearchOn === true) {
    return (
      <SearchElement
        inputType={props.inputType}
        onSearchUser={props.onSearchUser}
        removeSearchResults={props.removeSearchResults}
        searchKeyword={props.searchKeyword}
        searchPlaceHolder={props.searchPlaceHolder}
        searchPrefix={props.searchPrefix}
        searchResultElementRef={props.searchResultElementRef}
        searchResults={props.searchResults}
        searchType={props.searchType}
        setIsSearchOn={props.setIsSearchOn}
      />
    );
  }
  return (
    <img
      className={`${
        props.removeResponsive ? "" : "adaptive-header-logo-height"
      } header-logo-height`}
      data-cy="header-logo"
      alt="header-logo"
      src={
        props.userType === "doctor"
          ? ninto_doctor_logo
          : props.userType === "corporate"
          ? ninto_corporate_logo
          : ninto_logo
      }
      width="auto"
    />
  );
};

const HeaderSuffixSection = (props) => {
  return (
    <div className="display-flex flex-align-items-center margin-top-small">
      {props.isSearchOn === false && (
        <>
          {props.showSearch === true && (
            <img
              alt="searchIcon"
              className={`margin-right-large ${
                props.removeResponsive ? "" : "adaptive-support-icon-height"
              } support-icon-height  cursor-pointer`}
              data-cy="search-icon"
              onClick={() => {
                if (props.loading === false) {
                  if (typeof props.onSearchIconClick === "function") {
                    props.onSearchIconClick();
                  } else {
                    props.setIsSearchOn(true);
                  }
                }
              }}
              src={searchIcon}
            />
          )}
          {props.hideSupport === true ? null : (
            <img
              data-cy="support-icon"
              className={`margin-right-large ${
                props.removeResponsive ? "" : "adaptive-support-icon-height"
              } support-icon-height  cursor-pointer`}
              src={supportIcon}
              alt="support-icon"
              width="auto"
              onClick={() => {
                if (
                  props.loading === false &&
                  typeof props.supportIconOnClick === "function"
                ) {
                  props.supportIconOnClick();
                }
              }}
            />
          )}
          {props.showQr === true ? (
            <img
              data-cy="support-icon"
              className={`margin-right-large ${
                props.removeResponsive ? "" : "adaptive-support-icon-height"
              } support-icon-height  cursor-pointer`}
              src={QrIcon1}
              alt="qr-scan-icon"
              width="auto"
              onClick={() => {
                if (
                  props.loading === false &&
                  typeof props.onQrClick === "function"
                ) {
                  props.onQrClick();
                }
              }}
            />
          ) : null}
        </>
      )}
      <RoundedProfilePicture
        size="medium"
        displayName={props.displayName}
        background={props.background}
        data-cy={props["profile-picture-data-cy"]}
        loading={props.loading}
        notificationCount={props.notificationsCount}
        onClick={() => {
          if (props.onProfilePictureClick) {
            props.onProfilePictureClick();
          }
        }}
        src={props.userProfilePictureSrc}
      />
    </div>
  );
};
