import React, { useEffect, useState } from "react";
import "./front-end-global-components/design-system.css";
import "./App.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate
  // useLocation
} from "react-router-dom";
import "./index.css";
import { connect } from "react-redux";
import { useAuthSubscriber } from "./services/authentication";
import {
  useDemographicListener,
  useTransferredDocumentsListener,
  useLinkingTokenListener,
  useDataTransferListener,
  useSubscriptionListener,
  useAutoApprovalPoliciesListener,
  useNotificationsListener
  // useProfileSubscribers,
  // useRequestsListener
} from "./services/database";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import SignUp from "./Screens/SignUp/SignUp";
import Appointment from "./Screens/Appointment/Appointment";
import Symptom from "./Screens/Symptom/Symptom";
import ViewDocument from "./Screens/ViewDocument/ViewDocument";
import Query from "./Screens/Query/Query";
import LogRocket from "logrocket";
import QuerySuccess from "./Screens/Query/QuerySuccess";
import SnackBar from "./front-end-global-components/components/SnackBar/SnackBar";
// eslint-disable-next-line
// import perf from "./services/performance";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Vitals from "./Screens/Vitals/Vitals";
import Settings from "./Screens/Settings/Settings";
import Forms from "./Screens/Forms/Forms";
import reloadUpdate from "../src/assets/icons/reload_update.svg";
import FitnessTrackers from "./Screens/FitnessTrackers/FitnessTrackers";
import Button from "./front-end-global-components/components/Button/Button";
import Modal from "./front-end-global-components/components/Modal/Modal";
import RoundedProfilePicture from "./front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import googleFit from "./assets/images/googleFit.png";
import AbdmAuthentication from "./Screens/Abdm/AbdmAuthentication";
import ViewConsent from "./Screens/Abdm/ViewConsent";
import ChangeConsentPin from "./Screens/Abdm/ChangeConsentPin";
import EditProfile from "./Screens/Abdm/EditProfile";
import ViewSearchedClinicCareContext from "./Screens/Abdm/ViewSearchedClinicCareContext";
import ScanClinic from "./Screens/ScanClinic/ScanClinic";
import ScannedClinicDetails from "./Screens/ScannedClinicDetails/ScannedClinicDetails";
import AbdmHealthIdQr from "./Screens/AbdmHealthIdQr/AbdmHealthIdQr";
import {
  //  logout,
  setOAuthAccessToken
} from "./redux/authentication/actions";
import { initializeGoogleApi } from "./redux/fitness/actions";
import { lockerSetup, logrocketInit } from "./utils/constants";
import { useRemoteConfig } from "./services/remoteConfig";
import { logUserData } from "./services/analytics";
// import errorBoundaryFallBack from "./assets/icons/permissionDeniedFallBack.svg";
import { onMessage } from "firebase/messaging";
import { messaging } from "./services/firebase";
import AbhaNumber from "./Screens/Abdm/AbhaNumber";
import LinkedFacilities from "./Screens/Abdm/linkedFacilities";
import ResetPassword from "./Screens/Abdm/ResetPassword";
import { isValidObject } from "./utils/validators";
import { switchPatientProfile } from "./redux/patients/actions";

function App(props) {
  const [status, setStatus] = useState(null);
  const [isAuthed, setIsAuthed] = useState(false);
  const [remoteConfig, setRemoteConfig] = useState(null);
  // const location = useLocation();
  const navigate = useNavigate();

  //auth listener custom hook
  useAuthSubscriber((isAuthed) => {
    setIsAuthed(isAuthed);
  }, isAuthed);

  onMessage(messaging, (payload) => {
    console.log(payload);
  });

  useRemoteConfig((remoteConfig) => {
    setRemoteConfig(remoteConfig);
  }, isAuthed);
  //notifications listener custom hook
  useNotificationsListener({
    profileId: props.patients.profiles && Object.keys(props.patients.profiles),
    isAuthed: isAuthed,
    phoneNumber:
      props.patients.currentProfile &&
      props.patients.profiles[props.patients.currentProfile]?.phoneNumber
  });

  //documents listener custom hook
  useTransferredDocumentsListener({
    isAuthed: isAuthed,
    phoneNumber: props.auth.data.phoneNumber
  });

  useDataTransferListener({
    isAuthed: isAuthed,
    phoneNumber: props.auth.data.phoneNumber,
    patients: props.patients
  });

  useLinkingTokenListener({
    isAuthed: isAuthed,
    phoneNumber: props.auth.data.phoneNumber
  });

  // Demographic listener custom hook
  useDemographicListener({
    isAuthed: isAuthed,
    phoneNumber: props.auth.data.phoneNumber
  });

  useSubscriptionListener({
    isAuthed: isAuthed,
    phoneNumber: props.auth.data.phoneNumber,
    clinicId: lockerSetup.locker
  });

  useAutoApprovalPoliciesListener({
    isAuthed: isAuthed,
    phoneNumber: props.auth.data.phoneNumber
  });

  //useEffect for relaying user data to analytics
  useEffect(() => {
    if (props.auth.data.phoneNumber && props.auth.data.uid) {
      logUserData(props.auth.data.phoneNumber, props.auth.data.uid);
    }
  }, [props.auth.data.phoneNumber, props.auth.data.uid]);

  //useEffect for error handling
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logrocketInit.key) {
        LogRocket.init(logrocketInit.key, release);
      }
    }
  }, []);

  useEffect(() => {
    if (props.patients.currentProfile) {
      return;
    } else {
      if (isValidObject(props.patients.accessToken)) {
        props.switchPatientProfile(Object.keys(props.patients.accessToken)[0]);
      }
    }
    // eslint-disable-next-line
  }, [props.patients.currentProfile]);

  return window.location.hostname === "localhost" ||
    window.matchMedia("(display-mode: standalone)").matches ||
    true ? (
    <React.Fragment>
      <TransitionGroup className="inherit-parent-height">
        <CSSTransition timeout={300} classNames="fade-in-fade-out-transition">
          <Routes>
            <Route
              exact
              path="/login"
              element={
                <PublicRoute accessToken={props.auth.data.accessToken}>
                  <Login navigate={navigate} />
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/signup"
              element={
                <PublicRoute accessToken={props.auth.data.accessToken}>
                  <SignUp navigate={navigate} />
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Home navigate={navigate} remoteConfig={remoteConfig} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/symptom"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Symptom navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/symptoms/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Symptom navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/appointment/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Appointment navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/reports/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/prescriptions/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/consultations/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/vaccines/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/vitals/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/others/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/receipts/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admissions/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/insurances/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/i/:inviteCode"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Home navigate={navigate} />
                </ProtectedRoute>
              }
            />
            {/* <Route
            exact
            path="/test"
            element={
                <TestScreen  navigate={navigate}  />
              
            }
          /> */}
            <Route
              exact
              path="/query"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Query navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/fitnesstrackers"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <FitnessTrackers navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/querysuccess"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <QuerySuccess navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/vitals"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Vitals navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/settings"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Settings navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/forms/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <Forms navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/authentication"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <AbdmAuthentication navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/clinic/:clinicId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewSearchedClinicCareContext navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/changeconsentpin"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ChangeConsentPin navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/abdmHealthId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <AbdmHealthIdQr navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/editProfile"
              element={
                <ProtectedRoute accessToken={props.auth.data.accessToken}>
                  <EditProfile navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/abhaNumber"
              element={
                <ProtectedRoute accessToken={props.auth.data.accessToken}>
                  <AbhaNumber navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/linkedFacilities"
              element={
                <ProtectedRoute accessToken={props.auth.data.accessToken}>
                  <LinkedFacilities navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/:abdmRequestType/:abdmRequestId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ViewConsent navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/scanClinic"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ScanClinic navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/scannedClinicDetails"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ScannedClinicDetails navigate={navigate} />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/resetPassword"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  remoteConfig={remoteConfig}
                >
                  <ResetPassword navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>

      <GoogleOAuthRefreshModal
        show={props.auth.oAuth.accessToken === "expired"}
        remindMeLaterOnClick={() => {
          props.setOAuthAccessToken(null);
        }}
        reconnectOnClick={() => {
          props.initGapi();
        }}
      />
      <SnackBar
        type={props.status.code === null ? "success" : "error"}
        message={status}
        status={props.status}
      />
      <div
        id="critical-update-card"
        className="display-none inherit-parent-height inherit-parent-width background-color-black-with-opacity-light position-absolute-center-self "
      >
        <footer className="position-absolute  inherit-parent-width bottom-0 padding-large">
          <div
            id="critical-update-card-contents-wrapper"
            className="background-color-white inherit-parent-width padding-large border-radius-default display-flex flex-direction-column flex-align-items-center "
          >
            <img
              src={reloadUpdate}
              alt="update"
              className=" margin-bottom-default"
            />
            <p className="font-family-gilroy-medium font-size-medium font-color-secondary text-align-center margin-bottom-default ">
              {" "}
              Critical update available{" "}
            </p>
            <p className="font-family-gilroy-regular font-size-medium font-color-secondary text-align-center margin-bottom-default ">
              New critical changes updated and ready to launch.
            </p>
            <button
              id="critical-update-confirm-button"
              className="inherit-parent-width padding-vertical-medium font-family-gilroy-bold border-radius-default font-size-medium cursor-pointer background-color-primary font-color-white box-shadow-none"
            >
              Okay
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  ) : (
    <>please use mobile app</>
  );
}

const ProtectedRoute = (props) => {
  if (props.accessToken === null) {
    return <Navigate to="/login" />;
  }
  // else if (props.remoteConfig?.experimental === false) {
  //   return <UnAuthorizedFallBack />;
  // } else if (props.remoteConfig === null) {
  //   return <div></div>;
  // }
  else {
    return props.children;
  }
};

const PublicRoute = ({ accessToken, children }) => {
  if (accessToken === null) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const mapStateToProps = function (state) {
  return {
    abdm: state.abdm,
    status: state.status,
    auth: state.auth,
    downloads: state.downloads,
    patients: state.patients,
    profile: state.profile
  };
};

const mapDispatchToProps = function () {
  return {
    initGapi: () => initializeGoogleApi(),
    switchPatientProfile: (data) => switchPatientProfile(data),
    setOAuthAccessToken: (accessToken, email) =>
      setOAuthAccessToken(accessToken, email)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

const GoogleOAuthRefreshModal = (props) => {
  return (
    <Modal
      show={props.show}
      width="inherit-parent-width"
      maxWidth="max-width-630px"
      background="false"
      boxShadow="false"
      borderRadius="false"
      height="height-fit-to-content"
      modalBodyClassName="padding-horizontal-medium"
    >
      <div className="background-white padding-medium border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary">
        <div className=" font-size-medium text-align-center padding-bottom-large">
          Action required for fitness tracking
        </div>
        <div className=" font-size-small text-align-center ">
          Google fit tracking session has been expired. Please re-connect to
          resume fitness tracking.
        </div>
        <div className="display-flex flex-grow-1 flex-align-items-center padding-bottom-larger cursor-pointer ">
          <RoundedProfilePicture
            className="margin-top-default"
            size={"medium"}
            src={googleFit}
          />
          <div className="flex-grow-1 flex-direction-column display-flex padding-left-medium  ">
            <span className="font-size-medium font-family-gilroy-regular font-color-secondary text-transform-capitalize">
              Google fit
            </span>
          </div>
        </div>
        <div className="display-flex">
          <Button
            data-cy="edit-profile-logout-button"
            boxShadow={false}
            loading={props.logoutButtonLoading}
            className="margin-right-small font-family-gilroy-medium"
            variant="primary"
            text="Re-connect"
            onClick={() => {
              if (typeof props.reconnectOnClick === "function") {
                props.reconnectOnClick();
              }
            }}
          />
          <Button
            data-cy="edit-profile-cancel-button"
            boxShadow={false}
            className="margin-left-small font-family-gilroy-medium"
            variant="secondary"
            text="Remind me later"
            onClick={() => {
              if (typeof props.remindMeLaterOnClick === "function") {
                props.remindMeLaterOnClick();
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

// const UnAuthorizedFallBack = () => {
//   return (
//     <main className="inherit-parent-height inherit-parent-width flex-place-children-page-center padding-left-larger padding-right-larger">
//       <div>
//         <div className="padding-bottom-larger flex-center-children-horizontally">
//           <img
//             src={errorBoundaryFallBack}
//             className="error-fallback-image-height"
//             alt="error-fallback"
//             width="auto"
//           />
//         </div>

//         <div>
//           <div
//             style={{ fontSize: 24 }}
//             className="font-weight-normal font-family-gilroy-bold text-align-center font-color-secondary font-size-larger padding-bottom-large"
//           >
//             Access Denied !
//           </div>
//           <div className="text-align-center font-size-larger">
//             You are not authorized for further access.
//           </div>
//           <div className="text-align-center font-size-larger">
//             Please check back after sometime.
//           </div>
//         </div>
//         <div className=" text-align-center padding-top-larger">
//           <Button
//             boxShadow={false}
//             text="Take me back"
//             onClick={() => {
//               logout();
//             }}
//           />
//         </div>
//       </div>
//     </main>
//   );
// };
