import { all, put, takeEvery } from "@redux-saga/core/effects";
import { otpRequest, otpValidate } from "../../services/authentication";
import store from "../store/store";
import { getVerificationCode } from "../../services/mocks";
import { setErrorStatus } from "../status/actions";
import { login, signUp } from "../authentication/actions";

export const mockActionTypes = {
  MOCK_AUTH: "MOCK_AUTH",
  MOCK_AUTH_VERIFY_OTP: "MOCK_AUTH_VERIFY_OTP",
  MOCK_ABDM_LOGIN: "MOCK_ABDM_LOGIN"
};

function* mockAuthWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: true
      }
    });
    const phoneNumber = "+918489551067";
    const verificationId = yield otpRequest(phoneNumber);
    yield put({
      type: "SET_AUTH_VERIFICATION_ID",
      payload: {
        verificationId: verificationId
      }
    });
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
    const otp = yield getVerificationCode();
    yield put({
      type: "VERIFY_OTP",
      payload: {
        loginOtp: otp,
        userName: "chan",
        phoneNumber: phoneNumber,
        type: action.payload.type
      }
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* mockAuthVerifyOtpWorker(action) {
  try {
    const otp = yield getVerificationCode();
    const authCredentials = yield otpValidate(
      otp,
      store.getState().auth.credentials.verificationId
    );
    yield put({
      type: "SET_AUTH_CREDENTIALS",
      payload: {
        authCredential: authCredentials
      }
    });
    if (action.payload.type === "signUp") {
      signUp(action.payload.userName, action.payload.phoneNumber);
    } else {
      login();
    }
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* mockAbdmLoginWorker(action) {
  yield put({
    type: "SET_AUTH_INFO",
    payload: {
      accessToken: action.payload.accessToken,
      uid: action.payload.uid
    }
  });
}

export default function* mockWatcher() {
  yield all([
    takeEvery("MOCK_AUTH", mockAuthWorker),
    takeEvery("MOCK_AUTH_VERIFY_OTP", mockAuthVerifyOtpWorker),
    takeEvery("MOCK_ABDM_LOGIN", mockAbdmLoginWorker)
  ]);
}
