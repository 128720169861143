import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { isValidObject } from "../../utils/validators";
import Button from "../../front-end-global-components/components/Button/Button";
import warningIcon from "../../assets/icons/warning.svg";
import errorBoundaryFallBack from "../../assets/images/errorFallback.svg";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import Header from "../../front-end-global-components/components/Header/Header";
import PDFRenderer from "../../front-end-global-components/components/PDFRenderer/PDFRenderer";
// import addIconGrey from "../../front-end-global-components/assets/addIconGrey.svg";
import "./ViewDocument.css";
import { downloadURI } from "../../front-end-global-components/utils/Functions";
import { throwError } from "../../services/error";
import FHIRRenderer from "../../front-end-global-components/components/FHIRRenderer/FHIRRenderer";
import { setErrorStatus } from "../../redux/status/actions";
import { getDocumentDownloadUrl } from "../../redux/downloads/actions";
import { createQuery } from "../../redux/query/actions";
import { setNotificationRead } from "../../redux/notifications/actions";
import { setCurrentDocument } from "../../redux/documents/actions";

const ViewDocument = (props) => {
  let { documentId } = useParams();
  let documentType = window.location.pathname.split("/")[1];
  const [readOnly] = useState(true);
  const [PDFfromFHIR, setPDFfromFHIR] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [fileType, setFileType] = useState("");
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState({});
  const [jsonData, setJsonData] = useState(null);
  const [currentDoc] = useState("base");
  const [invalidDocument, setInvalidDocument] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const getPdfData = (doctorId) => {
    if (!!doctorId && !!pdfArrayBuffer[doctorId]) {
      return;
    } else if (!doctorId && !!pdfArrayBuffer.base) {
      return;
    }
    if (
      !!doctorId &&
      isValidObject(props.downloads.data) &&
      isValidObject(props.downloads.data[documentType]) &&
      isValidObject(props.downloads.data[documentType][documentId]) &&
      typeof props.downloads.data[documentType][documentId].downloadUrl ===
        "string" &&
      isValidObject(props.downloads.data[documentType][documentId].notes) &&
      typeof props.downloads.data[documentType][documentId].notes[doctorId] ===
        "string"
    ) {
      fetch(props.downloads.data[documentType][documentId].notes[doctorId])
        .then(async (res) => {
          setPdfArrayBuffer({
            ...pdfArrayBuffer,
            [doctorId]: await res.arrayBuffer()
          });
        })
        .catch((error) => {
          setErrorStatus({
            custom: "custom",
            message: "Unable to download"
          });
          console.error(error);
        });
    } else if (
      isValidObject(props.documents.data) &&
      props.documents.data[documentId] &&
      isValidObject(props.downloads.data) &&
      isValidObject(props.downloads.data[documentType][documentId]) &&
      typeof props.downloads.data[documentType][documentId].downloadUrl ===
        "string"
    ) {
      fetch(props.downloads.data[documentType][documentId].downloadUrl)
        .then(async (res) => {
          const url = res.url.split("?")[0];
          const lastElement = url.split(".").length - 1;
          setFileType(url.split(".")[lastElement]);
          if (url.split(".")[lastElement] === "json") {
            const response = await fetch(res.url, {
              method: "GET"
            });
            const text = await response.json();
            setJsonData(text);
          } else {
            setPdfArrayBuffer({
              ...pdfArrayBuffer,
              base: await res.arrayBuffer()
            });
          }
        })
        .catch((error) => {
          setErrorStatus({
            custom: "custom",
            message: "Unable to download"
          });
          console.error(error);
        });
    }
  };

  //document download url fetching
  useEffect(() => {
    if (
      typeof props.downloads.data?.[documentType]?.[documentId] !== "string" &&
      props.documents.latest === true &&
      typeof documentId === "string" &&
      typeof documentType === "string"
    ) {
      if (
        isValidObject(props.documents.data) &&
        props.documents.data[documentId]
      ) {
        // props.setSelectedDocument(documentId);
        props.getDocumentUrl(documentId);
      } else {
        setInvalidDocument(true);
      }
    }

    return () => {
      setInvalidDocument(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentId,
    props.patients?.currentProfile,
    props.documents?.data,
    props.documents?.latest
  ]);

  // get pdf data
  useEffect(() => {
    if (
      typeof props.downloads.data?.[documentType]?.[documentId]?.downloadUrl ===
        "string" &&
      !Object.keys(pdfArrayBuffer).includes("base")
    ) {
      getPdfData();
    }
    // eslint-disable-next-line
  }, [props.downloads.data, documentId, documentType]);

  // set empty object in array buffer
  useEffect(() => {
    return () => {
      setPdfArrayBuffer({});
    };
  }, []);

  const headerTitle = () => {
    switch (documentType) {
      case "prescriptions":
        return "View Prescription";

      case "receipts":
        return "View Receipt";

      case "reports":
        return "View Report";

      case "admissions":
        return "View Admission";

      case "insurances":
        return "View Insurance";

      case "vaccines":
        return "View Vaccine";

      case "consultations":
        return "View Consultation";

      case "vitals":
        return "View Vital";

      case "WellnessRecord":
        return "View WellnessRecord";

      default:
        return "View Document";
    }
  };

  const shouldShowSuspense = () => {
    if (
      (props.documents.loading === true || props.downloads.loading === true) &&
      readOnly === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const querySubmitHandler = (event) => {
    event.preventDefault();
    props.createQuery(
      {
        document: {
          id: documentId,
          incorrect: "user"
        },
        topic: "Document mismatch"
      },
      props.navigate
    );
    setShowModal(false);
  };

  // set notification read is true in database
  useEffect(() => {
    if (
      typeof props.downloads.data?.[documentType]?.[documentId]?.downloadUrl ===
        "string" &&
      typeof props.patients?.currentProfile === "string" &&
      isValidObject(props.notifications.data?.[props.patients.currentProfile])
    ) {
      const urlPath = documentType + "/" + documentId;
      const notificationObject = Object.values(
        props.notifications.data[props.patients.currentProfile]
      )
        .flat()
        .find(
          (notification) =>
            notification.url === urlPath && notification.read === false
        );
      if (isValidObject(notificationObject)) {
        props.setNotificationRead(notificationObject.documentId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentType,
    documentId,
    props.downloads.data,
    props.notifications.data
  ]);
  return (
    <div className="inherit-parent-height">
      <Header
        title={headerTitle()}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        backButtonOnClick={() => {
          if (PDFfromFHIR !== null) {
            setPDFfromFHIR(null);
          } else {
            props.navigate(-1);
          }
        }}
        showShare={
          (isValidObject(pdfArrayBuffer) || jsonData) &&
          props.downloads.loading === false &&
          typeof props.documents.data?.[documentId]?.name === "string"
            ? true
            : false
        }
        shareIconOnClick={async () => {
          try {
            if (
              typeof props.documents.data?.[documentId]?.name === "string" &&
              pdfArrayBuffer[currentDoc]
            ) {
              const blob = new Blob([pdfArrayBuffer[currentDoc]], {
                type: "application/pdf"
              });
              const documentFile = new File(
                [blob],
                props.documents.data[documentId].name,
                { type: "application/pdf" }
              );
              if (
                !window.Cypress &&
                !!navigator &&
                navigator.canShare &&
                navigator.canShare({ files: [documentFile] })
              ) {
                await navigator.share({
                  title: "Ninto",
                  text: "Ninto - View Document",
                  url: window.location.href,
                  files: [documentFile]
                });
              } else {
                downloadURI(
                  pdfArrayBuffer[currentDoc],
                  props.documents.data[documentId].name
                );
              }
            }
          } catch (error) {
            throwError("custom", "Something went wrong");
          }
        }}
      />
      <div className=" overflow-y-auto remaining-body-height">
        <article className="inherit-parent-height display-flex flex-direction-column">
          {invalidDocument === true && props.documents.latest === true ? (
            <DocumentNotFound
              backButtonOnClick={() => {
                props.navigate(-1);
              }}
            />
          ) : shouldShowSuspense() === "true" ? (
            <ViewDocumentSuspense />
          ) : (
            <>
              {props.documents.data[documentId] && (
                <>
                  <section
                    data-cy="documents-view-screen"
                    className={`inherit-parent-height overflow-auto display-flex flex-direction-column inherit-parent-width background-white`}
                  >
                    {(fileType !== "json" || PDFfromFHIR !== null) && (
                      <PDFRenderer
                        readOnly={readOnly}
                        pdfArrayBuffer={
                          PDFfromFHIR
                            ? PDFfromFHIR
                            : pdfArrayBuffer[currentDoc]
                            ? pdfArrayBuffer[currentDoc]
                            : null
                        }
                        isPrescription={documentType === "prescription"}
                        saveWithBackground={false}
                        onPDFChange={() => {
                          if (isPdfLoading === false) {
                            setIsPdfLoading(true);
                          }
                        }}
                        onRender={() => {
                          if (isPdfLoading === true) {
                            setIsPdfLoading(false);
                          }
                        }}
                      />
                    )}
                    {fileType === "json" &&
                      jsonData !== null &&
                      PDFfromFHIR === null && (
                        <>
                          <FHIRRenderer
                            userType="patient"
                            document={props.documents.data[documentId]}
                            // connection={props.connection}
                            data={jsonData}
                            documentOnClick={(document) => {
                              setPDFfromFHIR(document);
                            }}
                          />
                        </>
                      )}
                  </section>
                  {isValidObject(props.documents.data[documentId]) &&
                    props.documents.data[documentId].from &&
                    props.documents.data[documentId].from.type !==
                      "patient" && (
                      <div
                        className="background-color-secondary flex-justify-content-center flex-align-items-center padding-large"
                        data-cy="not-your-report"
                      >
                        <div
                          className="background-color-secondary flex-justify-content-center flex-align-items-center cursor-pointer "
                          onClick={() => {
                            setShowModal(!showModal);
                          }}
                        >
                          <img
                            className=""
                            width="auto"
                            src={warningIcon}
                            alt="warning-icon"
                          />
                          <span className="font-color-tertiary font-size-small padding-horizontal-default">
                            Not your report?
                          </span>
                          <span className="font-color-primary font-size-small">
                            Notify!
                          </span>
                        </div>
                      </div>
                    )}
                  {/* <PdfNotes
                    loading={
                      (isValidObject(props.documents.data[documentId]?.notes) &&
                        !pdfArrayBuffer[currentDoc]) ||
                      isPdfLoading
                    }
                    document={props.documents.data[documentId]}
                    show={
                      documentType !== "prescriptions" &&
                      isValidObject(props.documents.data[documentId]) &&
                      props.documents.data[documentId].hasOwnProperty("notes")
                    }
                    currentNotesId={currentDoc !== "base" ? currentDoc : ""}
                    notesButtonOnClick={(notesId) => {
                      if (currentDoc === notesId) {
                        if (!Object.keys(pdfArrayBuffer).includes("base")) {
                          getPdfData();
                        }
                        setCurrentDoc("base");
                      } else {
                        if (!Object.keys(pdfArrayBuffer).includes(notesId)) {
                          getPdfData(notesId);
                        }
                        setCurrentDoc(notesId);
                      }
                    }}
                  /> */}
                </>
              )}
            </>
          )}
        </article>
      </div>
      <Modal
        show={showModal}
        width="inherit-parent-width"
        maxWidth="max-width-630px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
        modalBodyClassName="padding-horizontal-medium"
      >
        <div className="background-white padding-medium border-radius-default box-shadow-default ">
          <div className="font-family-gilroy-medium font-size-medium font-color-secondary text-align-center">
            Not your record?
          </div>
          <div className="text-align-center font-size-small font-color-secondary padding-large font-family-gilroy-medium">
            You can notify the clinic / doctor for misplaced records. Would you
            like to notify and remove this record from your timeline ?
          </div>
          <div className="display-flex">
            <Button
              buttonKey="query-notify-button"
              data-cy="notify-button"
              boxShadow={false}
              loading={props.auth.loading}
              className="margin-right-small font-family-gilroy-medium"
              text="Notify"
              onClick={(event) => querySubmitHandler(event)}
            />
            <Button
              buttonKey={"query-raise-button"}
              boxShadow={false}
              className="margin-left-small font-family-gilroy-regular font-color-secondary border-1px-e5e5e5"
              variant="secondary"
              text="Cancel"
              onClick={() => setShowModal(!showModal)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    documents: state.documents,
    notifications: state.notifications,
    downloads: state.downloads
  };
};

const mapDispatchToProps = function () {
  return {
    setSelectedDocument: (documentDocId) => setCurrentDocument(documentDocId),
    getDocumentUrl: (documentId) => getDocumentDownloadUrl(documentId),
    createQuery: (queryData, navigate) => createQuery(queryData, navigate),
    setNotificationRead: (notificationId) => setNotificationRead(notificationId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocument);

//pdf notes component
// const PdfNotes = (props) => {
//   /**
//    * loading => to show suspense
//    * notesButtonOnClick => onClick fun callback of notes button
//    * document => document data object
//    * currentNotesId => selected notes id
//    * addNotesOnClick => add notes button onClick fun
//    * showAddNotes => to show add notes button  `true` or `false`
//    */
//   return props.loading === true ? (
//     <PdfNotesSuspense {...props} />
//   ) : (
//     props.show === true &&
//       isValidObject(props.document) &&
//       props.document.notes &&
//       isValidObject(props.document.notes) && (
//         <section
//           className={`background-color-secondary margin-horizontal-auto inherit-parent-width padding-left-large padding-right-large footer background-color-grey inherit-parent-width padding-bottom-small`}
//           data-cy="doctors-notes-dropdown"
//         >
//           <p className="font-family-gilroy-regular font-color-secondary font-size-smaller padding-top-default">
//             Notes by doctors
//           </p>
//           <div className=" inherit-parent-width flex-justify-content-space-between">
//             <div
//               className={`${
//                 props.showAddNotes === true
//                   ? "width-85-percentage"
//                   : "inherit-parent-width "
//               } border-radius-default overflow-x-auto padding-top-default padding-bottom-default overflow-x-auto inherit-parent-height display-flex flex-align-items-center`}
//             >
//               {isValidObject(props.document.notes) &&
//                 Object.keys(props.document.notes).map((doctorId, index) => {
//                   return (
//                     <React.Fragment key={doctorId}>
//                       <button
//                         key={index}
//                         className={`white-space-nowrap padding-top-small padding-bottom-small padding-left-default padding-right-default margin-right-small border-radius-default font-family-gilroy-regular  font-size-small
//                                      ${
//                                        doctorId === props.currentNotesId
//                                          ? "background-primary font-color-white"
//                                          : "background-color-secondary font-color-secondary border-1px-e5e5e5"
//                                      }`}
//                         onClick={() => {
//                           if (typeof props.notesButtonOnClick === "function") {
//                             //returns notes id
//                             props.notesButtonOnClick(doctorId);
//                           }
//                         }}
//                         data-cy={
//                           props.document.notes[doctorId].authorName &&
//                           props.document.notes[doctorId].authorName
//                             .split(" ")
//                             .join("-")
//                         }
//                       >
//                         {props.document.notes[doctorId].authorName &&
//                           props.document.notes[doctorId].authorName}
//                       </button>
//                     </React.Fragment>
//                   );
//                 })}
//             </div>
//             {props.showAddNotes === true && (
//               <button
//                 className="background-color-lite-grey border-radius-default pdf-add-notes-button-size flex-place-children-page-center cursor-pointer"
//                 onClick={(event) => {
//                   if (typeof props.addNotesOnClick === "function") {
//                     props.addNotesOnClick(event);
//                   }
//                 }}
//               >
//                 <img src={addIconGrey} alt="" className="add-notes-icon-size" />
//               </button>
//             )}
//           </div>
//         </section>
//       )
//   );
// };

// function PdfNotesSuspense(props) {
//   return (
//     <section
//       className="background-color-secondary margin-horizontal-auto inherit-parent-width padding-left-large padding-right-large  inherit-parent-width padding-bottom-large"
//       data-cy="pdf-notes-suspense"
//     >
//       <div
//         className="shimmer border-radius-default padding-default"
//         style={{ width: "20%" }}
//       ></div>
//       <div
//         className={`display-flex flex-justify-content-space-between ${
//           props.showAddNotes !== true ? "padding-top-default" : ""
//         }`}
//         style={{ alignItems: "flex-end" }}
//       >
//         <div
//           className={`display-flex ${
//             props.showAddNotes === true
//               ? "width-85-percentage"
//               : "inherit-parent-width "
//           }`}
//           style={{ height: "30%" }}
//         >
//           <span
//             className="shimmer padding-default margin-right-large border-radius-default"
//             style={{ width: "100%" }}
//           ></span>
//         </div>
//         {props.showAddNotes === true && (
//           <div className="shimmer pdf-add-notes-button-size border-radius-default"></div>
//         )}
//       </div>
//     </section>
//   );
// }
function ViewDocumentSuspense() {
  return (
    <article
      data-cy="view-document-suspense"
      className="screen-loader-wrapper inherit-parent-width  flex-center-children-horizontally"
    >
      <main className="inherit-parent-width padding-larger ">
        <ul className="margin-top-larger margin-bottom-larger">
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "80%" }}
          ></li>
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "90%" }}
          ></li>
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "70%" }}
          ></li>
        </ul>
        <ul className="margin-top-larger margin-bottom-larger padding-vertical-larger">
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "80%" }}
          ></li>
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "90%" }}
          ></li>
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "70%" }}
          ></li>
        </ul>
        <ul className="margin-top-larger margin-bottom-larger">
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "80%" }}
          ></li>
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "90%" }}
          ></li>
          <li
            className="padding-right-large shimmer margin-bottom-default"
            style={{ width: "70%" }}
          ></li>
        </ul>
      </main>
    </article>
  );
}

function DocumentNotFound({ backButtonOnClick }) {
  return (
    <section className=" inherit-parent-height inherit-parent-width flex-place-children-page-center padding-larger">
      <main className="flex-place-children-page-center">
        <img src={errorBoundaryFallBack} alt="querySubmittedSuccess" />
        <h1 className="font-weight-normal font-family-gilroy-bold font-size-medium font-color-secondary padding-top-large padding-bottom-large">
          Document not found
        </h1>
        <p className="font-family-gilroy-medium font-size-medium font-color-tertiary text-align-center">
          Please try again later. If the problem persists, please contact
          customer support.
        </p>
        <div className="max-width-35-percentage">
          <Button
            buttonKey="document-retry-button"
            data-cy="button"
            type="submit"
            text="Retry"
            className="inherit-parent-width margin-top-larger"
            onClick={(_) => {
              window.location.reload();
            }}
            boxShadow={false}
          />
          <p
            onClick={() => {
              backButtonOnClick();
            }}
            style={{ textDecoration: "underline" }}
            className="text-align-center margin-top-medium"
          >
            Take me back
          </p>
        </div>
      </main>
    </section>
  );
}
