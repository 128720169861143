import {
  LocationIcon,
  MailIcon,
  PhoneIcon
} from "../../front-end-global-components/assets/assets";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";

export const ClinicCard = (props) => {
  return (
    <>
      <section className="padding-left-larger padding-right-larger padding-top-large padding-bottom-large">
        <div className="display-flex flex-align-items-center flex-justify-content-space-between">
          <div className="display-flex flex-align-items-center">
            <div>
              <RoundedProfilePicture
                userType="corporate"
                src={props.logoURL && props.logoURL}
                // loading={loading}
                size="small"
              />
            </div>
            <div className="padding-left-default">
              {props.clinicDetails?.identifier?.name
                ? props.clinicDetails?.identifier?.name
                : props.clinicDetails?.identifier?.id}
            </div>
          </div>

          <div className="font-size-small">
            {props.clinicDetails?.city ? props.clinicDetails.city : ""}
          </div>
        </div>
        <div className="font-size-small">
          <div className="padding-top-large display-flex flex-align-items-center">
            <PhoneIcon />
            <div className="padding-left-large">
              {props.clinicDetails?.telephone
                ? props.clinicDetails.telephone
                : "-"}
            </div>
          </div>

          <div className="padding-top-default display-flex flex-align-items-center">
            <MailIcon />
            <div className="padding-left-large">
              {props.clinicDetails?.email ? props.clinicDetails?.email : "-"}
            </div>
          </div>

          <div className="padding-top-default display-flex ">
            <LocationIcon width="12" />
            <div className="padding-left-large">
              {props.clinicDetails?.address ? props.clinicDetails.address : "-"}
            </div>
          </div>
        </div>
      </section>
      <hr className="background-color-lite-grey height-1px border-none" />
    </>
  );
};

// { data, loading, checkBoxOnChange }
export const DocumentCard = (props) => {
  return (
    <>
      <div className="display-flex padding-bottom-larger">
        {props.checkBoxOnChange && (
          <div className="flex-center-children-vertically">
            <CheckBox
              data-cy="care-context-check-box"
              onChange={props.checkBoxOnChange}
            />
          </div>
        )}
        <div className="text-transform-capitalize padding-left-large flex-direction-column">
          <div className="font-size-small padding-bottom-default">
            {props.loading ? "" : props.data.display}
          </div>
          <div className="font-size-smaller background-color-secondary font-color-secondary border-radius-default width-fit-content padding-left-default padding-right-default padding-top-small padding-bottom-small">
            {props.loading ? "" : props.data.referenceNumber}
          </div>
        </div>
      </div>
    </>
  );
};
