import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import errorIcon from "../../front-end-global-components/assets/error.svg";
import Header from "../../front-end-global-components/components/Header/Header";
import {
  editProfile,
  generateOtpForUpdate,
  setTransactionId,
  verifyOtpForUpdate
} from "../../redux/patients/actions";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { isValidObject, validation } from "../../utils/validators";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import {
  stateCodeWithDistricts,
  stateOptionsWithCode
} from "../../utils/constants";
import { CircleWithTickIcon } from "../../front-end-global-components/assets/assets";
import Button from "../../front-end-global-components/components/Button/Button";
import CropperModal from "../../front-end-global-components/components/CropperModal/CropperModal";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import { Regex } from "../../utils/regex";

const EditProfile = (props) => {
  const [profileData, setProfileData] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [error, setError] = useState({});
  const [mode, setMode] = useState(null);
  const [counter, setCounter] = useState(null);
  useEffect(() => {
    let counterTimer;
    if (counter && typeof counter === "number") {
      counterTimer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      clearInterval(counterTimer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const [formData, setFormData] = useState({
    profilePhoto: "",
    name: {
      first: "",
      middle: "",
      last: ""
    },
    gender: "",
    email: "",
    phoneNumber: "+91",
    dateOfBirth: "",
    address: "",
    stateCode: "",
    districtCode: "",
    pinCode: ""
  });
  const [OTP, setOTP] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (
      isValidObject(
        props.patients.healthIdData?.[props.patients.currentProfile]
      )
    ) {
      setProfileData(
        props.patients.healthIdData[props.patients.currentProfile]
      );
    }
  }, [props.patients.healthIdData, props.patients.currentProfile]);

  const getGender = () => {
    if (profileData?.gender === "M") {
      return "male";
    } else if (profileData?.gender === "F") {
      return "female";
    } else if (profileData?.gender === "O") {
      return "others";
    } else if (profileData?.gender === "U") {
      return "unknown";
    }
  };

  const combineDateOfBirth = () => {
    const date = ("0" + profileData?.dateOfBirth.date).slice(-2);
    const month = ("0" + profileData?.dateOfBirth.month).slice(-2);
    const year = profileData?.dateOfBirth.year;
    return `${year}-${month}-${date}`;
  };

  useEffect(() => {
    if (profileData) {
      let data = {
        profilePhoto: profileData?.profilePhoto
          ? `data:image/png;base64, ${profileData?.profilePhoto}`
          : "",
        name: {
          first: profileData?.name.first,
          middle: profileData?.name.middle,
          last: profileData?.name.last
        },
        gender: profileData?.gender,
        email: profileData?.email,
        phoneNumber: `+91${profileData?.mobile}`,
        dateOfBirth: combineDateOfBirth(),
        address: profileData?.address,
        stateCode: profileData?.stateCode,
        districtCode: profileData?.districtCode,
        pinCode: profileData?.pincode
      };
      setFormData(data);
    }

    // eslint-disable-next-line
  }, [profileData]);

  function onChangeHandler(event) {
    if (event.target.name === "editImage") {
      return;
    }
    switch (event.target.name) {
      case "firstName":
        setFormData({
          ...formData,
          name: { ...formData?.name, first: event.target.value }
        });
        break;

      case "middleName":
        setFormData({
          ...formData,
          name: { ...formData?.name, middle: event.target.value }
        });
        break;

      case "lastName":
        setFormData({
          ...formData,
          name: { ...formData?.name, last: event.target.value }
        });
        break;

      default:
        setFormData({ ...formData, [event.target.name]: event.target.value });
        break;
    }
  }

  const verifyForm = () => {
    if (profileData?.kycStatus === "VERIFIED") {
      if (
        !error &&
        formData?.address &&
        formData?.stateCode &&
        formData?.districtCode &&
        formData?.pinCode &&
        (formData?.address !== profileData?.address ||
          (formData?.email ? formData?.email !== profileData?.email : false) ||
          (formData?.phoneNumber
            ? formData?.phoneNumber !== `+91${profileData?.mobile}`
            : true) ||
          formData?.stateCode !== profileData?.stateCode ||
          formData?.districtCode !== profileData?.districtCode ||
          formData?.pinCode !== profileData?.pincode)
      ) {
        return false;
      } else {
        return true;
      }
    }
    if (
      formData?.name.first &&
      formData?.gender &&
      formData?.dateOfBirth &&
      formData?.address &&
      formData?.stateCode &&
      formData?.districtCode &&
      formData?.pinCode &&
      (formData?.name.first !== profileData?.name.first ||
        (formData?.name.middle
          ? formData?.name.middle !== profileData?.name.middle
          : false) ||
        (formData?.name.last
          ? formData?.name.last !== profileData?.name.last
          : false) ||
        (formData?.email ? formData?.email !== profileData?.email : false) ||
        (formData?.phoneNumber
          ? formData?.phoneNumber !== `+91${profileData?.mobile}`
          : false) ||
        formData?.gender !== profileData?.gender ||
        formData?.dateOfBirth !== combineDateOfBirth() ||
        formData?.address !== profileData?.address ||
        formData?.stateCode !== profileData?.stateCode ||
        formData?.districtCode !== profileData?.districtCode ||
        formData?.pinCode !== profileData?.pincode)
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (props.patients.transactionId) {
      setCounter(60);
      setShowVerifyModal(true);
    }
  }, [props.patients.transactionId]);

  useEffect(() => {
    let errorCount = 0;
    for (const [key, value] of Object.entries(formData)) {
      const validate = validation(key, value);
      if (validate.status === false) {
        errorCount++;
      }
    }
    if (errorCount > 0) {
      setError(true);
    } else {
      setError(false);
    }
  }, [formData]);

  return (
    <>
      <Header
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        title={"Edit Profile"}
        backButtonOnClick={() => {
          props.navigate(-1);
          if (props.patients.transactionId !== null) {
            props.setTransactionId(null);
          }
        }}
      />
      <section className="remaining-body-height flex-justify-content-center padding-larger inherit-parent-width ">
        <form
          className="inherit-parent-height max-width-588px display-flex  flex-direction-column flex-justify-content-space-between"
          onChange={(event) => {
            onChangeHandler(event);
          }}
          onSubmit={(event) => {
            event.preventDefault();
            let data = formData;
            delete data.email;
            delete data.phoneNumber;
            props.editProfile(data);
          }}
        >
          <div className="flex-justify-content-center">
            <RoundedProfilePicture
              data-cy={"edit-profile-pic"}
              size="large"
              editMode={true}
              setFile={(file) => {
                setFile(file);
              }}
              src={formData?.profilePhoto ? formData?.profilePhoto : null}
            />
          </div>
          <div className="flex-justify-content-center font-size-medium flex-direction-column padding-top-large flex-align-items-center">
            <div className="display-flex">
              {profileData?.kycStatus === "VERIFIED" ? (
                <>
                  <div className="padding-right-default">KYC Verified</div>
                  <CircleWithTickIcon
                    circleColor={"white"}
                    circleOutline={"#00a000"}
                    tickColor={"#00a000"}
                  />
                </>
              ) : (
                <>
                  <div className="padding-right-default">Self Declared</div>
                  <img src={errorIcon} alt="" />
                </>
              )}
            </div>
            <div className="padding-top-default">{`${profileData?.id}`}</div>
            {profileData?.kycStatus === "VERIFIED" && (
              <div className="padding-top-default padding-bottom-default">{`${profileData?.healthId}`}</div>
            )}
          </div>
          <div className="overflow-y-auto">
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default"
              label="first name"
              type="text"
              name="firstName"
              data-cy="firstName"
              disabled={profileData?.kycStatus === "VERIFIED"}
              value={formData?.name.first}
              validation={(value) => validation("firstName", value)}
            />
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default"
              label="middle name"
              type="text"
              name="middleName"
              data-cy="middleName"
              disabled={profileData?.kycStatus === "VERIFIED"}
              value={formData?.name.middle}
              validation={(value) => validation("middleName", value)}
            />

            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default"
              label="last name"
              type="text"
              name="lastName"
              disabled={profileData?.kycStatus === "VERIFIED"}
              data-cy="lastName"
              value={formData?.name.last}
              validation={(value) => validation("lastName", value)}
            />

            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default"
              label="Phone Number"
              type="tel"
              name="phoneNumber"
              onOutOfFocus={() => {
                if (
                  formData?.phoneNumber &&
                  formData?.phoneNumber !== `+91${profileData.mobile}` &&
                  Regex.phoneNumber.test(formData?.phoneNumber)
                ) {
                  setMode("phoneNumber");

                  props.generateOtpForUpdate(formData?.phoneNumber, "Phone");
                }
              }}
              value={formData?.phoneNumber}
              validation={(value) => validation("phoneNumber", value)}
            />

            <div className="display-flex flex-center-children-vertically">
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default padding-top-medium padding-right-default"
                label="Date Of Birth"
                type="date"
                disabled={profileData?.kycStatus === "VERIFIED"}
                size="half"
                name="dateOfBirth"
                value={formData?.dateOfBirth}
                validation={(value) => validation("dateOfBirth", value)}
              />
              <NativeSelect
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large padding-left-default"
                optionsClassName="text-transform-capitalize"
                name="gender"
                label="Gender"
                disabled={profileData?.kycStatus === "VERIFIED"}
                size="small"
                data-cy="gender"
                defaultValue={getGender()}
                options={["", "male", "female", "others", "unknown"]}
              />
            </div>
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default"
              label="Email"
              type="email"
              name="email"
              onOutOfFocus={() => {
                if (
                  formData?.email &&
                  formData?.email !== profileData.email &&
                  Regex.email?.test(formData?.email)
                ) {
                  setMode("email");
                  props.generateOtpForUpdate(formData?.email, "Email");
                }
              }}
              value={formData?.email}
              validation={(value) => validation("email", value)}
            />

            <TextArea
              type="text"
              className="padding-top-default font-color-secondary"
              value={formData?.address}
              name="address"
              label="Address"
              data-cy="address"
              required
              category="textarea"
            />

            <div className="display-flex flex-center-children-vertically padding-top-default">
              <NativeSelect
                labelClassName="letter-spacing-4-percentage"
                className="margin-right-default"
                selectClassName="text-transform-capitalize padding-top-large"
                optionsClassName="text-transform-capitalize"
                name="stateCode"
                label="State"
                required
                size="half"
                data-cy="stateCode"
                defaultValue={formData?.stateCode}
                options={stateOptionsWithCode}
              />
              <NativeSelect
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize  padding-top-large"
                optionsClassName="text-transform-capitalize"
                name="districtCode"
                label="District"
                required
                size="half"
                data-cy="districtCode"
                defaultValue={formData?.districtCode}
                options={stateCodeWithDistricts[formData?.stateCode]}
              />
            </div>
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default padding-top-default"
              label="pinCode"
              type="tel"
              required
              name="pinCode"
              value={formData?.pinCode}
              validation={(value) => validation("pinCode", value)}
            />
          </div>
          <div>
            <Button
              text="Save changes"
              data-cy="save-changes-button"
              boxShadow={false}
              className="margin-top-large"
              required
              type="submit"
              disabled={verifyForm()}
              loading={props.patients.loading}
            />
          </div>
        </form>
      </section>
      <Modal
        show={file !== null}
        canIgnore={true}
        onClose={() => {
          setFile(null);
        }}
        width="inherit-parent-width"
        maxWidth="max-width-800px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-90-percent"
      >
        <div
          data-cy="cropper-modal"
          className={`background-white inherit-parent-height border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <CropperModal
            className="border-radius-default"
            aspectRatio={1}
            OnBlobCreate={(blob) => {
              let reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onload = () => {
                const base64 = reader.result;
                setFormData({ ...formData, profilePhoto: base64 });
              };
            }}
            file={file}
            imageFormate="jpeg"
            exportSettings={{ height: 128, width: 128 }}
            setFile={() => {
              setFile(null);
            }}
          />
        </div>
      </Modal>
      <Modal
        show={showVerifyModal}
        onClose={() => {
          props.setTransactionId(null);
          setShowVerifyModal(!showVerifyModal);
        }}
        canIgnore={true}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div
          className="inherit-parent-width inherit-parent-height"
          style={{ flexBasis: "1" }}
        />
        <footer className="inherit-parent-width background-white">
          <div className="padding-large">
            <div className="text-align-center padding-bottom-default font-size-smaller">
              {mode === "phoneNumber"
                ? "CHANGE MOBILE NUMBER?"
                : "CHANGE EMAIL ADDRESS?"}
            </div>
            <div className="text-align-center font-size-small font-color-secondary margin-bottom-large">
              {mode === "phoneNumber" &&
                `This profile will be migrated to another Ninto account ${formData.phoneNumber}. Any other profiles in this account remains unchanged`}
            </div>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                props.verifyOtpForUpdate(
                  OTP,
                  mode === "phoneNumber" ? "Phone" : "Email",
                  mode === "phoneNumber" ? formData.phoneNumber : null,
                  props.navigate
                );
              }}
            >
              <OTPInputBox
                name="otp"
                autoFocus={true}
                value={OTP}
                setValue={(otpValue) => {
                  setOTP(otpValue);
                }}
                label={`Enter the OTP send to ${
                  mode === "phoneNumber"
                    ? formData?.phoneNumber
                    : formData?.email?.toLowerCase()
                }`}
                required
                className="margin-bottom-large"
              />
              <div className="display-flex flex-justify-content-space-between font-size-medium margin-bottom-larger">
                {counter && counter !== 0 && typeof counter === "number" ? (
                  <div>
                    {`${
                      counter?.toString().length === 1 ? "0" : ""
                    }${counter?.toString()} seconds`}
                  </div>
                ) : (
                  <div></div>
                )}
                <div
                  className={`${
                    counter ? "font-color-tertiary" : "font-color-primary"
                  }`}
                  onClick={() => {
                    if (counter && typeof counter === "number") {
                      return;
                    } else {
                      props.generateOtpForUpdate(
                        mode === "phoneNumber"
                          ? formData?.phoneNumber
                          : formData?.email,
                        mode === "phoneNumber" ? "Phone" : "Email"
                      );
                    }
                  }}
                >
                  Resend OTP
                </div>
              </div>
              <Button
                text="Continue"
                data-cy="continue-button"
                boxShadow={false}
                type="submit"
                disabled={OTP?.length === 6 ? false : true}
                loading={props.patients.loading}
              />
            </form>
          </div>
        </footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.status,
  patients: state.patients
});

const mapDispatchToProps = function () {
  return {
    editProfile: (data) => editProfile(data),
    generateOtpForUpdate: (data, authMode) =>
      generateOtpForUpdate(data, authMode),
    verifyOtpForUpdate: (value, authMode, data, navigate) =>
      verifyOtpForUpdate(value, authMode, data, navigate),
    setTransactionId: () => setTransactionId()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
