import React, { useState } from "react";
import "cropperjs/dist/cropper.css";
import logoutIcon from "../../assets/icons/logout.svg";
import "./Profile.css";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import ConnectABDM from "../ConnectABDM/ConnectABDM";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import Button from "../../front-end-global-components/components/Button/Button";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";

const Profile = (props) => {
  const [logoutModal, setLogoutModal] = useState(false);
  const data = [
    {
      name: "ABHA Address",
      description: "View and share your ABHA address",
      onClick: props.abdmHealthIdQrOnClick,
      "data-cy": "share-health-id"
    },
    {
      name: "Change Consent PIN",
      description: " Required to approve requests to share health data",
      onClick: () => {
        props.changeConsentPinOnClick();
      },
      "data-cy": "edit-profile"
    },
    {
      name: "Edit Profile",
      description: "Edit information linked with your ABHA Address",
      onClick: () => {
        props.editProfileOnClick();
      },
      "data-cy": "edit-profile"
    },
    {
      name: "ABHA Number",
      description: "Manage ABHA number information",
      onClick: () => {
        props.abhaNumberOnClick();
      },
      "data-cy": "abha-number"
    },
    {
      name: "Linked Facilities",
      description: "View your records from connected clinics",
      onClick: () => {
        props.linkedFacilitiesOnClick();
      },
      "data-cy": "linked-facilities"
    },
    {
      name: "Reset Password",
      description: "Reset password for you ABHA address ",
      onClick: () => {
        props.resetPasswordOnClick();
      },
      "data-cy": "reset-password"
    }
  ];
  return (
    <div className="display-flex flex-grow-1 flex-direction-column profile-setting-height">
      <div className="height-100-percent padding-horizontal-larger">
        <ConditionalRender condition={props.abdmLoggedIn}>
          {data.map((data, index) => (
            <div
              key={index}
              className="display-flex flex-grow-1 flex-align-items-center padding-top-large"
              data-cy={data["data-cy"]}
            >
              <div
                className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
                onClick={data.onClick}
              >
                <span className="font-size-medium font-family-gilroy-regular">
                  {data.name}
                </span>
                <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                  {data.description}
                </span>
              </div>
              <ArrowIcon />
            </div>
          ))}
        </ConditionalRender>
        <ConditionalRender condition={!props.abdmLoggedIn}>
          <div className="height-100-percent flex-justify-content-center flex-align-items-center ">
            <ConnectABDM
              connectOnClick={() => {
                props.navigate("/abdm/authentication");
              }}
            />
          </div>
        </ConditionalRender>
      </div>

      <div
        className="padding-left-larger padding-right-larger padding-top-medium padding-bottom-medium display-flex flex-align-items-center border-top-2px-solid-e5e5e5"
        data-cy="logout-profile"
      >
        <div
          className="flex-grow-1 flex-direction-column display-flex font-color-red"
          onClick={() => {
            setLogoutModal(true);
          }}
        >
          <span className="font-size-medium font-family-gilroy-regular">
            Logout Profile
          </span>
          <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
            Logout from Ninto
          </span>
        </div>
        <img src={logoutIcon} alt="" className="height-width-16px" />
      </div>
      <Modal
        show={logoutModal}
        canIgnore={true}
        onClose={() => {
          setLogoutModal(!logoutModal);
        }}
        width="inherit-parent-width"
        position="position-fixed bottom-0 left-0 right-0"
        maxWidth="max-width-630px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-color-white">
          <div className="font-size-medium  ">
            Are you sure you want to logout?
          </div>

          <section className="display-flex padding-top-larger ">
            <Button
              boxShadow={false}
              type="submit"
              variant={"danger"}
              text="Logout"
              className={"border-1px-e5e5e5 margin-right-small"}
              onClick={() => {
                props.logoutOnClick();
              }}
              data-cy={"confirm-logout-button"}
            />
            <Button
              boxShadow={false}
              text="Cancel"
              variant={"secondary"}
              onClick={() => {
                setLogoutModal(false);
              }}
              className={
                "font-color-secondary border-1px-e5e5e5 margin-left-small"
              }
              data-cy={"cancel-logout-button"}
            />
          </section>
        </section>
      </Modal>
    </div>
  );
};

export default Profile;
