export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://api.dev.ninto.in"
      : process.env.REACT_APP_STAGING === "uat"
      ? "https://api.uat.ninto.in"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://ninto.in/api"
      : "http://localhost:5001/karthealth-dev/asia-south1"
};

export const lockerSetup = {
  locker:
    process.env.REACT_APP_STAGING === "development"
      ? "test-ninto-locker"
      : process.env.REACT_APP_STAGING === "uat"
      ? "NINTO-UAT-LOCKER"
      : process.env.REACT_APP_STAGING === "production"
      ? ""
      : "test-ninto-locker"
};

export const shard = {
  value:
    process.env.REACT_APP_STAGING === "development"
      ? ["x", "y"]
      : process.env.REACT_APP_STAGING === "uat"
      ? ["x", "y"]
      : process.env.REACT_APP_STAGING === "production"
      ? ["x", "y"]
      : ["x", "y"]
};

export const logrocketInit = {
  key:
    process.env.REACT_APP_STAGING === "development"
      ? "byepo/ninto-patients-dev"
      : process.env.REACT_APP_STAGING === "uat"
      ? "byepo/ninto-patients-uat"
      : ""
};

export const app = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://patients.dev.ninto.in"
      : process.env.REACT_APP_STAGING === "uat"
      ? "https://patients.uat.ninto.in"
      : process.env.REACT_APP_STAGING === "local"
      ? "http://localhost:3001"
      : "https://patients.ninto.in"
};

export const MAX_PROFILE_COUNT = 4;

export const consentStatus = {
  pending: "REQUESTED",
  expired: "EXPIRED",
  approved: "GRANTED",
  denied: "DENIED",
  autoApproved: "AUTO-APPROVED"
};

export const validDocumentType = (data) => {
  let value;
  if (data) {
    switch (data) {
      case "OPConsultation":
        value = "consultation";
        break;
      case "Prescription":
        value = "prescriptions";
        break;
      case "Prescriptions":
        value = "prescriptions";
        break;
      case "DischargeSummary":
        value = "admissions";
        break;
      case "DiagnosticReport":
        value = "reports";
        break;
      case "ImmunizationRecord":
        value = "vaccine";
        break;
      case "HealthDocumentRecord":
        value = "others";
        break;
      case "WellnessRecord":
        value = "vital";
        break;
      case "prescription":
        value = "Prescription";
        break;
      case "admission":
        value = "DischargeSummary";
        break;
      case "report":
        value = "DiagnosticReport";
        break;
      case "others":
        value = "HealthDocumentRecord";
        break;
      case "vaccine":
        value = "ImmunizationRecord";
        break;
      case "consultation":
        value = "OPConsultation";
        break;
      case "vital":
        value = "WellnessRecord";
        break;
      default:
        break;
    }
  }
  return value;
};

export const genderOptions = ["", "male", "female", "others"];
export const bloodGroupOptions = [
  "",
  "A +ve",
  "A -ve",
  "B +ve",
  "B -ve",
  "AB +ve",
  "AB -ve",
  "O +ve",
  "O -ve"
];
export const AbdmConsentHealthInfoTypes = [
  "ImmunizationRecord",
  "DischargeSummary",
  "WellnessRecord",
  "HealthDocumentRecord",
  "Prescription",
  "DiagnosticReport",
  "OPConsultation"
];
// { option: "Health Document Record", value: "HealthDocumentRecord" },
// { option: "Prescription", value: "Prescription" },
// { option: "Diagnostic Reports", value: "DiagnosticReport" },
// { option: "Wellness Record", value: "WellnessRecord" },
// { option: "Immunization Record", value: "ImmunizationRecord" },
// { option: "Discharge Summary", value: "DischargeSummary" },
// { option: "OP Consultation", value: "OPConsultation" }

export const stateOptionsWithCode = [
  "",
  { option: "ANDAMAN AND NICOBAR ISLANDS", value: "35" },
  { option: "ANDHRA PRADESH", value: "28" },
  { option: "ARUNACHAL PRADESH", value: "12" },
  { option: "ASSAM", value: "18" },
  { option: "BIHAR", value: "10" },
  { option: "CHANDIGARH", value: "4" },
  { option: "CHHATTISGARH", value: "22" },
  { option: "DELHI", value: "7" },
  { option: "GOA", value: "30" },
  { option: "GUJARAT", value: "24" },
  { option: "HARYANA", value: "6" },
  { option: "HIMACHAL PRADESH", value: "2" },
  { option: "JAMMU AND KASHMIR", value: "1" },
  { option: "JHARKHAND", value: "20" },
  { option: "KARNATAKA", value: "29" },
  { option: "KERALA", value: "32" },
  { option: "LADAKH", value: "37" },
  { option: "LAKSHADWEEP", value: "31" },
  { option: "MADHYA PRADESH", value: "23" },
  { option: "MAHARASHTRA", value: "27" },
  { option: "MANIPUR", value: "14" },
  { option: "MEGHALAYA", value: "17" },
  { option: "MIZORAM", value: "15" },
  { option: "NAGALAND", value: "13" },
  { option: "ODISHA", value: "21" },
  { option: "PUDUCHERRY", value: "34" },
  { option: "PUNJAB", value: "3" },
  { option: "RAJASTHAN", value: "8" },
  { option: "SIKKIM", value: "11" },
  { option: "TAMIL NADU", value: "33" },
  { option: "TELANGANA", value: "36" },
  { option: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU", value: "38" },
  { option: "TRIPURA", value: "16" },
  { option: "UTTARAKHAND", value: "5" },
  { option: "UTTAR PRADESH", value: "9" },
  { option: "WEST BENGAL", value: "19" }
];

export const stateCodeWithDistricts = {
  1: [
    "",
    { option: "ANANTNAG", value: "1" },
    { option: "BANDIPORA", value: "623" },
    { option: "BARAMULLA", value: "3" },
    { option: "BUDGAM", value: "2" },
    { option: "DODA", value: "4" },
    { option: "GANDERBAL", value: "626" },
    { option: "JAMMU", value: "5" },
    { option: "KATHUA", value: "7" },
    { option: "KISHTWAR", value: "620" },
    { option: "KULGAM", value: "622" },
    { option: "KUPWARA", value: "8" },
    { option: "POONCH", value: "10" },
    { option: "PULWAMA", value: "11" },
    { option: "RAJOURI", value: "12" },
    { option: "RAMBAN", value: "621" },
    { option: "REASI", value: "627" },
    { option: "SAMBA", value: "624" },
    { option: "SHOPIAN", value: "625" },
    { option: "SRINAGAR", value: "13" },
    { option: "UDHAMPUR", value: "14" }
  ],
  2: [
    "",
    { option: "BILASPUR", value: "15" },
    { option: "CHAMBA", value: "16" },
    { option: "HAMIRPUR", value: "17" },
    { option: "KANGRA", value: "18" },
    { option: "KINNAUR", value: "19" },
    { option: "KULLU", value: "20" },
    { option: "LAHUL AND SPITI", value: "21" },
    { option: "MANDI", value: "22" },
    { option: "SHIMLA", value: "23" },
    { option: "SIRMAUR", value: "24" },
    { option: "SOLAN", value: "25" },
    { option: "UNA", value: "26" }
  ],
  3: [
    "",
    { option: "AMRITSAR", value: "27" },
    { option: "BARNALA", value: "605" },
    { option: "BATHINDA", value: "28" },
    { option: "FARIDKOT", value: "29" },
    { option: "FATEHGARH SAHIB", value: "30" },
    { option: "FAZILKA", value: "651" },
    { option: "FIROZEPUR", value: "31" },
    { option: "GURDASPUR", value: "32" },
    { option: "HOSHIARPUR", value: "33" },
    { option: "JALANDHAR", value: "34" },
    { option: "KAPURTHALA", value: "35" },
    { option: "LUDHIANA", value: "36" },
    { option: "MANSA", value: "37" },
    { option: "MOGA", value: "38" },
    { option: "PATHANKOT", value: "662" },
    { option: "PATIALA", value: "41" },
    { option: "RUPNAGAR", value: "42" },
    { option: "SANGRUR", value: "43" },
    { option: "S.A.S Nagar", value: "608" },
    { option: "SHAHID BHAGAT SINGH NAGAR", value: "40" },
    { option: "SRI MUKTSAR SAHIB", value: "39" },
    { option: "TARN TARAN", value: "609" }
  ],
  4: ["", { option: "CHANDIGARH", value: "44" }],
  5: [
    "",
    { option: "ALMORA", value: "45" },
    { option: "BAGESHWAR", value: "46" },
    { option: "CHAMOLI", value: "47" },
    { option: "CHAMPAWAT", value: "48" },
    { option: "DEHRADUN", value: "49" },
    { option: "HARIDWAR", value: "50" },
    { option: "NAINITAL", value: "51" },
    { option: "PAURI GARHWAL", value: "52" },
    { option: "PITHORAGARH", value: "53" },
    { option: "RUDRA PRAYAG", value: "54" },
    { option: "TEHRI GARHWAL", value: "55" },
    { option: "UDAM SINGH NAGAR", value: "56" },
    { option: "UTTAR KASHI", value: "57" }
  ],
  6: [
    "",
    { option: "AMBALA", value: "58" },
    { option: "BHIWANI", value: "59" },
    { option: "CHARKI DADRI", value: "701" },
    { option: "FARIDABAD", value: "60" },
    { option: "FATEHABAD", value: "61" },
    { option: "GURUGRAM", value: "62" },
    { option: "HISAR", value: "63" },
    { option: "JHAJJAR", value: "64" },
    { option: "JIND", value: "65" },
    { option: "KAITHAL", value: "66" },
    { option: "KARNAL", value: "67" },
    { option: "KURUKSHETRA", value: "68" },
    { option: "MAHENDRAGARH", value: "69" },
    { option: "NUH", value: "604" },
    { option: "PALWAL", value: "619" },
    { option: "PANCHKULA", value: "70" },
    { option: "PANIPAT", value: "71" },
    { option: "REWARI", value: "72" },
    { option: "ROHTAK", value: "73" },
    { option: "SIRSA", value: "74" },
    { option: "SONIPAT", value: "75" },
    { option: "YAMUNANAGAR", value: "76" }
  ],
  7: [
    "",
    { option: "CENTRAL", value: "77" },
    { option: "EAST", value: "78" },
    { option: "NEW DELHI", value: "79" },
    { option: "NORTH", value: "80" },
    { option: "NORTH EAST", value: "81" },
    { option: "NORTH WEST", value: "82" },
    { option: "SHAHDARA", value: "671" },
    { option: "SOUTH", value: "83" },
    { option: "SOUTH EAST", value: "670" },
    { option: "SOUTH WEST", value: "84" },
    { option: "WEST", value: "85" }
  ],
  8: [
    "",
    { option: "AJMER", value: "86" },
    { option: "ALWAR", value: "87" },
    { option: "BANSWARA", value: "88" },
    { option: "BARAN", value: "89" },
    { option: "BARMER", value: "90" },
    { option: "BHARATPUR", value: "91" },
    { option: "BHILWARA", value: "92" },
    { option: "BIKANER", value: "93" },
    { option: "BUNDI", value: "94" },
    { option: "CHITTORGARH", value: "95" },
    { option: "CHURU", value: "96" },
    { option: "DAUSA", value: "97" },
    { option: "DHOLPUR", value: "98" },
    { option: "DUNGARPUR", value: "99" },
    { option: "GANGANAGAR", value: "100" },
    { option: "HANUMANGARH", value: "101" },
    { option: "JAIPUR", value: "102" },
    { option: "JAISALMER", value: "103" },
    { option: "JALORE", value: "104" },
    { option: "JHALAWAR", value: "105" },
    { option: "JHUNJHUNU", value: "106" },
    { option: "JODHPUR", value: "107" },
    { option: "KARAULI", value: "108" },
    { option: "KOTA", value: "109" },
    { option: "NAGAUR", value: "110" },
    { option: "PALI", value: "111" },
    { option: "PRATAPGARH", value: "629" },
    { option: "RAJSAMAND", value: "112" },
    { option: "SAWAI MADHOPUR", value: "113" },
    { option: "SIKAR", value: "114" },
    { option: "SIROHI", value: "115" },
    { option: "TONK", value: "116" },
    { option: "UDAIPUR", value: "117" }
  ],
  9: [
    "",
    { option: "AGRA", value: "118" },
    { option: "ALIGARH", value: "119" },
    { option: "AMBEDKAR NAGAR", value: "121" },
    { option: "AMETHI", value: "640" },
    { option: "AMROHA", value: "154" },
    { option: "AURAIYA", value: "122" },
    { option: "AYODHYA", value: "140" },
    { option: "AZAMGARH", value: "123" },
    { option: "BAGHPAT", value: "124" },
    { option: "BAHRAICH", value: "125" },
    { option: "BALLIA", value: "126" },
    { option: "BALRAMPUR", value: "127" },
    { option: "BANDA", value: "128" },
    { option: "BARABANKI", value: "129" },
    { option: "BAREILLY", value: "130" },
    { option: "BASTI", value: "131" },
    { option: "BHADOHI", value: "179" },
    { option: "BIJNOR", value: "132" },
    { option: "BUDAUN", value: "133" },
    { option: "BULANDSHAHR", value: "134" },
    { option: "CHANDAULI", value: "135" },
    { option: "CHITRAKOOT", value: "136" },
    { option: "DEORIA", value: "137" },
    { option: "ETAH", value: "138" },
    { option: "ETAWAH", value: "139" },
    { option: "FARRUKHABAD", value: "141" },
    { option: "FATEHPUR", value: "142" },
    { option: "FIROZABAD", value: "143" },
    { option: "GAUTAM BUDDHA NAGAR", value: "144" },
    { option: "GHAZIABAD", value: "145" },
    { option: "GHAZIPUR", value: "146" },
    { option: "GONDA", value: "147" },
    { option: "GORAKHPUR", value: "148" },
    { option: "HAMIRPUR", value: "149" },
    { option: "HAPUR", value: "661" },
    { option: "HARDOI", value: "150" },
    { option: "HATHRAS", value: "163" },
    { option: "JALAUN", value: "151" },
    { option: "JAUNPUR", value: "152" },
    { option: "JHANSI", value: "153" },
    { option: "KANNAUJ", value: "155" },
    { option: "KANPUR DEHAT", value: "156" },
    { option: "KANPUR NAGAR", value: "157" },
    { option: "KASGANJ", value: "633" },
    { option: "KAUSHAMBI", value: "158" },
    { option: "KHERI", value: "159" },
    { option: "KUSHI NAGAR", value: "160" },
    { option: "LALITPUR", value: "161" },
    { option: "LUCKNOW", value: "162" },
    { option: "MAHARAJGANJ", value: "164" },
    { option: "MAHOBA", value: "165" },
    { option: "MAINPURI", value: "166" },
    { option: "MATHURA", value: "167" },
    { option: "MAU", value: "168" },
    { option: "MEERUT", value: "169" },
    { option: "MIRZAPUR", value: "170" },
    { option: "MORADABAD", value: "171" },
    { option: "MUZAFFARNAGAR", value: "172" },
    { option: "PILIBHIT", value: "173" },
    { option: "PRATAPGARH", value: "174" },
    { option: "PRAYAGRAJ", value: "120" },
    { option: "RAE BARELI", value: "175" },
    { option: "RAMPUR", value: "176" },
    { option: "SAHARANPUR", value: "177" },
    { option: "SAMBHAL", value: "659" },
    { option: "SANT KABEER NAGAR", value: "178" },
    { option: "SHAHJAHANPUR", value: "180" },
    { option: "SHAMLI", value: "660" },
    { option: "SHRAVASTI", value: "181" },
    { option: "SIDDHARTH NAGAR", value: "182" },
    { option: "SITAPUR", value: "183" },
    { option: "SONBHADRA", value: "184" },
    { option: "SULTANPUR", value: "185" },
    { option: "UNNAO", value: "186" },
    { option: "VARANASI", value: "187" }
  ],
  10: [
    "",
    { option: "ARARIA", value: "188" },
    { option: "ARWAL", value: "611" },
    { option: "AURANGABAD", value: "189" },
    { option: "BANKA", value: "190" },
    { option: "BEGUSARAI", value: "191" },
    { option: "BHAGALPUR", value: "192" },
    { option: "BHOJPUR", value: "193" },
    { option: "BUXAR", value: "194" },
    { option: "DARBHANGA", value: "195" },
    { option: "GAYA", value: "196" },
    { option: "GOPALGANJ", value: "197" },
    { option: "JAMUI", value: "198" },
    { option: "JEHANABAD", value: "199" },
    { option: "KAIMUR (BHABUA)", value: "200" },
    { option: "KATIHAR", value: "201" },
    { option: "KHAGARIA", value: "202" },
    { option: "KISHANGANJ", value: "203" },
    { option: "LAKHISARAI", value: "204" },
    { option: "MADHEPURA", value: "205" },
    { option: "MADHUBANI", value: "206" },
    { option: "MUNGER", value: "207" },
    { option: "MUZAFFARPUR", value: "208" },
    { option: "NALANDA", value: "209" },
    { option: "NAWADA", value: "210" },
    { option: "PASHCHIM CHAMPARAN", value: "211" },
    { option: "PATNA", value: "212" },
    { option: "PURBI CHAMPARAN", value: "213" },
    { option: "PURNIA", value: "214" },
    { option: "ROHTAS", value: "215" },
    { option: "SAHARSA", value: "216" },
    { option: "SAMASTIPUR", value: "217" },
    { option: "SARAN", value: "218" },
    { option: "SHEIKHPURA", value: "219" },
    { option: "SHEOHAR", value: "220" },
    { option: "SITAMARHI", value: "221" },
    { option: "SIWAN", value: "222" },
    { option: "SUPAUL", value: "223" },
    { option: "VAISHALI", value: "224" }
  ],
  11: [
    "",
    { option: "EAST DISTRICT", value: "225" },
    { option: "NORTH DISTRICT", value: "226" },
    { option: "SOUTH DISTRICT", value: "227" },
    { option: "WEST DISTRICT", value: "228" }
  ],
  12: [
    "",
    { option: "ANJAW", value: "628" },
    { option: "CHANGLANG", value: "229" },
    { option: "DIBANG VALLEY", value: "230" },
    { option: "EAST KAMENG", value: "231" },
    { option: "EAST SIANG", value: "232" },
    { option: "KAMLE", value: "718" },
    { option: "KRA DAADI", value: "677" },
    { option: "KURUNG KUMEY", value: "233" },
    { option: "LEPARADA", value: "724" },
    { option: "LOHIT", value: "234" },
    { option: "LONGDING", value: "666" },
    { option: "LOWER DIBANG VALLEY", value: "235" },
    { option: "LOWER SIANG", value: "719" },
    { option: "LOWER SUBANSIRI", value: "236" },
    { option: "NAMSAI", value: "678" },
    { option: "PAKKE KESSANG", value: "723" },
    { option: "PAPUM PARE", value: "237" },
    { option: "SHI YOMI", value: "725" },
    { option: "SIANG", value: "679" },
    { option: "TAWANG", value: "238" },
    { option: "TIRAP", value: "239" },
    { option: "UPPER SIANG", value: "240" },
    { option: "UPPER SUBANSIRI", value: "241" },
    { option: "WEST KAMENG", value: "242" },
    { option: "WEST SIANG", value: "243" }
  ],
  13: [
    "",
    { option: "DIMAPUR", value: "244" },
    { option: "KIPHIRE", value: "614" },
    { option: "KOHIMA", value: "245" },
    { option: "LONGLENG", value: "615" },
    { option: "MOKOKCHUNG", value: "246" },
    { option: "MON", value: "247" },
    { option: "PEREN", value: "613" },
    { option: "PHEK", value: "248" },
    { option: "TUENSANG", value: "249" },
    { option: "WOKHA", value: "250" },
    { option: "ZUNHEBOTO", value: "251" }
  ],
  14: [
    "",
    { option: "BISHNUPUR", value: "252" },
    { option: "CHANDEL", value: "253" },
    { option: "CHURACHANDPUR", value: "254" },
    { option: "IMPHAL EAST", value: "255" },
    { option: "IMPHAL WEST", value: "256" },
    { option: "JIRIBAM", value: "713" },
    { option: "KAKCHING", value: "711" },
    { option: "KAMJONG", value: "717" },
    { option: "KANGPOKPI", value: "712" },
    { option: "NONEY", value: "714" },
    { option: "PHERZAWL", value: "715" },
    { option: "SENAPATI", value: "257" },
    { option: "TAMENGLONG", value: "258" },
    { option: "TENGNOUPAL", value: "716" },
    { option: "THOUBAL", value: "259" },
    { option: "UKHRUL", value: "260" }
  ],
  15: [
    "",
    { option: "AIZAWL", value: "261" },
    { option: "CHAMPHAI", value: "262" },
    { option: "HNAHTHIAL", value: "726" },
    { option: "KHAWZAWL", value: "728" },
    { option: "KOLASIB", value: "263" },
    { option: "LAWNGTLAI", value: "264" },
    { option: "LUNGLEI", value: "265" },
    { option: "MAMIT", value: "266" },
    { option: "SAIHA", value: "267" },
    { option: "SAITUAL", value: "727" },
    { option: "SERCHHIP", value: "268" }
  ],
  16: [
    "",
    { option: "DHALAI", value: "269" },
    { option: "GOMATI", value: "654" },
    { option: "KHOWAI", value: "652" },
    { option: "NORTH TRIPURA", value: "270" },
    { option: "SEPAHIJALA", value: "653" },
    { option: "SOUTH TRIPURA", value: "271" },
    { option: "UNAKOTI", value: "655" },
    { option: "WEST TRIPURA", value: "272" }
  ],
  17: [
    "",
    { option: "EAST GARO HILLS", value: "273" },
    { option: "EAST JAINTIA HILLS", value: "657" },
    { option: "EAST KHASI HILLS", value: "274" },
    { option: "NORTH GARO HILLS", value: "656" },
    { option: "RI BHOI", value: "276" },
    { option: "SOUTH GARO HILLS", value: "277" },
    { option: "SOUTH WEST GARO HILLS", value: "663" },
    { option: "SOUTH WEST KHASI HILLS", value: "658" },
    { option: "WEST GARO HILLS", value: "278" },
    { option: "WEST JAINTIA HILLS", value: "275" },
    { option: "WEST KHASI HILLS", value: "279" }
  ],
  18: [
    "",
    { option: "BAKSA", value: "616" },
    { option: "BARPETA", value: "280" },
    { option: "BISWANATH", value: "705" },
    { option: "BONGAIGAON", value: "281" },
    { option: "CACHAR", value: "282" },
    { option: "CHARAIDEO", value: "708" },
    { option: "CHIRANG", value: "612" },
    { option: "DARRANG", value: "283" },
    { option: "DHEMAJI", value: "284" },
    { option: "DHUBRI", value: "285" },
    { option: "DIBRUGARH", value: "286" },
    { option: "DIMA HASAO", value: "299" },
    { option: "GOALPARA", value: "287" },
    { option: "GOLAGHAT", value: "288" },
    { option: "HAILAKANDI", value: "289" },
    { option: "HOJAI", value: "709" },
    { option: "JORHAT", value: "290" },
    { option: "KAMRUP", value: "291" },
    { option: "KAMRUP METRO", value: "618" },
    { option: "KARBI ANGLONG", value: "292" },
    { option: "KARIMGANJ", value: "293" },
    { option: "KOKRAJHAR", value: "294" },
    { option: "LAKHIMPUR", value: "295" },
    { option: "MAJULI", value: "706" },
    { option: "MARIGAON", value: "296" },
    { option: "NAGAON", value: "297" },
    { option: "NALBARI", value: "298" },
    { option: "SIVASAGAR", value: "300" },
    { option: "SONITPUR", value: "301" },
    { option: "SOUTH SALMARA MANCACHAR", value: "707" },
    { option: "TINSUKIA", value: "302" },
    { option: "UDALGURI", value: "617" },
    { option: "WEST KARBI ANGLONG", value: "710" }
  ],
  19: [
    "",
    { option: "24 PARAGANAS NORTH", value: "303" },
    { option: "24 PARAGANAS SOUTH", value: "304" },
    { option: "ALIPURDUAR", value: "664" },
    { option: "BANKURA", value: "305" },
    { option: "BIRBHUM", value: "307" },
    { option: "COOCHBEHAR", value: "308" },
    { option: "DARJEELING", value: "309" },
    { option: "DINAJPUR DAKSHIN", value: "310" },
    { option: "DINAJPUR UTTAR", value: "311" },
    { option: "HOOGHLY", value: "312" },
    { option: "HOWRAH", value: "313" },
    { option: "JALPAIGURI", value: "314" },
    { option: "JHARGRAM", value: "703" },
    { option: "KALIMPONG", value: "702" },
    { option: "KOLKATA", value: "315" },
    { option: "MALDAH", value: "316" },
    { option: "MEDINIPUR EAST", value: "317" },
    { option: "MEDINIPUR WEST", value: "318" },
    { option: "MURSHIDABAD", value: "319" },
    { option: "NADIA", value: "320" },
    { option: "PASCHIM BARDHAMAN", value: "704" },
    { option: "PURBA BARDHAMAN", value: "306" },
    { option: "PURULIA", value: "321" }
  ],
  20: [
    "",
    { option: "BOKARO", value: "322" },
    { option: "CHATRA", value: "323" },
    { option: "DEOGHAR", value: "324" },
    { option: "DHANBAD", value: "325" },
    { option: "DUMKA", value: "326" },
    { option: "EAST SINGHBUM", value: "327" },
    { option: "GARHWA", value: "328" },
    { option: "GIRIDIH", value: "329" },
    { option: "GODDA", value: "330" },
    { option: "GUMLA", value: "331" },
    { option: "HAZARIBAGH", value: "332" },
    { option: "JAMTARA", value: "333" },
    { option: "KHUNTI", value: "606" },
    { option: "KODERMA", value: "334" },
    { option: "LATEHAR", value: "335" },
    { option: "LOHARDAGA", value: "336" },
    { option: "PAKUR", value: "337" },
    { option: "PALAMU", value: "338" },
    { option: "RAMGARH", value: "607" },
    { option: "RANCHI", value: "339" },
    { option: "SAHEBGANJ", value: "340" },
    { option: "SARAIKELA KHARSAWAN", value: "341" },
    { option: "SIMDEGA", value: "342" },
    { option: "WEST SINGHBHUM", value: "343" }
  ],
  21: [
    "",
    { option: "ANUGUL", value: "344" },
    { option: "BALANGIR", value: "345" },
    { option: "BALESHWAR", value: "346" },
    { option: "BARGARH", value: "347" },
    { option: "BHADRAK", value: "348" },
    { option: "BOUDH", value: "349" },
    { option: "CUTTACK", value: "350" },
    { option: "DEOGARH", value: "351" },
    { option: "DHENKANAL", value: "352" },
    { option: "GAJAPATI", value: "353" },
    { option: "GANJAM", value: "354" },
    { option: "JAGATSINGHAPUR", value: "355" },
    { option: "JAJAPUR", value: "356" },
    { option: "JHARSUGUDA", value: "357" },
    { option: "KALAHANDI", value: "358" },
    { option: "KANDHAMAL", value: "359" },
    { option: "KENDRAPARA", value: "360" },
    { option: "KENDUJHAR", value: "361" },
    { option: "KHORDHA", value: "362" },
    { option: "KORAPUT", value: "363" },
    { option: "MALKANGIRI", value: "364" },
    { option: "MAYURBHANJ", value: "365" },
    { option: "NABARANGPUR", value: "366" },
    { option: "NAYAGARH", value: "367" },
    { option: "NUAPADA", value: "368" },
    { option: "PURI", value: "369" },
    { option: "RAYAGADA", value: "370" },
    { option: "SAMBALPUR", value: "371" },
    { option: "SONEPUR", value: "372" },
    { option: "SUNDARGARH", value: "373" }
  ],
  22: [
    "",
    { option: "BALOD", value: "646" },
    { option: "BALODA BAZAR", value: "644" },
    { option: "BALRAMPUR", value: "649" },
    { option: "BASTAR", value: "374" },
    { option: "BEMETARA", value: "650" },
    { option: "BIJAPUR", value: "636" },
    { option: "BILASPUR", value: "375" },
    { option: "DANTEWADA", value: "376" },
    { option: "DHAMTARI", value: "377" },
    { option: "DURG", value: "378" },
    { option: "GARIYABAND", value: "645" },
    { option: "GAURELLA PENDRA MARWAHI", value: "734" },
    { option: "JANJGIR-CHAMPA", value: "379" },
    { option: "JASHPUR", value: "380" },
    { option: "KABIRDHAM", value: "382" },
    { option: "KANKER", value: "381" },
    { option: "KONDAGAON", value: "643" },
    { option: "KORBA", value: "383" },
    { option: "KOREA", value: "384" },
    { option: "MAHASAMUND", value: "385" },
    { option: "MUNGELI", value: "647" },
    { option: "NARAYANPUR", value: "637" },
    { option: "RAIGARH", value: "386" },
    { option: "RAIPUR", value: "387" },
    { option: "RAJNANDGAON", value: "388" },
    { option: "SUKMA", value: "642" },
    { option: "SURAJPUR", value: "648" },
    { option: "SURGUJA", value: "389" }
  ],
  23: [
    "",
    { option: "AGAR MALWA", value: "667" },
    { option: "ALIRAJPUR", value: "639" },
    { option: "ANUPPUR", value: "390" },
    { option: "ASHOKNAGAR", value: "391" },
    { option: "BALAGHAT", value: "392" },
    { option: "BARWANI", value: "393" },
    { option: "BETUL", value: "394" },
    { option: "BHIND", value: "395" },
    { option: "BHOPAL", value: "396" },
    { option: "BURHANPUR", value: "397" },
    { option: "CHHATARPUR", value: "398" },
    { option: "CHHINDWARA", value: "399" },
    { option: "DAMOH", value: "400" },
    { option: "DATIA", value: "401" },
    { option: "DEWAS", value: "402" },
    { option: "DHAR", value: "403" },
    { option: "DINDORI", value: "404" },
    { option: "EAST NIMAR", value: "405" },
    { option: "GUNA", value: "406" },
    { option: "GWALIOR", value: "407" },
    { option: "HARDA", value: "408" },
    { option: "HOSHANGABAD", value: "409" },
    { option: "INDORE", value: "410" },
    { option: "JABALPUR", value: "411" },
    { option: "JHABUA", value: "412" },
    { option: "KATNI", value: "413" },
    { option: "KHARGONE", value: "414" },
    { option: "MANDLA", value: "415" },
    { option: "MANDSAUR", value: "416" },
    { option: "MORENA", value: "417" },
    { option: "NARSINGHPUR", value: "418" },
    { option: "NEEMUCH", value: "419" },
    { option: "NIWARI", value: "722" },
    { option: "PANNA", value: "420" },
    { option: "RAISEN", value: "421" },
    { option: "RAJGARH", value: "422" },
    { option: "RATLAM", value: "423" },
    { option: "REWA", value: "424" },
    { option: "SAGAR", value: "425" },
    { option: "SATNA", value: "426" },
    { option: "SEHORE", value: "427" },
    { option: "SEONI", value: "428" },
    { option: "SHAHDOL", value: "429" },
    { option: "SHAJAPUR", value: "430" },
    { option: "SHEOPUR", value: "431" },
    { option: "SHIVPURI", value: "432" },
    { option: "SIDHI", value: "433" },
    { option: "SINGRAULI", value: "638" },
    { option: "TIKAMGARH", value: "434" },
    { option: "UJJAIN", value: "435" },
    { option: "UMARIA", value: "436" },
    { option: "VIDISHA", value: "437" }
  ],
  24: [
    "",
    { option: "AHMADABAD", value: "438" },
    { option: "AMRELI", value: "439" },
    { option: "ANAND", value: "440" },
    { option: "ARVALLI", value: "672" },
    { option: "BANAS KANTHA", value: "441" },
    { option: "BHARUCH", value: "442" },
    { option: "BHAVNAGAR", value: "443" },
    { option: "BOTAD", value: "676" },
    { option: "CHHOTAUDEPUR", value: "668" },
    { option: "DANG", value: "444" },
    { option: "DEVBHUMI DWARKA", value: "674" },
    { option: "DOHAD", value: "445" },
    { option: "GANDHINAGAR", value: "446" },
    { option: "GIR SOMNATH", value: "675" },
    { option: "JAMNAGAR", value: "447" },
    { option: "JUNAGADH", value: "448" },
    { option: "KACHCHH", value: "449" },
    { option: "KHEDA", value: "450" },
    { option: "MAHESANA", value: "451" },
    { option: "MAHISAGAR", value: "669" },
    { option: "MORBI", value: "673" },
    { option: "NARMADA", value: "452" },
    { option: "NAVSARI", value: "453" },
    { option: "PANCH MAHALS", value: "454" },
    { option: "PATAN", value: "455" },
    { option: "PORBANDAR", value: "456" },
    { option: "RAJKOT", value: "457" },
    { option: "SABAR KANTHA", value: "458" },
    { option: "SURAT", value: "459" },
    { option: "SURENDRANAGAR", value: "460" },
    { option: "TAPI", value: "641" },
    { option: "VADODARA", value: "461" },
    { option: "VALSAD", value: "462" }
  ],
  27: [
    "",
    { option: "AHMEDNAGAR", value: "466" },
    { option: "AKOLA", value: "467" },
    { option: "AMRAVATI", value: "468" },
    { option: "AURANGABAD", value: "469" },
    { option: "BEED", value: "470" },
    { option: "BHANDARA", value: "471" },
    { option: "BULDHANA", value: "472" },
    { option: "CHANDRAPUR", value: "473" },
    { option: "DHULE", value: "474" },
    { option: "GADCHIROLI", value: "475" },
    { option: "GONDIA", value: "476" },
    { option: "HINGOLI", value: "477" },
    { option: "JALGAON", value: "478" },
    { option: "JALNA", value: "479" },
    { option: "KOLHAPUR", value: "480" },
    { option: "LATUR", value: "481" },
    { option: "MUMBAI", value: "482" },
    { option: "MUMBAI SUBURBAN", value: "483" },
    { option: "NAGPUR", value: "484" },
    { option: "NANDED", value: "485" },
    { option: "NANDURBAR", value: "486" },
    { option: "NASHIK", value: "487" },
    { option: "OSMANABAD", value: "488" },
    { option: "PALGHAR", value: "665" },
    { option: "PARBHANI", value: "489" },
    { option: "PUNE", value: "490" },
    { option: "RAIGAD", value: "491" },
    { option: "RATNAGIRI", value: "492" },
    { option: "SANGLI", value: "493" },
    { option: "SATARA", value: "494" },
    { option: "SINDHUDURG", value: "495" },
    { option: "SOLAPUR", value: "496" },
    { option: "THANE", value: "497" },
    { option: "WARDHA", value: "498" },
    { option: "WASHIM", value: "499" },
    { option: "YAVATMAL", value: "500" }
  ],
  28: [
    "",
    { option: "ANANTAPUR", value: "502" },
    { option: "CHITTOOR", value: "503" },
    { option: "EAST GODAVARI", value: "505" },
    { option: "GUNTUR", value: "506" },
    { option: "KRISHNA", value: "510" },
    { option: "KURNOOL", value: "511" },
    { option: "PRAKASAM", value: "517" },
    { option: "SPSR NELLORE", value: "515" },
    { option: "SRIKAKULAM", value: "519" },
    { option: "VISAKHAPATANAM", value: "520" },
    { option: "VIZIANAGARAM", value: "521" },
    { option: "WEST GODAVARI", value: "523" },
    { option: "Y.S.R.", value: "504" }
  ],
  29: [
    "",
    { option: "BAGALKOTE", value: "524" },
    { option: "BALLARI", value: "528" },
    { option: "BELAGAVI", value: "527" },
    { option: "BENGALURU RURAL", value: "526" },
    { option: "BENGALURU URBAN", value: "525" },
    { option: "BIDAR", value: "529" },
    { option: "CHAMARAJANAGARA", value: "531" },
    { option: "CHIKKABALLAPURA", value: "630" },
    { option: "CHIKKAMAGALURU", value: "532" },
    { option: "CHITRADURGA", value: "533" },
    { option: "DAKSHINA KANNADA", value: "534" },
    { option: "DAVANGERE", value: "535" },
    { option: "DHARWAD", value: "536" },
    { option: "GADAG", value: "537" },
    { option: "HASSAN", value: "539" },
    { option: "HAVERI", value: "540" },
    { option: "KALABURAGI", value: "538" },
    { option: "KODAGU", value: "541" },
    { option: "KOLAR", value: "542" },
    { option: "KOPPAL", value: "543" },
    { option: "MANDYA", value: "544" },
    { option: "MYSURU", value: "545" },
    { option: "RAICHUR", value: "546" },
    { option: "RAMANAGARA", value: "631" },
    { option: "SHIVAMOGGA", value: "547" },
    { option: "TUMAKURU", value: "548" },
    { option: "UDUPI", value: "549" },
    { option: "UTTARA KANNADA", value: "550" },
    { option: "VIJAYAPURA", value: "530" },
    { option: "YADGIR", value: "635" }
  ],
  30: [
    "",
    { option: "NORTH GOA", value: "551" },
    { option: "SOUTH GOA", value: "552" }
  ],
  31: ["", { option: "LAKSHADWEEP DISTRICT", value: "553" }],
  32: [
    "",
    { option: "ALAPPUZHA", value: "554" },
    { option: "ERNAKULAM", value: "555" },
    { option: "IDUKKI", value: "556" },
    { option: "KANNUR", value: "557" },
    { option: "KASARAGOD", value: "558" },
    { option: "KOLLAM", value: "559" },
    { option: "KOTTAYAM", value: "560" },
    { option: "KOZHIKODE", value: "561" },
    { option: "MALAPPURAM", value: "562" },
    { option: "PALAKKAD", value: "563" },
    { option: "PATHANAMTHITTA", value: "564" },
    { option: "THIRUVANANTHAPURAM", value: "565" },
    { option: "THRISSUR", value: "566" },
    { option: "WAYANAD", value: "567" }
  ],
  33: [
    "",
    { option: "ARIYALUR", value: "610" },
    { option: "CHENGALPATTU", value: "730" },
    { option: "CHENNAI", value: "568" },
    { option: "COIMBATORE", value: "569" },
    { option: "CUDDALORE", value: "570" },
    { option: "DHARMAPURI", value: "571" },
    { option: "DINDIGUL", value: "572" },
    { option: "ERODE", value: "573" },
    { option: "KALLAKURICHI", value: "729" },
    { option: "KANCHIPURAM", value: "574" },
    { option: "KANNIYAKUMARI", value: "575" },
    { option: "KARUR", value: "576" },
    { option: "KRISHNAGIRI", value: "577" },
    { option: "MADURAI", value: "578" },
    { option: "NAGAPATTINAM", value: "579" },
    { option: "NAMAKKAL", value: "580" },
    { option: "PERAMBALUR", value: "581" },
    { option: "PUDUKKOTTAI", value: "582" },
    { option: "RAMANATHAPURAM", value: "583" },
    { option: "RANIPET", value: "731" },
    { option: "SALEM", value: "584" },
    { option: "SIVAGANGA", value: "585" },
    { option: "TENKASI", value: "733" },
    { option: "THANJAVUR", value: "586" },
    { option: "THENI", value: "588" },
    { option: "THE NILGIRIS", value: "587" },
    { option: "THIRUVALLUR", value: "589" },
    { option: "THIRUVARUR", value: "590" },
    { option: "TIRUCHIRAPPALLI", value: "591" },
    { option: "TIRUNELVELI", value: "592" },
    { option: "TIRUPATHUR", value: "732" },
    { option: "TIRUPPUR", value: "634" },
    { option: "TIRUVANNAMALAI", value: "593" },
    { option: "TUTICORIN", value: "594" },
    { option: "VELLORE", value: "595" },
    { option: "VILLUPURAM", value: "596" },
    { option: "VIRUDHUNAGAR", value: "597" }
  ],
  34: [
    "",
    { option: "KARAIKAL", value: "598" },
    { option: "MAHE", value: "599" },
    { option: "PONDICHERRY", value: "600" },
    { option: "YANAM", value: "601" }
  ],
  35: [
    "",
    { option: "NICOBARS", value: "603" },
    { option: "NORTH AND MIDDLE ANDAMAN", value: "632" },
    { option: "SOUTH ANDAMANS", value: "602" }
  ],
  36: [
    "",
    { option: "ADILABAD", value: "501" },
    { option: "BHADRADRI KOTHAGUDEM", value: "690" },
    { option: "HYDERABAD", value: "507" },
    { option: "JAGITIAL", value: "681" },
    { option: "JANGOAN", value: "689" },
    { option: "JAYASHANKAR BHUPALAPALLY", value: "687" },
    { option: "JOGULAMBA GADWAL", value: "695" },
    { option: "KAMAREDDY", value: "685" },
    { option: "KARIMNAGAR", value: "508" },
    { option: "KHAMMAM", value: "509" },
    { option: "KUMURAM BHEEM ASIFABAD", value: "699" },
    { option: "MAHABUBABAD", value: "688" },
    { option: "MAHABUBNAGAR", value: "512" },
    { option: "MANCHERIAL", value: "684" },
    { option: "MEDAK", value: "513" },
    { option: "MEDCHAL MALKAJGIRI", value: "700" },
    { option: "MULUGU", value: "720" },
    { option: "NAGARKURNOOL", value: "694" },
    { option: "NALGONDA", value: "514" },
    { option: "NARAYANPET", value: "721" },
    { option: "NIRMAL", value: "680" },
    { option: "NIZAMABAD", value: "516" },
    { option: "PEDDAPALLI", value: "682" },
    { option: "RAJANNA SIRCILLA", value: "683" },
    { option: "RANGA REDDY", value: "518" },
    { option: "SANGAREDDY", value: "691" },
    { option: "SIDDIPET", value: "692" },
    { option: "SURYAPET", value: "696" },
    { option: "VIKARABAD", value: "698" },
    { option: "WANAPARTHY", value: "693" },
    { option: "WARANGAL RURAL", value: "522" },
    { option: "WARANGAL URBAN", value: "686" },
    { option: "YADADRI BHUVANAGIRI", value: "697" }
  ],
  37: [
    "",
    { option: "KARGIL", value: "6" },
    { option: "LEH LADAKH", value: "9" }
  ],
  38: [
    "",
    { option: "DADRA AND NAGAR HAVELI", value: "465" },
    { option: "DAMAN", value: "463" },
    { option: "DIU", value: "464" }
  ]
};

export const remoteConfigStatus = {
  ALLOW_ALL: "ALLOW_ALL",
  UNDER_MAINTENANCE: "UNDER_MAINTENANCE",
  NOT_AVAILABLE: "NOT_AVAILABLE"
};

export const getValidAbhaMethod = (data) => {
  let value;

  switch (data) {
    case "Phone":
    case "Email":
      value = "mobileEmail";
      break;

    case "ABHA Address":
      value = "healthId";
      break;

    case "ABHA Number":
      value = "healthIdNumber";
      break;

    default:
      value = "mobileEmail";
      break;
  }
  return value;
};

export const getValidAuthMode = (data) => {
  let value;

  switch (data) {
    case "Phone":
      value = "MOBILE_OTP";
      break;

    case "Email":
      value = "EMAIL_OTP";
      break;

    case "Password":
      value = "PASSWORD";
      break;

    case "Aadhar":
      value = "AADHAAR_OTP";
      break;

    default:
      value = "MOBILE_OTP";
      break;
  }
  return value;
};

export const getValidAbhaNumber = (number) => {
  return (
    number.substring(0, 2) +
    "-" +
    number.substring(2, 6) +
    "-" +
    number.substring(6, 10) +
    "-" +
    number.substring(10, 14)
  );
};

export const getValidGender = (data) => {
  let value;
  switch (data) {
    case "M":
      value = "male";
      break;
    case "F":
      value = "female";
      break;
    case "U":
      value = "unknown";
      break;
    default:
      value = "others";
      break;
  }
  return value;
};

export const getValidDateOfBirth = (dateOfBirth) => {
  return dateOfBirth?.year + "-" + dateOfBirth?.month + "-" + dateOfBirth?.date;
};
