import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import "./AbdmHealthIdQr.css";
import { throwError } from "../../services/error";
import { getPatientQrDetails } from "../../services/api";
import { setErrorStatus } from "../../redux/status/actions";
import { DownloadIcon } from "../../front-end-global-components/assets/assets";
import shareIcon from "../../front-end-global-components/assets/shareIcon.svg";

function AbdmHealthIdQr(props) {
  const [qrDataUriLoading, setQrDataUriLoading] = useState(false);
  const [dataUrl, setDataUrl] = useState(null);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "image.jpg";
    link.click();
  };

  const handleShare = async () => {
    const blob = await (await fetch(dataUrl)).blob();
    const file = new File([blob], "ABHA Address details.png", {
      type: blob.type
    });
    if (
      !!navigator &&
      navigator.canShare &&
      navigator.canShare({ files: [file] })
    ) {
      await navigator?.share({
        title: "Ninto",
        text: "Ninto - ABHA Address",
        url: window.location.href,
        files: [file]
      });
    }
  };

  const getData = async () => {
    setQrDataUriLoading(true);
    try {
      const result = await getPatientQrDetails(
        props.auth.data.accessToken,
        props.patients.accessToken[props.patients.currentProfile]
      );
      if (
        result &&
        result.success === true &&
        result.data &&
        typeof result.data === "string"
      ) {
        const imageDataUri = result.data;
        setDataUrl(imageDataUri);
      } else {
        throwError("custom", "failed to fetch");
      }
    } catch (error) {
      console.error(error);
      props.setErrorStatus({
        code: "API",
        name: "API",
        message: "Unable to get QR details"
      });
    }
    setQrDataUriLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.patients.accessToken]);

  return (
    <div className="inherit-parent-height">
      <Header
        title="ABHA Address"
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
      />
      <div className="inherit-parent-width text-align-center padding-larger">
        ABHA ADDRESS INFORMATION
      </div>
      <main className="display-flex flex-justify-content-center overflow-hidden padding-top-larger padding-bottom-larger position-relative ">
        {qrDataUriLoading === true ? (
          <div className="background-color-white padding-left-default padding-right-default padding-top-small padding-bottom-small border-radius-default ">
            <div className="qr-loader" />
          </div>
        ) : qrDataUriLoading === false && dataUrl ? (
          <div className="flex-justify-content-center ">
            <img
              className="inherit-parent-height width-250-percent"
              alt="qr"
              src={dataUrl}
            />
          </div>
        ) : (
          <p>No QR code Details</p>
        )}
      </main>
      {qrDataUriLoading === false && dataUrl && (
        <div className="display-flex inherit-parent-width overflow-hidden flex-justify-content-space-around border-top-2px-solid-e5e5e5 position-absolute position-bottom-0">
          <div
            className="padding-larger display-flex"
            onClick={() => {
              handleDownload();
            }}
          >
            <div className="padding-right-default">Download</div>
            <div>
              <DownloadIcon />
            </div>
          </div>
          <div
            className="padding-larger display-flex"
            onClick={() => {
              handleShare();
            }}
          >
            <div className="padding-right-default">Share</div>
            <img
              data-cy="share-icon"
              className={`cursor-pointer margin-right-medium ${
                props.removeResponsive ? "" : "adaptive-support-icon-height"
              } support-icon-height `}
              src={shareIcon}
              alt="share-icon"
              width="auto"
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  abdm: state.abdm,
  auth: state.auth,
  profile: state.profile,
  patients: state.patients
});

const mapDispatchToProps = () => {
  return {
    setErrorStatus: (payload) => setErrorStatus(payload)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AbdmHealthIdQr);
