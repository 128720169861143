import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";
import { setSelectedLinkedFacility } from "../../redux/patients/actions";
import { searchAbdmClinic } from "../../redux/abdm/actions";
import { ClinicCard, DocumentCard } from "../../components/Cards/Cards";

const LinkedFacilities = (props) => {
  return (
    <>
      <Header
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        title={"Linked Facilities"}
        backButtonOnClick={() => {
          if (props.patients.selectedLinkedFacility) {
            props.setSelectedLinkedFacility(null);
          } else {
            props.navigate(-1);
          }
        }}
      />

      <ConditionalRender
        condition={typeof props.patients.selectedLinkedFacility !== "string"}
      >
        <div className=" padding-horizontal-larger">
          <ConditionalRender
            condition={
              props.patients.currentProfile &&
              props.patients.careContexts &&
              Object.values(
                props.patients.careContexts[props.patients.currentProfile]
              ).filter((data) => data.hip.type === "HIP_AND_HIU").length >= 1
            }
          >
            <div className=" font-size-small display-flex flex-justify-content-center padding-top-large text-uppercase">
              Hospitals / Clinic
            </div>
          </ConditionalRender>

          {props.patients.currentProfile &&
            props.patients.careContexts?.[props.patients.currentProfile] &&
            Object.values(
              props.patients.careContexts[props.patients.currentProfile]
            )
              .filter((data) => data.hip.type === "HIP_AND_HIU")
              .map((data) => (
                <div
                  className=" display-flex flex-align-items-center flex-justify-content-space-between padding-vertical-medium"
                  onClick={() => {
                    props.searchAbdmClinic(data.hip.name);
                    props.setSelectedLinkedFacility(data.hip.id);
                  }}
                >
                  <div className=" display-flex flex-align-items-center">
                    <RoundedProfilePicture
                      data-cy={`profilePic-${""}`}
                      size={"medium"}
                      src={""}
                      alt=""
                      loading={props.patients.loading}
                    />

                    <div className=" padding-left-large font-color-secondary">
                      <div className=" font-size-default padding-top-small">
                        {data.hip.name}
                      </div>
                      {/* <div className=" font-size-small padding-top-small">
                      {data.hip.id}
                    </div> */}
                    </div>
                  </div>
                  <ArrowIcon />
                </div>
              ))}

          <ConditionalRender
            condition={
              props.patients.currentProfile &&
              props.patients.careContexts?.[props.patients.currentProfile] &&
              Object.values(
                props.patients.careContexts[props.patients.currentProfile]
              ).filter((data) => data.hip.type === "HEALTH_LOCKER").length >= 1
            }
          >
            <div className=" font-size-small display-flex flex-justify-content-center padding-top-larger text-uppercase">
              Health Lockers
            </div>
          </ConditionalRender>

          {props.patients.currentProfile &&
            props.patients.careContexts?.[props.patients.currentProfile] &&
            Object.values(
              props.patients.careContexts[props.patients.currentProfile]
            )
              .filter((data) => data.hip.type === "HEALTH_LOCKER")
              .map((data) => (
                <div
                  className=" display-flex flex-align-items-center flex-justify-content-space-between padding-vertical-medium"
                  onClick={() => {
                    props.searchAbdmClinic(data.hip.name);
                    props.setSelectedLinkedFacility(data.hip.id);
                  }}
                >
                  <div className=" display-flex flex-align-items-center">
                    <RoundedProfilePicture
                      data-cy={`profilePic-${""}`}
                      size={"medium"}
                      src={""}
                      alt=""
                      loading={props.patients.loading}
                    />

                    <div className=" padding-left-large font-color-secondary">
                      <div className=" font-size-default padding-top-small">
                        {data.hip.name}
                      </div>
                      {/* <div className=" font-size-small padding-top-small">
                      {data.hip.id}
                    </div> */}
                    </div>
                  </div>
                  <ArrowIcon />
                </div>
              ))}
        </div>

        <ConditionalRender
          condition={
            props.patients.currentProfile &&
            props.patients.careContexts?.[props.patients.currentProfile] &&
            Object.values(
              props.patients.careContexts[props.patients.currentProfile]
            ).length === 0
          }
        >
          <div className=" font-family-gilroy-medium font-color-secondary display-flex flex-align-items-center flex-justify-content-center remaining-body-height">
            No linked facilities found
          </div>
        </ConditionalRender>
      </ConditionalRender>

      <ConditionalRender
        condition={typeof props.patients.selectedLinkedFacility === "string"}
      >
        <ClinicCard
          // loading={"loading"}
          clinicDetails={
            props.patients.selectedLinkedFacility &&
            props.abdm?.clinics?.search?.results?.[
              props.patients.selectedLinkedFacility
            ]
          }
          // logoURL={null}
        />
        <div className=" padding-left-small padding-right-large ">
          <div className=" text-align-center font-size-small text-uppercase padding-top-large padding-bottom-larger">
            linked records
          </div>

          {props.patients.careContexts?.[props.patients.currentProfile]?.[
            props.patients.selectedLinkedFacility
          ]?.careContexts.map((data) => (
            <DocumentCard data={data} />
          ))}
        </div>
      </ConditionalRender>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.status,
  patients: state.patients,
  abdm: state.abdm
});

const mapDispatchToProps = function () {
  return {
    setSelectedLinkedFacility: (linkedFacilityId) =>
      setSelectedLinkedFacility(linkedFacilityId),
    searchAbdmClinic: (searchKey) => searchAbdmClinic(searchKey)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedFacilities);
