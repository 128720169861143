import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import { QrReader } from "react-qr-reader";
import "./ScanClinic.css";
import {
  clearABdmConnectDetails,
  getScannedClinicDetails
} from "../../redux/abdm/actions";

// clear ABDM connect details
function ScanClinic(props) {
  useEffect(() => {
    props.clearABdmConnectDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <section data-cy="scan-screen" className="inherit-parent-height">
      <Header
        title="Scan your clinic QR code"
        backButtonOnClick={() => {
          props.navigate("/");
        }}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
      />
      <main
        className="max-width-588px remaining-body-height overflow-hidden"
        style={{
          flexBasis: "100%"
        }}
        // onClick={() => {
        // props.getScannedClinicDetails("SURIYA-HIP", null, props.navigate);
        // }}
      >
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result && props.abdm.loading === false) {
              if (result && result.text) {
                const data = new URL(result.text).searchParams
                  .toString()
                  .split("&")
                  .reduce((previous, current) => {
                    const [key, value] = current.split("=");
                    if (key === "hip-id") {
                      previous["hipId"] = value;
                    } else if (key === "counter-id") {
                      previous["hipCode"] = value;
                    }
                    return previous;
                  }, {});
                props.getScannedClinicDetails(
                  data.hipId,
                  data.hipCode,
                  props.navigate
                );
              } else if (result && result.t) {
                const data = new URL(result.text).searchParams
                  .toString()
                  .split("&")
                  .reduce((previous, current) => {
                    const [key, value] = current.split("=");
                    if (key === "hip-id") {
                      previous["hipId"] = value;
                    } else if (key === "counter-id") {
                      previous["hipCode"] = value;
                    }
                    return previous;
                  }, {});
                props.getScannedClinicDetails(
                  data.hipId,
                  data.hipCode,
                  props.navigate
                );
              }
            }
          }}
          className="inherit-parent-height "
          containerStyle={{
            height: "100%",
            paddingTop: "0"
          }}
          videoContainerStyle={{
            height: "100%",
            paddingTop: "0"
          }}
          videoStyle={{
            position: "none",
            width: "none"
          }}
          style={{ height: "100%" }}
        />
      </main>
      {props.abdm.loading === true && (
        <div className="position-absolute-center-self inherit-parent-height inherit-parent-width dimmed-overlay font-size-medium flex-place-children-page-center ">
          <div className="background-color-white padding-left-medium padding-right-medium padding-top-default padding-bottom-default border-radius-default ">
            <div className="loader" />
          </div>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = function (state) {
  return {
    abdm: state.abdm
  };
};

const mapDispatchToProps = function () {
  return {
    clearABdmConnectDetails: () => clearABdmConnectDetails(),
    getScannedClinicDetails: (hipId, hipCode, push) =>
      getScannedClinicDetails(hipId, hipCode, push)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScanClinic);
