import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/actions";
import store from "../store/store";

export const appActionTypes = {
  PUT_ONLINE: "PUT_ONLINE",
  PUT_NOTIFICATION_PERMISSION: "PUT_NOTIFICATION_PERMISSION"
};

function* putOnlineWorker(action) {
  try {
    if (store.getState().app.online !== action.payload.online) {
      yield put({
        type: "SET_ONLINE",
        payload: {
          online: action.payload.online
        }
      });
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* putNotificationWorker(action) {
  try {
    yield put({
      type: "SET_NOTIFICATION_PERMISSION",
      payload: {
        notification: action.payload.notification
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* appWatcher() {
  yield all([
    takeEvery("PUT_ONLINE", putOnlineWorker),
    takeEvery("PUT_NOTIFICATION_PERMISSION", putNotificationWorker)
  ]);
}
