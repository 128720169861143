import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "../../front-end-global-components/components/Button/Button";
import Header from "../../front-end-global-components/components/Header/Header";
import {
  clearABdmConnectDetails,
  shareProfile
} from "../../redux/abdm/actions";
import { ClinicCard } from "../../components/Cards/Cards";
import { getValidGender } from "../../utils/constants";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import Modal from "../../front-end-global-components/components/Modal/Modal";

function ScannedClinicDetails(props) {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  console.log(props.abdm);

  const patientData = [
    {
      name: "VERIFIED MOBILE NUMBER",
      value: props.patients?.healthIdData[props.patients?.currentProfile].mobile
        ? `+91${
            props.patients?.healthIdData[props.patients?.currentProfile].mobile
          }`
        : "Nill"
    },
    {
      name: "ABHA ADDRESS",
      value: props.patients?.healthIdData[props.patients?.currentProfile].id
        ? props.patients?.healthIdData[props.patients?.currentProfile].id
        : "Nill"
    },
    {
      name: "ABHA NUMBER",
      value: props.patients?.healthIdData[props.patients?.currentProfile]
        .healthId
        ? props.patients?.healthIdData[props.patients?.currentProfile].healthId
        : "Nill"
    },
    {
      name: "NAME",
      value: props.patients?.healthIdData[props.patients?.currentProfile]
        .fullName
        ? props.patients?.healthIdData[props.patients?.currentProfile].fullName
        : "Nill"
    },
    {
      name: "GENDER",
      value: props.patients?.healthIdData[props.patients?.currentProfile].gender
        ? getValidGender(
            props.patients?.healthIdData[props.patients?.currentProfile].gender
          )
        : "Nill"
    },
    {
      name: "YEAR OF BIRTH",
      value: props.patients?.healthIdData[props.patients?.currentProfile]
        .dateOfBirth?.year
        ? props.patients?.healthIdData[props.patients?.currentProfile]
            .dateOfBirth?.year
        : "Nill"
    }
  ];

  return (
    <>
      <section data-cy="scan-screen" className="inherit-parent-height">
        <Header
          title="Connect with your clinic"
          backButtonOnClick={() => {
            props.navigate("/scanClinic");
          }}
          supportIconOnClick={() => {
            props.navigate("/query");
          }}
        />
        <section className="remaining-body-height max-width-588px margin-horizontal-auto ">
          <ClinicCard
            loading={props.abdm.loading}
            clinicDetails={props.abdm.connect.clinicDetails}
            logoURL={null}
          />

          <section className="remaining-height-of-abdm-clinic-care-context-section display-flex flex-justify-content-space-between flex-direction-column">
            <div>
              <div className="padding-top-default">
                {patientData.map((data, index) => (
                  <div
                    key={index}
                    className="padding-left-large padding-right-large padding-bottom-default padding-top-default font-size-medium"
                  >
                    <div className="padding-bottom-small">{data.name}</div>
                    <div
                      className={`${
                        data.name === "ABHA ADDRESS" ? "" : "text-capitalize"
                      }  font-size-small`}
                    >
                      {data.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="inherit-parent-width display-flex text-align-left padding-left-large font-size-small font-family-gilroy-medium flex-align-items-center white-space-no-wrap">
                <CheckBox
                  name="agreeTo"
                  onChange={(event) => {
                    setIsAgreed({
                      agreeTo: event.target.checked
                    });
                  }}
                  label="I agree with"
                  required={true}
                />

                <div
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="text-underline-hover font-color-primary padding-left-small cursor-pointer"
                >
                  ABDM's terms and conditions
                </div>
              </div>
              <div className="inherit-parent-width padding-left-large padding-right-large padding-left-large padding-top-larger padding-bottom-larger">
                <Button
                  text="Continue"
                  data-cy="continue-to-fetch-records"
                  boxShadow={false}
                  onClick={() => {
                    if (isAgreed) {
                      props.shareProfile(props.navigate);
                    }
                  }}
                  loading={props.abdm.loading}
                  disabled={!isAgreed}
                />
              </div>
            </div>
          </section>
        </section>
      </section>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(!showModal);
        }}
        canIgnore={true}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-50-percentage"
      >
        <div className="background-white padding-default font-family-gilroy-medium user-select-none">
          <div className="inherit-parent-width inherit-parent-height padding-bottom-large">
            <div className="text-align-center padding-large font-color-secondary">
              TERMS AND CONDITION
            </div>
            <div style={{ height: "420px" }} className="hide-scroll-bar">
              <p
                style={{ lineHeight: "20px", textAlign: "justify" }}
                className="padding-bottom-default padding-left-default padding-right-default font-color-secondary"
              >
                &nbsp; &nbsp; &nbsp; &nbsp; I, hereby declare that I am
                voluntarily sharing my Aadhaar Number and demographic
                information issued by UIDAI, with National Health Authority
                (NHA) for the sole purpose of creation of ABHA number . I
                understand that my ABHA number can be used and shared for
                purposes as may be notified by ABDM from time to time including
                provision of healthcare services. Further, I am aware that my
                personal identifiable information (Name, Address, Age, Date of
                Birth, Gender and Photograph) may be made available to the
                entities working in the National Digital Health Ecosystem (NDHE)
                which inter alia includes stakeholders and entities such as
                healthcare professionals (e.g. doctors), facilities (e.g.
                hospitals, laboratories) and data fiduciaries (e.g. health
                programmes), which are registered with or linked to the Ayushman
                Bharat Digital Mission (ABDM), and various processes there
                under.
              </p>
              <p
                style={{ lineHeight: "20px", textAlign: "justify" }}
                className="padding-bottom-larger padding-left-default padding-right-default font-color-secondary"
              >
                I authorize NHA to use my Aadhaar number for performing Aadhaar
                based authentication with UIDAI as per the provisions of the
                Aadhaar (Targeted Delivery of Financial and other Subsidies,
                Benefits and Services) Act, 2016 for the aforesaid purpose. I
                understand that UIDAI will share my e-KYC details, or response
                of “Yes” with NHA upon successful authentication. I have been
                duly informed about the option of using other IDs apart from
                Aadhaar; however, I consciously choose to use Aadhaar number for
                the purpose of availing benefits across the NDHE. I am aware
                that my personal identifiable information excluding Aadhaar
                number / VID number can be used and shared for purposes as
                mentioned above. I reserve the right to revoke the given consent
                at any point of time as per provisions of Aadhaar Act and
                Regulations.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    abdm: state.abdm,
    patients: state.patients
  };
};

const mapDispatchToProps = function () {
  return {
    clearABdmConnectDetails: () => clearABdmConnectDetails(),
    shareProfile: (navigate) => shareProfile(navigate)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScannedClinicDetails);
