import store from "../store/store";
import { patientsActionTypes } from "./saga";
/**
 * @module DocumentsSaga
 */

/**
 * set ABDM Auth creds
 * @param {string} requestMethod
 * @param {string} transactionId
 */
export function setAbdmAuthCredentials(requestMethod, transactionId) {
  store.dispatch({
    type: patientsActionTypes.ADD_ABDM_AUTH_CREDENTIALS,
    payload: {
      requestMethod: requestMethod,
      transactionId: transactionId
    }
  });
}

/**
 * set ABDM Auth creds
 * @param {string} data
 */
export function abdmResetAbhaPassword(password, navigate) {
  store.dispatch({
    type: patientsActionTypes.RESET_ABHA_PASSWORD,
    payload: {
      password: password,
      navigate: navigate
    }
  });
}

/**
 * set ABDM Auth modes
 * @param {string} data
 */
export function setAuthModes(data) {
  store.dispatch({
    type: patientsActionTypes.SET_AUTH_MODES,
    payload: {
      data: data
    }
  });
}

/**
 * set ABDM Auth creds
 * @param {string} value
 * @param {string} authMode
 */
export function generateOtpForUpdate(value, authMode) {
  store.dispatch({
    type: patientsActionTypes.GENERATE_OTP_TO_UPDATE_PHONE_NUMBER_OR_EMAIL,
    payload: {
      value: value,
      authMode: authMode
    }
  });
}

/**
 * set ABDM Auth creds
 * @param {string} value
 * @param {string} authMode
 */
export function verifyOtpForUpdate(value, authMode, data, navigate) {
  store.dispatch({
    type: patientsActionTypes.VERIFY_OTP_TO_UPDATE_PHONE_NUMBER_OR_EMAIL,
    payload: {
      value: value,
      authMode: authMode,
      data: data,
      navigate: navigate
    }
  });
}
/**
 * clear un used healthId
 */
export function clearUnusedHealthId() {
  store.dispatch({
    type: patientsActionTypes.CLEAR_ABDM_UNUSED_HEALTH_ID
  });
}
/**
 * create patients profiles
 * @param {string} profileName
 */
export function createDemographic(profileName) {
  store.dispatch({
    type: patientsActionTypes.CREATE_PATIENTS_DEMOGRAPHIC,
    payload: {
      profileName: profileName
    }
  });
}
/**
 * add patients profiles
 * @param {object} data
 */
export function addPatientsDemographics(data) {
  store.dispatch({
    type: patientsActionTypes.ADD_PATIENTS_DEMOGRAPHICS,
    payload: data
  });
}
/**
 * add patients subscription
 * @param {object} data
 */
export function addPatientsSubscription(data) {
  store.dispatch({
    type: patientsActionTypes.ADD_PATIENTS_SUBSCRIPTION,
    payload: { data: data }
  });
}
/**
 * add patients subscription
 * @param {object} data
 */
export function addAutoApprovalPolicies(data) {
  store.dispatch({
    type: patientsActionTypes.ADD_AUTO_APPROVAL_POLICIES,
    payload: { data: data }
  });
}
/**
 * switch patients profile
 * @param {string} profileId
 */
export function switchPatientProfile(profileId) {
  store.dispatch({
    type: patientsActionTypes.SWITCH_PATIENT_PROFILE,
    payload: {
      profileId: profileId
    }
  });
}
/**
 * set transactionId
 * @param {string} transactionId
 */
export function setTransactionId(transactionId) {
  store.dispatch({
    type: patientsActionTypes.GET_TRANSACTION_ID,
    payload: {
      transactionId: transactionId
    }
  });
}
/**
 * send OTP for ABD auth
 * @param {string} authType
 * @param {string} method
 * @param {string} value
 */
export function sendOtpForAbdmAuthentication(auth, authType, method, value) {
  store.dispatch({
    type: patientsActionTypes.SEND_OTP_FOR_ABDM_AUTHENTICATION,
    payload: {
      auth: auth,
      method: method,
      authType: authType,
      value: value
    }
  });
}
/**
 * verify OTP for ABD auth
 * @param {string} auth
 * @param {string} method
 * @param {string} otp
 * @param {string} push
 */
export function verifyAbdmAuthenticationOtp(
  auth,
  method,
  otp,
  push,
  skipHealthInfo,
  healthId
) {
  store.dispatch({
    type: patientsActionTypes.VERIFY_ABDM_AUTHENTICATION_OTP,
    payload: {
      auth: auth,
      method: method,
      otp: otp,
      push: push,
      skipHealthInfo: skipHealthInfo,
      healthId: healthId
    }
  });
}

export function getModesForAbdmAuth(auth, method, value, yearOfBirth) {
  store.dispatch({
    type: patientsActionTypes.GET_MODES_FOR_ABDM_AUTH,
    payload: {
      auth: auth,
      method: method,
      value: value,
      yearOfBirth: yearOfBirth
    }
  });
}

/**
 * create healthId
 * @param {string} data
 * @param {string} requestMethod
 * @param {string} push
 */
export function createHealthId(data, method, push) {
  store.dispatch({
    type: patientsActionTypes.CREATE_HEALTH_ID,
    payload: { data: data, method: method, push: push }
  });
}

/**
 * get patients requests
 * @param {string} status
 * @param {string} limits
 */
export function getPatientsRequests(status = "ALL", limits) {
  store.dispatch({
    type: patientsActionTypes.GET_PATIENTS_REQUESTS,
    payload: {
      status: status,
      limits: limits
    }
  });
}

/**
 * @param {object} data
 */
export function consentPin({ method, newPin, currentPin, otp, navigate }) {
  store.dispatch({
    type: patientsActionTypes.MANAGE_CONSENT_PIN,
    payload: {
      method,
      newPin,
      currentPin,
      otp,
      navigate
    }
  });
}

/**
 * get patients care contexts
 */
export function getPatientsCareContexts() {
  store.dispatch({
    type: patientsActionTypes.GET_PATIENTS_CARE_CONTEXTS
  });
}
/**
 * login with healthId
 * @param {string} healthId
 * @param {string} alreadyExistedPHR
 * @param {string} push
 */
export function loginWithHealthId(method, healthId, push) {
  store.dispatch({
    type: patientsActionTypes.LOGIN_WITH_HEALTH_ID,
    payload: {
      method: method,
      healthId: healthId,
      push: push
    }
  });
}

/**
 * logout healthId
 * @param {string} patientId
 */
export function logoutHealthId(patientId) {
  store.dispatch({
    type: patientsActionTypes.LOGOUT_HEALTH_ID,
    payload: {
      patientId: patientId
    }
  });
}
/**
 * set linking token info
 * @param {object} data
 */
export function setLinkingTokenDetails(data) {
  store.dispatch({
    type: patientsActionTypes.LINKING_TOKEN_DETAILS,
    payload: {
      linkingTokenData: data
    }
  });
}
/**
 * accept patients request
 * @param {object} data
 */
export function acceptPatientsRequest(data) {
  switch (data.requestType) {
    case "consent":
      store.dispatch({
        type: patientsActionTypes.ACCEPT_CONSENT_REQUEST,
        payload: data
      });
      break;
    case "subscription":
      store.dispatch({
        type: patientsActionTypes.ACCEPT_SUBSCRIPTION_REQUEST,
        payload: data
      });
      break;
    case "lockerSetup":
      store.dispatch({
        type: patientsActionTypes.ACCEPT_LOCKER_REQUEST,
        payload: data
      });
      break;
    case "authorization":
      store.dispatch({
        type: patientsActionTypes.ACCEPT_AUTHORIZATION_REQUEST,
        payload: data
      });
      break;

    default:
      break;
  }
}

/**
 * deny patients request
 * @param {object} data
 */
export function denyPatientsRequest(data) {
  switch (data.requestType) {
    case "consent":
      store.dispatch({
        type: patientsActionTypes.DENY_CONSENT_REQUEST,
        payload: data
      });
      break;
    case "subscription":
      store.dispatch({
        type: patientsActionTypes.DENY_SUBSCRIPTION_REQUEST,
        payload: data
      });
      break;
    case "authorization":
      store.dispatch({
        type: patientsActionTypes.DENY_AUTHORIZATION_REQUEST,
        payload: data
      });
      break;

    default:
      break;
  }
}

/**
 * deny patients request
 * @param {object} data
 */
export function revokePermission(data) {
  switch (data.requestType) {
    case "consent":
      store.dispatch({
        type: patientsActionTypes.REVOKE_CONSENT_REQUEST,
        payload: data
      });
      break;

    case "authorization":
      store.dispatch({
        type: patientsActionTypes.REVOKE_AUTHORIZATION_REQUEST,
        payload: data
      });
      break;

    default:
      break;
  }
}
/**
 * health locker authorization
 */
export function healthLockerAuthorization() {
  store.dispatch({
    type: patientsActionTypes.HEALTH_LOCKER_AUTHORIZATION
  });
}

/**
 * revoke auto approval policy
 * @param {object} data
 */
export function disableAutoApprove(data) {
  store.dispatch({
    type: patientsActionTypes.DISABLE_CONSENT_AUTO_APPROVE,
    payload: data
  });
}

/**
 * get health id information
 */
export function getHealthIdInformation() {
  store.dispatch({
    type: patientsActionTypes.GET_HEALTH_ID_INFORMATION
  });
}

/**
 * revoke auto approval policy
 * @param {object} data
 */
export function editProfile(data) {
  store.dispatch({
    type: patientsActionTypes.EDIT_PROFILE,
    payload: { data: data }
  });
}

/**
 * set requests filter data
 * @param {string} filterBy
 * @param {string} profileId
 */
export function setRequestsFilterData(filterBy, id) {
  store.dispatch({
    type: patientsActionTypes.SET_REQUESTS_FILTER_DATA,
    payload: {
      filterBy: filterBy,
      id: id
    }
  });
}

/**
 * Confirm unlink ABHA address from ABHA number
 * @param {string} healthId
 */
export function searchHealthIdAuthMode(healthId) {
  store.dispatch({
    type: patientsActionTypes.SEARCH_HEALTH_ID_AUTH_MODE,
    payload: {
      healthId: healthId
    }
  });
}

/**
 * Generate OTP for unlink ABHA address from ABHA number
 * @param {string} healthId
 * @param {string} mode
 */
export function generateAbhaAddressOtp(healthId, mode) {
  store.dispatch({
    type: patientsActionTypes.GENERATE_LINK_OR_UNLINK_ABHA_ADDRESS_OTP,
    payload: {
      healthId: healthId,
      mode: mode
    }
  });
}

/**
 * Confirm unlink ABHA address from ABHA number
 * @param {string} transactionId
 * @param {string} otp
 * @param {string} mode
 */
export function confirmLinkOrUnLinkAbhaAddressOtp(
  transactionId,
  otp,
  mode,
  linkAddress,
  navigate
) {
  store.dispatch({
    type: patientsActionTypes.CONFIRM_LINK_OR_UNLINK_ABHA_ADDRESS,
    payload: {
      transactionId: transactionId,
      otp: otp,
      mode: mode,
      linkAbhaAddress: linkAddress,
      navigate: navigate
    }
  });
}

/**
 * set selected linked facilitie Hip id
 * @param {string} linkedFacilitieId
 */
export function setSelectedLinkedFacility(linkedFacilityId) {
  store.dispatch({
    type: patientsActionTypes.SELECTED_LINKED_FACILITY,
    payload: {
      linkedFacilityId: linkedFacilityId
    }
  });
}
