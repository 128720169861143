import React from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
// import { useParams } from "react-router";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import "./Appointment.css";

const Appointment = (props) => {
  // let { documentId } = useParams();
  return (
    <>
      <Header
        title="Appointment"
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
      />
      <div className="remaining-body-height overflow-x-scroll">
        <div className="padding-large inherit-parent-height">
          <div className="flex-justify-content-space-between flex-align-items-center padding-bottom-large padding-top-larger border-bottom-1px">
            <div>
              <div className="font-family-gilroy-medium font-size-medium font-color-secondary padding-bottom-default">
                Apollo hospitals
              </div>
              <div className="font-family-gilroy-medium font-color-tertiary font-size-small">
                Anna Nagar, Chennai
              </div>
            </div>
            <RoundedProfilePicture size="medium" />
          </div>
          <div className="flex-justify-content-space-between flex-align-items-center padding-bottom-large padding-top-larger">
            <div>
              <div className="font-family-gilroy-medium font-size-small font-color-secondary padding-bottom-small">
                Dr. Steve G
              </div>
              <div className="font-family-gilroy-medium font-color-tertiary font-size-small">
                General physician
              </div>
            </div>
            <RoundedProfilePicture size="smaller" />
          </div>
          <div className="padding-bottom-large">
            <div>
              <div className="font-family-gilroy-medium font-size-small font-color-tertiary padding-bottom-small">
                Appointment
              </div>
              <div className="font-family-gilroy-regular font-color-tertiary font-size-smaller">
                01 Oct 2021, 02:15 PM
              </div>
            </div>
          </div>
          <div className="flex-justify-content-space-between flex-align-items-center padding-bottom-large">
            <div>
              <div className="font-family-gilroy-medium font-size-small font-color-tertiary padding-bottom-small">
                Contact
              </div>
              <div className="font-family-gilroy-regular font-color-tertiary font-size-smaller">
                +91 99999 99999
              </div>
            </div>
            <button className="padding-top-default padding-bottom-default padding-right-large padding-left-large border-radius-default font-family-gilroy-medium font-size-small font-color-tertiary">
              Call clinic
            </button>
          </div>
          <div className="padding-bottom-large">
            <div>
              <div className="font-family-gilroy-medium font-size-small font-color-tertiary padding-bottom-small">
                Location
              </div>
              <div className="font-family-gilroy-regular font-size-smaller">
                1/1, A Street, A - Block, Anna Nagar, Chennai - 600040
              </div>
            </div>
          </div>
          <div className="padding-default background-white box-shadow-default border-radius-default margin-vertical-large">
            map
          </div>
          <div className="font-family-gilroy-medium text-align-center font-color-tertiary font-size-small">
            Can’t make it to your appointment?
            <span className=" font-color-primary padding-left-default">
              Notify!
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = function () {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
