import { setErrorStatus } from "../redux/status/actions";
import { api } from "../utils/constants";
import { throwError } from "./error";

export async function checkPhoneNumberAvailability(phoneNumber) {
  const response = await fetch(
    `${
      api.baseUrl
    }/nintoProfileManagement/accountExists?phoneNumber=${encodeURIComponent(
      phoneNumber
    )}&type=patient`,
    {
      method: "GET",
      headers: {
        // "ngrok-skip-browser-warning": "69420"
        // "X-Firebase-AppCheck": await getAppCheckToken()
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function acceptConnection(inviteCode, accessToken) {
  const response = await fetch(`${api.baseUrl}/acceptConnectionRequest/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "X-Firebase-AppCheck": await getAppCheckToken(),
      "x-auth-token": `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      requestId: inviteCode
    })
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function getUserInformationFromGoogleApis() {
  return fetch(
    "https://www.googleapis.com/oauth2/v2/userinfo?access_token=YOUR_ACCESS_TOKEN"
  );
}

export async function sendOAuthAccessCode(
  accessToken,
  patientProfileId,
  accessCode = false
) {
  return await fetch(`${api.baseUrl}/getGoogleFitnessData`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "X-Firebase-AppCheck": await getAppCheckToken(),
      "x-auth-token": `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      ...(accessCode ? { accessCode } : {}),
      pid: patientProfileId
    })
  });
}

export async function checkHealthIdExists(healthId, accessToken) {
  const response = await fetch(
    `${api.baseUrl}/profile/healthIdExists?healthId=${healthId}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": `Bearer ${accessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendOtp(
  profileId,
  phoneNumber,
  userName,
  requestType,
  accessToken
) {
  const response = await fetch(
    `${
      api.baseUrl
    }/sendOtp?userType=patient-demographic&userPhoneNumber=${encodeURIComponent(
      phoneNumber
    )}&profileId=${profileId}&userFullName=${userName}&otpType=${requestType}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        // "X-Firebase-AppCheck": await getAppCheckToken(),
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyOtp(
  profileId,
  phoneNumber,
  requestType,
  otp,
  accessToken
) {
  const response = await fetch(
    `${
      api.baseUrl
    }/validateOtp?userType=patient-demographic&userPhoneNumber=${encodeURIComponent(
      phoneNumber
    )}&otpType=${requestType}&patientDocumentId=${profileId}&otp=${otp}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        // "X-Firebase-AppCheck": await getAppCheckToken(),
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function generateOtpForAbdmAuth(
  accessToken,
  auth,
  method,
  value,
  authMode
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/patient/${auth}/${method}/generateOtp`,
    {
      method: "POST",
      body: JSON.stringify({
        value: value,
        authMode: authMode
      }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyOtpForAbdmAuth(
  accessToken,
  auth,
  method,
  otp,
  transactionId,
  patientId,
  healthId
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/patient/${auth}/${
      auth === "login"
        ? method === "healthId"
          ? "healthId"
          : "mobileEmail"
        : method
    }/verifyOtp`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        otp: otp,
        transactionId: transactionId,
        ...(patientId ? { patientId: patientId } : {}),
        ...(healthId ? { healthId: healthId } : {})
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    }
  } else {
    return result;
  }
}

export async function getModesForAbdmAuth(
  accessToken,
  auth,
  method,
  value,
  yearOfBirth
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/patient/${auth}/${method}/search`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        value: value,
        ...(yearOfBirth ? { yearOfBirth: yearOfBirth } : {})
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    }
  } else {
    return result;
  }
}

export async function confirmLoginForAbdmAuth(
  accessToken,
  healthId,
  transactionId,
  patientId
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/patient/login/mobileEmail/confirm`,
    {
      method: "POST",
      body: JSON.stringify({
        healthId: healthId,
        transactionId: transactionId,
        ...(patientId ? { patientId: patientId } : {})
      }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function createHealthId(accessToken, registerType, data) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/patient/register/${registerType}/createPhrAddress`,

    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        ...data
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export const getPatientRequests = async (abdmAccessToken, nintoAccessToken) => {
  let headersList = {
    Accept: "application/json",
    "X-ABDM-TOKEN": abdmAccessToken,
    "x-auth-token": `Bearer ${nintoAccessToken}`
  };

  const response = await fetch(
    `${api.baseUrl}/consentManager/getPatientRequests`,
    {
      method: "GET",
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
};

export const getCareContexts = async (abdmAccessToken, nintoAccessToken) => {
  let headersList = {
    Accept: "application/json",
    "X-ABDM-TOKEN": abdmAccessToken,
    "x-auth-token": `Bearer ${nintoAccessToken}`
  };

  const response = await fetch(
    `${api.baseUrl}/abdmCareContext/careContext/getPatientCareContextList`,
    {
      method: "GET",
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
};

export const denyRequests = async (data, type, nintoToken, abdmToken) => {
  let headersList = {
    "X-ABDM-Token": abdmToken,
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${nintoToken}`
  };

  let bodyContent = JSON.stringify(data);

  const response = await fetch(`${api.baseUrl}/consentManager/${type}/deny`, {
    method: "POST",
    body: bodyContent,
    headers: headersList
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
};

export async function createConsentPin(pin, nintoAccessToken, abdmAccessToken) {
  // if (typeof accessToken === "string") {
  let headersList = {
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${nintoAccessToken}`,
    "X-ABDM-Token": abdmAccessToken
  };

  let bodyContent = JSON.stringify({
    pin: pin
  });

  const response = await fetch(`${api.baseUrl}/profile/consentPin/create`, {
    method: "POST",
    body: bodyContent,
    headers: headersList
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
  // } else throwError("api", "Invalid accessToken or requestId or reason");
}

export async function updateConsentPin(
  currentPin,
  newPin,
  nintoAccessToken,
  abdmAccessToken
) {
  let headersList = {
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${nintoAccessToken}`,
    "X-ABDM-Token": abdmAccessToken
  };

  let bodyContent = JSON.stringify({
    pin: currentPin,
    newPin: newPin,
    forget: false
  });

  const response = await fetch(`${api.baseUrl}/profile/consentPin/update`, {
    method: "POST",
    body: bodyContent,
    headers: headersList
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function acceptConsentRequest(
  data,
  abdmAccessToken,
  nintoAccessToken
) {
  // http://localhost:5001/karthealth-dev/asia-south1/abdmConsentRequest/accept
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${nintoAccessToken}`
  };

  let bodyContent = JSON.stringify({
    ...data
  });

  const response = await fetch(
    `${api.baseUrl}/consentManager/consentRequest/accept`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendOtpToResetConsentPin(
  nintoAccessToken,
  abdmAccessToken
) {
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${nintoAccessToken}`
  };

  let bodyContent = JSON.stringify({
    forget: true
  });

  const response = await fetch(`${api.baseUrl}/profile/consentPin/update`, {
    method: "POST",
    body: bodyContent,
    headers: headersList
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyResetConsentPinOtp(data) {
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": data.abdmAccessToken,
    "x-auth-token": `Bearer ${data.nintoAccessToken}`
  };

  let bodyContent = JSON.stringify({
    transactionId: data.transactionId,
    otp: data.otp,
    newPin: data.newPin
  });

  const response = await fetch(`${api.baseUrl}/profile/consentPin/verifyOtp`, {
    method: "POST",
    body: bodyContent,
    headers: headersList
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function acceptSubscriptionRequest(
  data,
  abdmAccessToken,
  fireBaseAccessToken
) {
  // http://localhost:5001/karthealth-dev/asia-south1/abdmConsentRequest/accept

  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    ...data
  });

  const response = await fetch(
    `${api.baseUrl}/consentManager/subscription/accept`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function editSubscriptionRequest(
  data,
  abdmAccessToken,
  fireBaseAccessToken
) {
  // http://localhost:5001/karthealth-dev/asia-south1/abdmConsentRequest/accept

  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    ...data
  });

  const response = await fetch(
    `${api.baseUrl}/consentManager/subscription/edit`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function revokeConsentRequest(
  data,
  abdmAccessToken,
  fireBaseAccessToken
) {
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    ...data
  });

  const response = await fetch(
    `${api.baseUrl}/consentManager/consentRequest/revoke`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function revokeAuthorizationRequest(
  data,
  abdmAccessToken,
  fireBaseAccessToken
) {
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    ...data
  });

  const response = await fetch(
    `${api.baseUrl}/consentManager/authorization/revoke`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function searchAbdmClinics(
  accessToken,
  fireBaseAccessToken,
  searchString
) {
  let headersList = {
    "x-abdm-token": accessToken,
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  const response = await fetch(
    `${api.baseUrl}/abdmCareContext/careContext/searchProviderDetails?name=${searchString}`,
    {
      method: "GET",
      headers: headersList
    }
  );

  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function searchAbdmClinicsWithHipId(
  accessToken,
  fireBaseAccessToken,
  searchString
) {
  let headersList = {
    "x-abdm-token": accessToken,
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  const response = await fetch(
    `${api.baseUrl}/abdmCareContext/careContext/getProviderDetailsById?hipId=${searchString}`,
    {
      method: "GET",
      headers: headersList
    }
  );

  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function getPublicHealthPrograms(
  fireBaseAccessToken,
  accessToken
) {
  const response = await fetch(`${api.baseUrl}/abdmCareContext/govtPrograms`, {
    method: "GET",
    headers: {
      "x-abdm-token": accessToken,
      "x-auth-token": `Bearer ${fireBaseAccessToken}`
    }
  });

  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function discoverCareContextUsingClinicId(
  fireBaseAccessToken,
  accessToken,
  clinicId,
  phoneNumber,
  patientId
) {
  let headersList = {
    "x-abdm-token": accessToken,
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    hipId: clinicId,
    unverifiedIdentifiers: [
      patientId
        ? { type: "MR", value: patientId }
        : {
            type: "MOBILE",
            value: phoneNumber
          }
    ]
  });

  const response = await fetch(
    `${api.baseUrl}/abdmCareContext/careContext/discover`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function linkAbdmCareContextSendOtp(
  accessToken,
  fireBaseAccessToken,
  data,
  transactionId,
  referenceNumber
) {
  let headersList = {
    "x-abdm-token": accessToken,
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    transactionId: transactionId,
    referenceNumber: referenceNumber,
    ...(!!data ? { careContexts: data } : {})
  });

  const response = await fetch(
    `${api.baseUrl}/abdmCareContext/careContext/linkRequest`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function linkAbdmCareContextConfirmOtp(
  accessToken,
  fireBaseAccessToken,
  linkRefNumber,
  otp
) {
  let headersList = {
    "x-abdm-token": accessToken,
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    otp: otp,
    linkRefNumber: linkRefNumber
  });
  const response = await fetch(
    `${api.baseUrl}/abdmCareContext/careContext/linkConfirm`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function shareProfile(
  accessToken,
  fireBaseAccessToken,
  hipId,
  hipCode
) {
  const headersList = {
    "x-abdm-token": accessToken,
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };
  const bodyData = JSON.stringify({
    hipId: hipId,
    hipCode: hipCode
  });
  const response = await fetch(`${api.baseUrl}/profile/share`, {
    method: "POST",
    headers: headersList,
    body: bodyData
  });

  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function acceptAndShareConnectionToClinic(
  abdmLoginAccessToken,
  hipId,
  hipCode
) {
  const headersList = {
    "abdm-login-token": abdmLoginAccessToken,
    "Content-Type": "application/json"
  };

  const bodyContent = JSON.stringify({
    hipId: hipId,
    hipCode: hipCode
  });

  const response = await fetch(`${api.baseUrl}/profile/share`, {
    method: "POST",
    headers: headersList,
    body: bodyContent
  });

  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function acceptLockerAuthorizationRequest(
  data,
  abdmAccessToken,
  nintoAccessToken
) {
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${nintoAccessToken}`
  };

  let bodyContent = JSON.stringify({
    ...data
  });

  const response = await fetch(
    `${api.baseUrl}/consentManager/lockerRequest/accept`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );

  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function acceptAuthorizationRequest(
  data,
  abdmAccessToken,
  fireBaseAccessToken
) {
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    ...data
  });

  const response = await fetch(
    `${api.baseUrl}/consentManager/authorization/accept`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function acceptAutoApprove(
  data,
  abdmAccessToken,
  fireBaseAccessToken
) {
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-Token": abdmAccessToken,
    "x-auth-token": `Bearer ${fireBaseAccessToken}`
  };

  let bodyContent = JSON.stringify({
    ...data
  });

  const response = await fetch(
    `${api.baseUrl}/consentManager/auto-approval-policy`,
    {
      method: "POST",
      body: bodyContent,
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function getPatientQrDetails(fireBaseAccessToken, accessToken) {
  if (typeof accessToken === "string") {
    let headersList = {
      "Content-Type": "application/json",
      "x-abdm-token": accessToken,
      "x-auth-token": `Bearer ${fireBaseAccessToken}`
    };
    const response = await fetch(`${api.baseUrl}/profile/getMyQrCode`, {
      method: "GET",
      headers: headersList
    });
    const result = await response.json();
    if (result.error) {
      if (result.error.name === "requestError") {
        setErrorStatus({ code: "custom", message: result.error.message });
      } else {
        throw result.error;
      }
    }
    return result;
  } else throwError("api", "Invalid accessToken or requestId or reason");
}

export async function generateOTPForUpdatePhoneNumberOrEmail(
  fireBaseAccessToken,
  accessToken,
  value,
  authMode
) {
  const response = await fetch(
    `${api.baseUrl}/profile/update/mobileEmail/sendOtp`,
    {
      method: "POST",
      body: JSON.stringify({
        value: value,
        authMode: authMode
      }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${fireBaseAccessToken}`,
        "x-abdm-token": accessToken
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyOTPForUpdatePhoneNumberOrEmail(
  fireBaseAccessToken,
  accessToken,
  value,
  sessionId,
  data
) {
  const response = await fetch(
    `${api.baseUrl}/profile/update/mobileEmail/verifyOtp`,
    {
      method: "POST",
      body: JSON.stringify({
        value: value,
        sessionId: sessionId,
        ...(data ? { updatedPhoneNumber: data } : {})
      }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${fireBaseAccessToken}`,
        "x-abdm-token": accessToken
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function abhaAddressResetPassword(
  fireBaseAccessToken,
  accessToken,
  value
) {
  const response = await fetch(`${api.baseUrl}/profile/resetPassword`, {
    method: "POST",
    body: JSON.stringify({
      password: value
    }),
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `Bearer ${fireBaseAccessToken}`,
      "x-abdm-token": accessToken
    }
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function lockerAuthorizationRequest(
  patientId,
  abdmAccessToken,
  nintoAccessToken
) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/lockerRequest/init`,
    {
      method: "POST",
      body: JSON.stringify({
        // patientId: patientId,
        purpose: { text: "Self Requested", code: "PATRQT" },
        period: {
          from: new Date(
            new Date().setMonth(new Date().getMonth() - 6)
          ).toISOString(),
          to: new Date(
            new Date().setMonth(new Date().getMonth() + 6)
          ).toISOString()
        }
        // clinicUid: lockerSetup.locker
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-ABDM-Token": abdmAccessToken,
        "x-auth-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function getLockerAuthorizationRequest(
  accessToken,
  nintoAccessToken
) {
  // if (typeof accessToken === "string") {
  let headersList = {
    "Content-Type": "application/json",
    "X-ABDM-TOKEN": accessToken,
    "x-auth-token": `Bearer ${nintoAccessToken}`
  };

  const response = await fetch(
    `${api.baseUrl}/consentManager/getPatientRequests?consentLimit=0&consentOffset=0&lockerSetupLimit=10&lockerSetupOffset=0&subscriptionLimit=10&subscriptionOffset=0&authorizationLimit=10&authorizationOffset=0&status=REQUESTED`,

    {
      method: "GET",
      headers: headersList
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
  // } else throwError("api", "Invalid accessToken or requestId or reason");
}

export async function lockerRequestAccept(
  accessToken,
  requestIdOfLockerRequest,
  lockerId,
  toTime
) {
  if (typeof accessToken === "string") {
    let headersList = {
      "Content-Type": "application/json",
      "X-ABDM-Token": accessToken
    };

    let bodyContent = JSON.stringify({
      requestIdOfLockerRequest: requestIdOfLockerRequest,
      lockerId: lockerId,
      toTime: toTime
    });

    const response = await fetch(
      `${api.baseUrl}/consentManager/lockerRequest/accept`,
      {
        method: "POST",
        body: bodyContent,
        headers: headersList
      }
    );

    const result = await response.json();
    if (result.error) {
      if (result.error.name === "requestError") {
        setErrorStatus({ code: "custom", message: result.error.message });
      } else {
        throw result.error;
      }
    }
    return result;
  } else throwError("api", "Invalid accessToken or requestId or reason");
}

export async function retryDataTransfer(abdmToken, token, bodyData) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/healthInformation/request`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-abdm-token": abdmToken,
        "x-auth-token": `Bearer ${token}`
      },
      body: JSON.stringify({
        clinicId: bodyData.hiuClinicId,
        ...(bodyData.consentRequestId
          ? { consentRequestId: bodyData.consentRequestId }
          : {}),
        patientId: bodyData.patientId,
        ...(bodyData.consentId ? { consentId: bodyData.consentId } : {}),
        ...(bodyData.subscriptionId
          ? { subscriptionId: bodyData.subscriptionId }
          : {})
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function uploadDocument(data, firebaseToken, accessToken) {
  const response = await fetch(`${api.baseUrl}/uploadDocument/upload`, {
    method: "POST",
    headers: {
      "x-abdm-token": accessToken,
      "x-auth-token": `Bearer ${firebaseToken}`
    },
    body: data
  });

  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function disableAutoApprovalPolicy(
  data,
  abdmAccessToken,
  nintoAccessToken
) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/auto-approval-policy/disable`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-ABDM-Token": abdmAccessToken,
        "x-auth-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function getHealthIdData(nintoAccessToken, abdmAccessToken) {
  let headersList = {
    "Content-Type": "application/json",
    "x-auth-token": `Bearer ${nintoAccessToken}`,
    "X-ABDM-Token": abdmAccessToken
  };

  const response = await fetch(`${api.baseUrl}/profile`, {
    method: "GET",
    headers: headersList
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function editHealthIdData(
  nintoAccessToken,
  abdmAccessToken,
  data
) {
  const response = await fetch(`${api.baseUrl}/profile/edit`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-ABDM-Token": abdmAccessToken,
      "x-auth-token": `Bearer ${nintoAccessToken}`
    }
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function searchHealthId(
  nintoAccessToken,
  abdmAccessToken,
  healthId
) {
  const response = await fetch(`${api.baseUrl}/identityManager/search`, {
    method: "POST",
    body: JSON.stringify({ healthId: healthId }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-ABDM-Token": abdmAccessToken,
      "x-auth-token": `Bearer ${nintoAccessToken}`
    }
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function generateOtpForUnlinkAbhaAddressFromAbhaNumber(
  nintoAccessToken,
  abdmAccessToken,
  data
) {
  const response = await fetch(`${api.baseUrl}/identityManager/login/init`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-ABDM-Token": abdmAccessToken,
      "x-auth-token": `Bearer ${nintoAccessToken}`
    }
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function confirmUnlinkAbhaAddressFromAbhaNumber(
  nintoAccessToken,
  abdmAccessToken,
  data
) {
  const response = await fetch(`${api.baseUrl}/identityManager/login/confirm`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-ABDM-Token": abdmAccessToken,
      "x-auth-token": `Bearer ${nintoAccessToken}`
    }
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function linkOrUnlinkAbhaAddressFromAbhaNumber(
  nintoAccessToken,
  abdmAccessToken,
  data,
  type
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/abhaAddress/${type}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-ABDM-Token": abdmAccessToken,
        "x-auth-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function registerAbhaNumberUsingAadhar(
  nintoAccessToken,
  data,
  type
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/register/aadhaar/${type}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function linkPhoneNumberAndAbhaNumber(
  nintoAccessToken,
  data,
  type
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/register/mobile/${type}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function createAbhaNumber(nintoAccessToken, data) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/register/abhaNumber/create`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}
