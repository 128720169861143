import React from "react";
import { useState } from "react";
import { getFCMToken } from "../../services/cloudMessaging";
import closeIcon from "../../assets/icons/close.svg";
import { CSSTransition } from "react-transition-group";
import { isValidArray } from "../../utils/validators";

export default function Notification(props) {
  const [permissionStatus, setPermissionStatus] = useState(
    window.Notification.permission
  );

  const handlePermission = async () => {
    await getFCMToken();
    setPermissionStatus(window.Notification.permission);
  };

  const onNotificationScroll = (event) => {
    const bottom =
      parseInt(event.target.scrollHeight - event.target.scrollTop) ===
      event.target.clientHeight;
    if (bottom) {
      if (
        props.pagination &&
        !Object.values(props.pagination).includes("end")
      ) {
        props.updateNotifications();
      }
    }
  };

  return (
    <CSSTransition
      in={props.show}
      timeout={2000}
      classNames="fade-in-fade-out-transition"
    >
      <React.Fragment>
        {props.show === true && props.loading === false && (
          <section className=" padding-top-large settings-screen-content-remaining-height inherit-parent-width">
            <section className=" margin-right-medium margin-left-medium  display-flex flex-justify-content-space-between flex-align-items-center padding-bottom-large">
              <div className=" letter-spacing-large display-flex text-transform-uppercase font-family-gilroy-regular font-size-small font-color-secondary">
                Notifications
                {props.notificationCount > 0 && (
                  <div
                    className={`margin-left-small settings-notification-icon-count-size box-shadow-none flex-place-children-page-center border-radius-50-percentage font-color-white background-primary text-align-center font-size-extra-small ${
                      props.notificationCount > 9
                        ? "height-and-width-for-double-digit-number"
                        : props.notificationCount > 99
                        ? "height-and-width-for-triple-digit-number"
                        : "height-and-width-for-single-digit-number"
                    }`}
                  >
                    {props.notificationCount > 99
                      ? "99+"
                      : props.notificationCount}
                  </div>
                )}
              </div>
              {props.notifications &&
                isValidArray(props.notifications) &&
                props.notifications?.some(
                  (notification) => notification.read === false
                ) && (
                  <div
                    onClick={() => {
                      props.markAllAsReadOnClick();
                    }}
                    data-cy={"mark-all-as-read"}
                    className=" cursor-pointer letter-spacing-large text-transform-uppercase width-fit-content border-1px-e5e5e5 background-color-white border-radius-default font-size-smaller height-fit-to-content flex-place-children-page-center padding-small text-transform-capitalize letter-spacing-large font-color-secondary"
                  >
                    Mark all as read
                  </div>
                )}
            </section>
            <ul
              data-cy="notification-container"
              className={`list-style-none border-bottom-1px-faded-gray ${
                permissionStatus === "default"
                  ? "height-85-percentage"
                  : "notification-remaining-height"
              } overflow-y-auto ${
                props.notifications && !isValidArray(props.notifications)
                  ? "display-flex flex-align-items-center flex-justify-content-center"
                  : ""
              }`}
              onScroll={onNotificationScroll}
            >
              {props.notifications && isValidArray(props.notifications) ? (
                <React.Fragment>
                  {props.notifications?.map((notification, index) => (
                    <React.Fragment key={index}>
                      <NotificationCard
                        index={index}
                        onClick={() => {
                          props.notificationCardOnClick(notification);
                        }}
                        notification={notification}
                      />
                    </React.Fragment>
                  ))}
                  <PaginationLoading show={props.paginationLoading} />
                  {props.pagination &&
                    Object.values(props.pagination).includes("end") && (
                      <div className="text-align-center padding-top-large padding-bottom-large">
                        No further notification available
                      </div>
                    )}
                </React.Fragment>
              ) : (
                <li className="background-white padding-medium flex-align-items-center text-align-center font-color-tertiary">
                  No notifications
                </li>
              )}
            </ul>
            {permissionStatus === "default" && props.app.notification === null && (
              <EnablePushNotification
                onClick={() => {
                  handlePermission();
                }}
                onCloseClick={props.onCloseClick}
              />
            )}
          </section>
        )}
        {props.show === true && props.loading === true && (
          <NotificationsSuspense />
        )}
      </React.Fragment>
    </CSSTransition>
  );
}

const NotificationCard = ({ onClick, notification, index }) => {
  /**
   * index => notification index
   * onClick => notification onClick callback
   * notification => data to render notification , required values description, timestamp, read
   */
  return (
    <li
      className={` margin-right-medium margin-left-medium  ${
        index !== 0 ? " padding-top-large " : ""
      } border-collapse background-white padding-bottom-large flex-justify-content-space-between flex-align-items-center cursor-pointer`}
      onClick={(event) => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}
      key={notification.timestamp}
    >
      <div className="width-90-percentage ">
        <div
          data-cy={notification.title}
          className={` ${
            notification.read === false
              ? " font-color-secondary "
              : " font-color-tertiary "
          } text-overflow-ellipsis font-family-gilroy-regular font-size-medium padding-bottom-default`}
        >
          {notification.title}
        </div>

        <div
          data-cy={notification.title}
          className={` ${
            notification.read === false
              ? " font-color-secondary "
              : " font-color-tertiary "
          } text-overflow-ellipsis font-family-gilroy-regular font-size-medium padding-bottom-default`}
        >
          {notification.description}
        </div>

        <div
          className={` font-family-gilroy-regular font-size-extra-small text-transform-uppercase letter-spacing-large ${
            notification.read === false
              ? "font-color-secondary "
              : "font-color-tertiary"
          }`}
        >
          {dateAndTimeConverter(notification.timestamp)}
        </div>
      </div>
      {notification.read === false && (
        <div data-cy="unread-notification">
          <div className="margin-default background-color-primary-green border-radius-100-percentage padding-small" />
        </div>
      )}
    </li>
  );
};

const NotificationsSuspense = () => {
  return (
    <section
      className="padding-top-large settings-screen-content-remaining-height"
      data-cy="notification-suspense"
    >
      {[...Array(10)].map((index) => (
        <div
          key={index}
          className="margin-top-large margin-bottom-larger padding-horizontal-large inherit-parent-width"
        >
          <div className=" width-100-percentage shimmer padding-default margin-bottom-medium border-radius-default"></div>
          <div className=" width-50-percentage shimmer padding-default border-radius-default margin-bottom-larger"></div>
        </div>
      ))}
    </section>
  );
};

const PaginationLoading = ({ show }) => {
  return (
    show === true && (
      <div className="flex-justify-content-center padding-vertical-small margin-bottom-larger inherit-parent-width">
        <div className="display-flex-direction-row flex-justify-content-center flex-align-items-center inherit-parent-width">
          <p className=" font-color-secondary ">Loading</p>{" "}
          <div className="padding-horizontal-medium" />
          <div className={`dot-flashing`} />
          <div className="padding-horizontal-medium" />
        </div>
      </div>
    )
  );
};

const EnablePushNotification = (props) => {
  return (
    <div className="inherit-parent-width display-flex position-absolute bottom-0 flex-justify-content-space-between padding-large text-overflow-ellipsis background-color-white">
      <div className="display-flex flex-direction-column text-overflow-ellipsis">
        <div className="font-family-gilroy-medium font-size-medium">
          Enable Push Notification
        </div>
        <div className="padding-top-default font-size-small ">
          Get notified for any updates
        </div>
      </div>
      <div className="display-flex flex-center-children-vertically padding-left-default">
        <div
          onClick={props.onClick}
          className=" background-primary padding-left-default padding-right-default padding-top-smaller padding-bottom-smaller height-fit-content width-fit-content font-color-white font-size-small border-radius-small"
        >
          Accept
        </div>
        <div
          onClick={props.onCloseClick}
          className="display-flex flex-center-children-vertically padding-left-large"
        >
          <img
            src={closeIcon}
            alt="close-icon"
            width={12}
            height={12}
            className=""
          />
        </div>
      </div>
    </div>
  );
};

function dateAndTimeConverter(timestamp) {
  if (!timestamp) return;
  let dateObject = new Date(timestamp);
  const date =
    timestamp >= new Date().setHours(0, 0, 0, 0)
      ? "TODAY"
      : timestamp >= new Date().setHours(0, 0, 0, 0) - 86400000 &&
        timestamp < new Date().setHours(0, 0, 0, 0)
      ? "YESTERDAY"
      : dateObject.toLocaleString("en-IN", { day: "2-digit" }) +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }) +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
  return (
    date + " ," + dateObject.toLocaleString("en-IN", { timeStyle: "short" })
  );
}
