const downloadsReducer = (
  state = {
    loading: false,
    data: {
      profilePictures: {
        patients: null,
        doctors: null,
        clinics: null
      },
      reports: null,
      prescriptions: null,
      symptoms: null
    }
  },
  action
) => {
  switch (action.type) {
    case "SET_DOWNLOADS_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_PATIENT_PROFILE_PRICTURES_URL":
      return {
        ...state,
        data: {
          ...state.data,
          profilePictures: {
            ...state.data.profilePictures,
            patients: {
              ...state.data.profilePictures.patients,
              ...action.payload
            }
          }
        }
      };

    case "SET_DOCTOR_PROFILE_PRICTURES_URL":
      return {
        ...state,
        data: {
          ...state.data,
          profilePictures: {
            ...state.data.profilePictures,
            doctors: {
              ...state.data.profilePictures.doctors,
              ...action.payload
            }
          }
        }
      };

    case "SET_CLINIC_PROFILE_PRICTURES_URL":
      return {
        ...state,
        data: {
          ...state.data,
          profilePictures: {
            ...state.data.profilePictures,
            clinics: {
              ...state.data.profilePictures.clinics,
              ...action.payload
            }
          }
        }
      };
    case "SET_REPORTS_URL":
      return {
        ...state,
        data: {
          ...state.data,
          reports: action.payload.reportsUrl
        }
      };

    case "SET_PRESCRIPTIONS_URL":
      return {
        ...state,
        data: {
          ...state.data,
          prescriptions: action.payload.prescriptionsUrl
        }
      };
    case "SET_RECEIPTS_URL":
      return {
        ...state,
        data: {
          ...state.data,
          receipts: action.payload.receiptsUrl
        }
      };
    case "SET_ADMISSIONS_URL":
      return {
        ...state,
        data: {
          ...state.data,
          admissions: action.payload.admissionsUrl
        }
      };
    case "SET_INSURANCES_URL":
      return {
        ...state,
        data: {
          ...state.data,
          insurances: action.payload.insurancesUrl
        }
      };
    case "SET_SYMPTOMS_URL":
      return {
        ...state,
        data: {
          ...state.data,
          symptoms: action.payload.symptomsUrl
        }
      };
    case "SET_VITAL_URL":
      return {
        ...state,
        data: {
          ...state.data,
          vitals: action.payload.vital
        }
      };

    case "SET_VACCINE_URL":
      return {
        ...state,
        data: {
          ...state.data,
          vaccines: action.payload.vaccine
        }
      };

    case "SET_CONSULTATION_URL":
      return {
        ...state,
        data: {
          ...state.data,
          consultations: action.payload.consultation
        }
      };

    case "SET_OTHERS_URL":
      return {
        ...state,
        data: {
          ...state.data,
          others: action.payload.others
        }
      };

    case "RESET":
      return {
        loading: false,
        data: {
          profilePictures: {
            patients: null,
            doctors: null,
            clinics: null
          },
          reports: null,
          prescriptions: null,
          symptoms: null
        }
      };

    default:
      return state;
  }
};

export default downloadsReducer;
