export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  abhaNumber: /^[0-9]{2}[0-9]{4}[0-9]{4}[0-9]{4}$/,
  phoneNumber: /^\+[1-9]{1}[0-9]{11,14}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){4,13}[a-zA-Z]$/i,
  password: /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  name: /\d/
};
