import store from "../store/store";
import { abdmActionTypes } from "./saga";
/**
 * @module AbdmSaga
 */

/**
 * send OTP to link care context
 * @param {object} data
 * @param {string} clinicId
 */
export function sendOtpToLinkAbdmCareContext(data, clinicId) {
  store.dispatch({
    type: abdmActionTypes.SEND_OTP_TO_LINK_ABDM_CARE_CONTEXT,
    payload: { data: data, clinicId: clinicId }
  });
}

/**
 * send OTP to link care context
 * @param {string} data
 */
export function setSearchHistory(data) {
  store.dispatch({
    type: abdmActionTypes.SET_SEARCH_HISTORY,
    payload: { data: data }
  });
}

/**
 * send OTP to link care context
 */
export function clearClinicSearchResults() {
  store.dispatch({
    type: abdmActionTypes.CLEAR_CLINIC_SEARCH_RESULTS
  });
}

/**
 * send OTP to link care context
 * @param {object} data
 * @param {string} clinicId
 */
export function getPublicHealthPrograms() {
  store.dispatch({
    type: abdmActionTypes.GET_PUBLIC_HEALTH_PROGRAMS
  });
}

/**
 * confirm OTP to link care context
 * @param {string} otp
 * @param {function} navigate
 */
export function confirmOtpToLinkAbdmCareContext(otp, navigate) {
  store.dispatch({
    type: abdmActionTypes.CONFIRM_OTP_TO_LINK_ABDM_CARE_CONTEXT,
    payload: {
      otp: otp,
      navigate: navigate
    }
  });
}
/**
 * discover patient care context using clinic id
 * @param {string} clinicId
 */
export function discoverCareContextWithClinicId(clinicId, patientId) {
  store.dispatch({
    type: abdmActionTypes.DISCOVER_ABDM_CARE_CONTEXT_WITH_CLINIC_ID,
    payload: { clinicId: clinicId, patientId: patientId }
  });
}

/**
 * abdm clinic/lab search
 * @param {string} clinicId
 */
export function searchAbdmClinic(searchKey) {
  store.dispatch({
    type: abdmActionTypes.SEARCH_ABDM_CLINIC,
    payload: {
      searchKey: searchKey
    }
  });
}

/**
 * check healthId exists
 * @param {string} healthId
 */
export function checkHealthIdExists(healthId) {
  store.dispatch({
    type: abdmActionTypes.CHECK_HEALTH_ID_EXISTS,
    payload: {
      healthId: healthId
    }
  });
}
/**
 * share profile
 */
export function shareProfile(navigate) {
  store.dispatch({
    type: abdmActionTypes.SHARE_PROFILE,
    payload: {
      navigate: navigate
    }
  });
}

/**
 * set ABDM creds
 * @param {string} requestMethod
 * @param {string} transactionId
 */
export function setAbdmAuthCredentials(requestMethod, transactionId) {
  store.dispatch({
    type: "ADD_ABDM_AUTH_CREDENTIALS",
    payload: {
      requestMethod: requestMethod,
      transactionId: transactionId
    }
  });
}

/**
 * clear ABDM auth creds
 */
export function clearAbdmAuthCredentials() {
  store.dispatch({
    type: "CLEAR_ABDM_AUTH_CREDENTIALS"
  });
}
/**
 * clear ABDM Link ref number
 */
export function clearLinkRefNumber() {
  store.dispatch({
    type: "CLEAR_LINK_REF_NUMBER"
  });
}

/**
 * clear healthId exists status
 */
export function clearHealthIdExistsStatus() {
  store.dispatch({
    type: abdmActionTypes.CLEAR_HEALTH_ID_EXISTS_STATUS
  });
}

/**
 * get scanned clinic details
 * @param {string} hipId
 * @param {string} hipCode
 * @param {string} push
 */
export function getScannedClinicDetails(hipId, hipCode, push) {
  store.dispatch({
    type: abdmActionTypes.GET_SCANNED_CLINIC_DETAILS,
    payload: {
      hipId: hipId,
      hipCode: hipCode,
      push: push
    }
  });
}

/**
 * clear ABDM connect details
 */
export function clearABdmConnectDetails() {
  store.dispatch({
    type: abdmActionTypes.CLEAR_ABDM_CONNECT_DETAILS
  });
}

/**
 * share connection to clinic
 * @param {string} go
 */
export function shareConnectionToClinic(go) {
  store.dispatch({
    type: abdmActionTypes.SHARE_CONNECTION_TO_CLINIC,
    payload: {
      go: go
    }
  });
}

/**
 * Register new ABHA number
 * @param {object} data
 */
export function registerNewAbhaNumber(data) {
  store.dispatch({
    type: abdmActionTypes.REGISTER_NEW_ABHA_NUMBER,
    payload: data
  });
}
